export const setIsLoading = (newState) => ({
    type: "SET_IS_LOADING",
    payload: newState
})

export const setSharing = (sharing) => ({
    type: "SET_SHARING",
    payload: sharing
})

export const setCurrentDomain = (domain) => ({
    type: "SET_CURRENT_DOMAIN",
    payload: domain
})

export const setCompanies = (companies) => ({
    type: "SET_COMPANIES",
    payload: companies
})

export const setCurrentCompany = (company) => ({
    type: "SET_CURRENT_COMPANY",
    payload: company
})

export const setMainFolders = (mainFolders) => ({
    type: "SET_MAIN_FOLDERS",
    payload: mainFolders
})

export const setUploadedFiles = (uploadedFiles) => ({
    type: "SET_UPLOADED_FILES",
    payload: uploadedFiles
})

export const setCurrentMainFolder = (mainFolder) => ({
    type: "SET_CURRENT_MAIN_FOLDER",
    payload: mainFolder
})

export const setCurrentFolder = (folder) => ({
    type: "SET_CURRENT_FOLDER",
    payload: folder
})

export const setCurrentSubFolder = (subFolder) => ({
    type: "SET_CURRENT_SUB_FOLDER",
    payload: subFolder
})

export const setCurrentFile = (file) => ({
    type: "SET_CURRENT_FILE",
    payload: file
})

export const setCurrentFileIdx = (idx) => ({
    type: "SET_CURRENT_FILE_IDX",
    payload: idx
})

export const setMainFoldersEntities = (mainFoldersEntities) => ({
    type: "SET_MAIN_FOLDERs_ENTITIES",
    payload: mainFoldersEntities
})

export const setCurrentMainFolderEntities = (mainFolderEntities) => ({
    type: "SET_CURRENT_MAIN_FOLDER_ENTITIES",
    payload: mainFolderEntities
})

export const setCurrentFolderEntities = (folderEntities) => ({
    type: "SET_CURRENT_FOLDER_ENTITIES",
    payload: folderEntities
})

export const setCurrentSubFolderEntities = (subFolderEntities) => ({
    type: "SET_CURRENT_SUB_FOLDER_ENTITIES",
    payload: subFolderEntities
})

export const setSearchValue = (newValue) => ({
    type: "SET_SEARCH_VALUE",
    payload: newValue
})

export const setSearchResults = (newValue) => ({
    type: "SET_SEARCH_RESULTS",
    payload: newValue
})

export const setLoggedInUser = (user) => ({
    type: "SET_LOGGED_IN_USER",
    payload: user
})

export const setDeadlineItems = (newValue) => ({
    type: "SET_DEADLINE_ITEMS",
    payload: newValue
})