import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { Grid, IconButton, Divider, Link, Typography, Menu, MenuItem, Icon, Chip } from '@material-ui/core'
import { Share, FileCopy, MoreVert, ArrowRight, Alarm, AttachFile, Note, Security, Lens, Attachment, Person } from "@material-ui/icons"
import { entityTypes } from "../enums"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { optionsActionBarFolders, optionsActionBarEntities } from "../constants"
import MoreOptionsMenu from "./MoreOptionsMenu"
import moment from "moment"
import { CopyToClipboard } from "react-copy-to-clipboard"
import api, { escapeForwardSlash } from "../api"
import { save } from "save-file"
import { setCurrentFileIdx, setMainFolders, setCurrentFile, setSearchValue, setCurrentFolder, setCurrentMainFolder } from "../actions/actions"

class FileActionBar extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            moreOptionsFileTarget: null
        }
    }

    handleMoreOptionsClicked = (event) => {
        const { setCurrentFileIdx, idx, file, setCurrentFile } = this.props
        event.stopPropagation()

        if (file.type !== "sharing") {
            setCurrentFileIdx(idx)
            setCurrentFile(file)
        }

        this.setState({ moreOptionsFileTarget: event.currentTarget })
    }

    handleMoreOptionsClose = (event) => {
        event.stopPropagation()
        this.setState({ moreOptionsFileTarget: null })
    }

    handleFileActionBarClicked = (event) => {
        const { handleDocumentModalOpen, setSearchValue, searchValue, setSharingState, mainFolders, currentCompany, loggedInUser, setCurrentFileIdx, setCurrentFile, file, idx, subFolder, searchResults } = this.props

        event.stopPropagation()

        if (file.type == "sharing") {
            setSharingState({ isModifying: true, modifyingIdx: idx, oldDomain: file.domain, oldName: file.name, oldEmail: file.email, newShareName: file.name, newShareEmail: file.email, newShareRole: file.role, newShareDateTo: file.dateTo, newShareDateFrom: file.dateFrom, newShareNote: file.note })
            return
        }

        if (searchResults && searchResults.length > 0) {
            if (file.subFolder == null) {
                mainFolders[file.mainFolder].folders[file.folder].files.forEach((f, index) => {
                    if (f.name == file.name) {
                        setCurrentFileIdx(index)
                    }
                })
            } else if (file.subFolder != null) {
                mainFolders[file.mainFolder].folders[file.folder].subFolders[file.subFolder].files.forEach((f, subIdx) => {
                    if (f.name == file.name) {
                        setCurrentFileIdx(subIdx)
                    }
                })
            }
        } else {
            setCurrentFileIdx(idx)
        }

        setCurrentFile(file)
        handleDocumentModalOpen(file, subFolder)
    }

    downloadFile = () => {
        const { file, currentCompany } = this.props

        api.get(`/${escapeForwardSlash(currentCompany.name)}/fileSrc/${file.src}`)
            .then(async res => {
                if (Object.keys(res.data).length > 0) {
                    if (res.data.row) {
                        await save(res.data.row.src, file.uploadedName)
                    }
                }
            })
    }

    handleUrgencyClick = (event, file) => {
        const { setCurrentFile, setMainFolders, idx, mainFolders, searchResults, searchValue, setCurrentMainFolder, setCurrentFileIdx, setCurrentFolder, currentFolder } = this.props

        event.stopPropagation()

        const newFile = { ...file }
        const newCurrentFolder = { ...currentFolder }
        newFile.urgency = newFile.urgency == "HIGH" ? "LOW" : (newFile.urgency == "DEFAULT" ? "HIGH" : "DEFAULT")
        newFile.missingDocumentDate = moment().format("YYYY-MM-DD")
        api.put(`/${escapeForwardSlash(newFile.company)}/file/${escapeForwardSlash(newFile.name)}/${escapeForwardSlash(newFile.mainFolder)}/${escapeForwardSlash(newFile.folder)}`, newFile)
            .then(res => {
                if (searchValue) {
                    const newMainFolder = { ...mainFolders[newFile.mainFolder] }
                    const newFolder = { ...newMainFolder.folders[newFile.folder] }
                    let foundIdx = 0
                    if (newFile.subFolder) {
                        for (const [index, file] of newFolder.subFolders[newFile.subFolder].files.entries()) {
                            if (file.name == newFile.name) {
                                foundIdx = index
                                break
                            }
                        }
                        newFolder.subFolders[newFile.subFolder].files.splice(foundIdx, 1, newFile)
                    } else {
                        for (const [index, file] of newFolder.files.entries()) {
                            if (file.name == newFile.name) {
                                foundIdx = index
                                break
                            }
                        }
                        newFolder.files.splice(foundIdx, 1, newFile)
                    }
                    newMainFolder.folders[newFolder.name] = newFolder
                    setMainFolders({ ...mainFolders, [newFile.mainFolder]: newMainFolder })
                } else if (file.subFolder) {
                    newCurrentFolder.subFolders[file.subFolder].files.splice(idx, 1, newFile)
                } else {
                    newCurrentFolder.files.splice(idx, 1, newFile)
                }
                setCurrentFolder(newCurrentFolder)
                setCurrentFile(newFile)
            })
    }

    render() {
        const { moreOptionsFileTarget } = this.state
        const { file, classes, isLastIdx, handleDeleteSharing, idx, handleDocumentModalOpen, handleShareClicked, handleDialogOpen, handleDocumentValuesContainerModalOpen, subFolder, setSearchValue, setCurrentFile, setCurrentFileIdx } = this.props

        return (
            <Fragment>
                <Grid item className={(file.type == "document" || file.type == "note") ? classes.fileActionBarDocument : classes.fileActionBar} onClick={(e) => { e.stopPropagation(); this.handleFileActionBarClicked(e) }}>
                    <Grid container direction="column" alignItems="center">
                        <Grid item className={classes.fileActionBarRow}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item className={(file.type == "document" || file.type == "note") ? isLastIdx ? classes.leftIconLastDocument : classes.leftIconDocument : isLastIdx ? classes.leftIconLast : classes.leftIcon} style={{ padding: file.type == "note" ? 12 : "unset" }}>
                                    {file.type == "note"
                                        ?
                                        <Note />
                                        : (file.type == "password"
                                            ?
                                            <Security />
                                            :
                                            (file.type == "sharing"
                                                ?
                                                <Person />
                                                :
                                                <IconButton onClick={(e) => { e.stopPropagation(); handleDocumentModalOpen(file, subFolder, true) }}>
                                                    <AttachFile />
                                                </IconButton>
                                            )
                                        )
                                    }
                                </Grid>
                                <Grid item xs style={{ width: "calc(100% - 60px)" }}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        {file.type == "sharing" &&
                                            <Fragment>
                                                <Grid item xs>
                                                    <Grid container direction="column">
                                                        <Grid item style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: (file.role || file.dateTo || file.dateFrom) ? 30 : 46 }}>
                                                            <Typography className={classes.fileActionBarFileName}>
                                                                {`${file.name} <${file.email}>`}
                                                            </Typography>
                                                        </Grid>
                                                        {(file.role || file.dateTo || file.dateFrom) &&
                                                            <Grid item className={classes.fileActionBarRow}>
                                                                <Grid container justify="space-between" alignItems="center">
                                                                    <Grid item>
                                                                        <Grid container direction="row" spacing={1}>
                                                                            {file.role &&
                                                                                <Grid item>
                                                                                    <Typography className={classes.fileActionBarLowerText}>
                                                                                        {file.role}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                            {file.dateFrom && !file.dateTo &&
                                                                                <Grid item>
                                                                                    <Typography className={classes.fileActionBarLowerText}>
                                                                                        Gælder fra: {moment(file.dateFrom).format("DD.MM.YYYY")}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                            {file.dateTo && !file.dateFrom &&
                                                                                <Grid item>
                                                                                    <Typography className={classes.fileActionBarLowerText}>
                                                                                        Gælder til: {moment(file.dateTo).format("DD.MM.YYYY")}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                            {file.dateTo && file.dateFrom &&
                                                                                <Grid item>
                                                                                    <Typography className={classes.fileActionBarLowerText}>
                                                                                        Periode: {moment(file.dateFrom).format("DD.MM.YYYY")} - {moment(file.dateTo).format("DD.MM.YYYY")}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={(e) => { e.stopPropagation(); this.handleMoreOptionsClicked(e) }}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu anchorEl={moreOptionsFileTarget} open={Boolean(moreOptionsFileTarget)} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose(e) }}>
                                                        <MenuItem key="Vis kontakt" onClick={(e) => { e.stopPropagation(); this.handleFileActionBarClicked(e); this.handleMoreOptionsClose(e) }}>
                                                            Vis kontakt
                                                        </MenuItem>
                                                        <MenuItem key="Slet kontakt" onClick={(e) => { e.stopPropagation(); handleDeleteSharing(file, idx); this.handleMoreOptionsClose(e) }}>
                                                            Slet kontakt
                                                        </MenuItem>
                                                    </Menu>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {file.type == "document" &&
                                            <Fragment>
                                                <Grid item xs={5} style={{ marginTop: (!file.dateDue && !file.periodTo && !file.periodFrom) ? 25 : "unset" }}>
                                                    <Typography className={classes.fileActionBarFileName}>
                                                        {file.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Grid container direction="row" alignItems="center" justify="flex-end" style={{ flexWrap: "nowrap" }} spacing={1}>
                                                        <Grid item className={classes.tagContainer}>
                                                            <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                <Grid item key="mainFolderTag">
                                                                    <Chip
                                                                        label={file.mainFolder}
                                                                        clickable={true}
                                                                        onClick={(e) => { e.stopPropagation(); setSearchValue(file.mainFolder) }}
                                                                    />
                                                                </Grid>
                                                                <Grid item key="folderTag">
                                                                    <Chip
                                                                        label={file.folder}
                                                                        clickable={true}
                                                                        onClick={(e) => { e.stopPropagation(); setSearchValue(file.folder) }}
                                                                    />
                                                                </Grid>
                                                                {file.subFolder != null &&
                                                                    <Grid item key="subFolderTag">
                                                                        <Chip
                                                                            label={file.subFolder}
                                                                            clickable={true}
                                                                            onClick={(e) => { e.stopPropagation(); setSearchValue(file.subFolder) }}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {file.tags.map((tag, idx) => {
                                                                    return (
                                                                        <Grid item key={`${tag}_${idx}`}>
                                                                            <Chip
                                                                                label={tag}
                                                                                clickable={true}
                                                                                onClick={(e) => { e.stopPropagation(); setSearchValue(tag) }}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); setCurrentFile(file); setCurrentFileIdx(idx); handleShareClicked(file, idx) }}>
                                                                <Share />
                                                            </IconButton>
                                                        </Grid>
                                                        {file.sharedWith.length > 0 &&
                                                            <Grid item className={classes.tagContainer}>
                                                                <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                    {file.sharedWith.map((name, idx) => {
                                                                        let initials = name.split(" ").map(namePart => namePart[0]).join("")
                                                                        
                                                                        return (
                                                                            <Grid item key={`${name}_${idx}_${name}`}>
                                                                                <Chip 
                                                                                    style={{ background: "lightgreen", fontWeight: 500 }}
                                                                                    label={initials}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); this.handleMoreOptionsClicked(e) }}>
                                                                <MoreVert />
                                                            </IconButton>
                                                            <Menu anchorEl={moreOptionsFileTarget} open={Boolean(moreOptionsFileTarget)} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose(e) }}>
                                                                <MenuItem key="Vis oplysninger" onClick={(e) => { e.stopPropagation(); handleDocumentValuesContainerModalOpen(file, subFolder); this.handleMoreOptionsClose(e) }}>
                                                                    Vis oplysninger
                                                                </MenuItem>
                                                                <MenuItem key="Vis dokument" onClick={(e) => { e.stopPropagation(); handleDocumentModalOpen(file, null, true); this.handleMoreOptionsClose(e) }}>
                                                                    Vis dokument
                                                                </MenuItem>
                                                                <MenuItem key="Download dokument" onClick={(e) => { e.stopPropagation(); this.downloadFile(); this.handleMoreOptionsClose(e) }}>
                                                                    Download dokument
                                                                </MenuItem>
                                                                <MenuItem key="Slet dokument" onClick={(e) => { e.stopPropagation(); handleDialogOpen("deleteFileDialog", idx); this.handleMoreOptionsClose(e) }}>
                                                                    Slet dokument
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={(event) => this.handleUrgencyClick(event, file)} style={{ color: "white", background: file.urgency == "HIGH" ? "crimson" : (file.urgency == "LOW" ? "limegreen" : "lightgrey"), justifyContent: "flex-end", minWidth: 30 }}>
                                                                <Attachment />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.fileActionBarRow}>
                                                    <Grid container justify="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={1}>
                                                                {file.nextReminder &&
                                                                    <Grid item>
                                                                        <Grid container direction="row" alignItems="center">
                                                                            <Grid item style={{ display: "flex", marginRight: 4 }}>
                                                                                <Alarm fontSize="small" />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography className={classes.fileActionBarLowerText}>
                                                                                    {moment(file.nextReminder).format("DD.MM.YYYY")}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                {file.dateDue &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Frist: {moment(file.dateDue).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {file.periodTo && !file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Gælder til: {moment(file.periodTo).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {!file.periodTo && file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Gælder fra: {moment(file.periodFrom).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {file.periodTo && file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Periode: {moment(file.periodFrom).format("DD.MM.YYYY")} - {moment(file.periodTo).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography className={classes.fileActionBarLowerText}>
                                                                {file.uploaderName &&
                                                                    `Uploadet ${moment(file.uploadDate).format("DD.MM.YYYY")} af ${file.uploaderName}`
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {file.type == "note" &&
                                            <Fragment>
                                                <Grid item xs={5} style={{ marginTop: (!file.dateDue && !file.periodTo && !file.periodFrom) ? 25 : "unset" }}>
                                                    <Typography className={classes.fileActionBarFileName}>
                                                        {file.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Grid container direction="row" alignItems="center" justify="flex-end" style={{ flexWrap: "nowrap" }} spacing={1}>
                                                        <Grid item className={classes.tagContainer}>
                                                            <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                <Grid item key="mainFolderTag">
                                                                    <Chip
                                                                        label={file.mainFolder}
                                                                        clickable={true}
                                                                        onClick={(e) => { e.stopPropagation(); setSearchValue(file.mainFolder) }}
                                                                    />
                                                                </Grid>
                                                                <Grid item key="folderTag">
                                                                    <Chip
                                                                        label={file.folder}
                                                                        clickable={true}
                                                                        onClick={(e) => { e.stopPropagation(); setSearchValue(file.folder) }}
                                                                    />
                                                                </Grid>
                                                                {file.subFolder != null &&
                                                                    <Grid item key="subFolderTag">
                                                                        <Chip
                                                                            label={file.subFolder}
                                                                            clickable={true}
                                                                            onClick={(e) => { e.stopPropagation(); setSearchValue(file.subFolder) }}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {file.tags.map((tag, idx) => {
                                                                    return (
                                                                        <Grid item key={`${tag}_${idx}`}>
                                                                            <Chip
                                                                                label={tag}
                                                                                clickable={true}
                                                                                onClick={(e) => { e.stopPropagation(); setSearchValue(tag) }}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); setCurrentFile(file); setCurrentFileIdx(idx); handleShareClicked(file, idx) }}>
                                                                <Share />
                                                            </IconButton>
                                                        </Grid>
                                                        {file.sharedWith.length > 0 &&
                                                            <Grid item className={classes.tagContainer}>
                                                                <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                    {file.sharedWith.map((name, idx) => {
                                                                        let initials = name.split(" ").map(namePart => namePart[0]).join("")
                                                                        
                                                                        return (
                                                                            <Grid item key={`${name}_${idx}_${name}`}>
                                                                                <Chip 
                                                                                    style={{ background: "lightgreen", fontWeight: 500 }}
                                                                                    label={initials}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); this.handleMoreOptionsClicked(e) }}>
                                                                <MoreVert />
                                                            </IconButton>
                                                            <Menu anchorEl={moreOptionsFileTarget} open={Boolean(moreOptionsFileTarget)} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose(e) }}>
                                                                <MenuItem key="Vis oplysninger" onClick={(e) => { e.stopPropagation(); handleDocumentValuesContainerModalOpen(file, subFolder); this.handleMoreOptionsClose(e) }}>
                                                                    Vis oplysninger
                                                                </MenuItem>
                                                                <MenuItem key="Slet note" onClick={(e) => { e.stopPropagation(); handleDialogOpen("deleteFileDialog", idx); this.handleMoreOptionsClose(e) }}>
                                                                    Slet note
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={(event) => this.handleUrgencyClick(event, file)} style={{ color: "white", background: file.urgency == "HIGH" ? "crimson" : (file.urgency == "LOW" ? "limegreen" : "lightgrey"), justifyContent: "flex-end", minWidth: 30 }}>
                                                                <Attachment />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.fileActionBarRow}>
                                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={2} alignItems="center">
                                                                {file.nextReminder &&
                                                                    <Grid item>
                                                                        <Grid container direction="row" alignItems="center">
                                                                            <Grid item style={{ display: "flex", marginRight: 4 }}>
                                                                                <Alarm fontSize="small" />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography className={classes.fileActionBarLowerText}>
                                                                                    {moment(file.nextReminder).format("DD.MM.YYYY")}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                {file.dateDue &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Frist: {moment(file.dateDue).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {file.periodTo && !file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Gælder til: {moment(file.periodTo).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {!file.periodTo && file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Gælder fra: {moment(file.periodFrom).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {file.periodTo && file.periodFrom &&
                                                                    <Grid item>
                                                                        <Typography className={classes.fileActionBarLowerText}>
                                                                            Periode: {moment(file.periodFrom).format("DD.MM.YYYY")} - {moment(file.periodTo).format("DD.MM.YYYY")}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography className={classes.fileActionBarLowerText}>
                                                                {file.uploaderName &&
                                                                    `Oprettet ${moment(file.uploadDate).format("DD.MM.YYYY")} af ${file.uploaderName}`
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {file.type == "share" &&
                                            <Fragment>
                                                <Grid item>
                                                    <Typography className={classes.fileActionBarFileName}>
                                                        {file.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="center" justify="center">
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); setCurrentFile(file); setCurrentFileIdx(idx); handleShareClicked(file, idx) }}>
                                                                <Share />
                                                            </IconButton>
                                                        </Grid>
                                                        {file.sharedWith.length > 0 &&
                                                            <Grid item className={classes.tagContainer}>
                                                                <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                    {file.sharedWith.map((name, idx) => {
                                                                        let initials = name.split(" ").map(namePart => namePart[0]).join("")
                                                                        
                                                                        return (
                                                                            <Grid item key={`${name}_${idx}_${name}`}>
                                                                                <Chip 
                                                                                    style={{ background: "lightgreen", fontWeight: 500 }}
                                                                                    label={initials}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <IconButton onClick={this.handleMoreOptionsClicked}>
                                                                <MoreVert />
                                                            </IconButton>
                                                            <Menu anchorEl={moreOptionsFileTarget} open={Boolean(moreOptionsFileTarget)} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose(e) }}>
                                                                <MenuItem key="Vis oplysninger" onClick={(e) => { e.stopPropagation(); handleDocumentValuesContainerModalOpen(file, subFolder); this.handleMoreOptionsClose(e) }}>
                                                                    Vis oplysninger
                                                                </MenuItem>
                                                                <MenuItem key="Vis dokument" onClick={(e) => { e.stopPropagation(); handleDocumentModalOpen(file, null, true); this.handleMoreOptionsClose(e) }}>
                                                                    Vis dokument
                                                                </MenuItem>
                                                                <MenuItem key="Slet dokument" onClick={(e) => { e.stopPropagation(); handleDialogOpen("deleteFileDialog", idx); this.handleMoreOptionsClose(e) }}>
                                                                    Slet dokument
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={(event) => this.handleUrgencyClick(event, file)} style={{ color: "white", background: file.urgency == "HIGH" ? "crimson" : (file.urgency == "LOW" ? "limegreen" : "lightgrey"), justifyContent: "flex-end", minWidth: 30 }}>
                                                                <Attachment />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {file.type == "password" &&
                                            <Fragment>
                                                <Grid item className={classes.codebankRow} xs={5}>
                                                    <Typography className={classes.fileActionBarText} style={{ fontWeight: 500 }}>
                                                        {file.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start" }}>
                                                    <Typography>{file.IP}</Typography>
                                                </Grid>
                                                <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <CopyToClipboard text={file.password}>
                                                            <IconButton style={{ padding: 8 }}>
                                                                <FileCopy />
                                                            </IconButton>
                                                        </CopyToClipboard>
                                                        <Typography style={{ fontSize: 14 }}>Kopiér</Typography>
                                                    </div>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {!isLastIdx &&
                                            <Grid item className={classes.fileActionBarRowDivider}>
                                                <Divider />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

const styles = theme => ({
    fileActionBar: {
        width: "100%",
        background: "white",
        '&:hover': {
            background: "rgba(44, 130, 201, 0.05)"
        },
        '&:not(:last-child)': {
            paddingBottom: 0
        },
        transition: "background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        padding: "8px 20px 17px",
    },
    fileActionBarDocument: {
        width: "100%",
        background: "white",
        '&:hover': {
            background: "rgba(44, 130, 201, 0.05)"
        },
        '&:not(:last-child)': {
            paddingBottom: 0
        },
        transition: "background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        paddingTop: 8,
        paddingBottom: 17,
        paddingRight: 20
    },
    fileActionBarRow: {
        width: "100%"
    },
    fileActionBarRowDivider: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    fileActionBarFileName: {
        fontSize: 18,
        fontWeight: 500,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileActionBarText: {
        fontSize: 16
    },
    fileActionBarLowerText: {
        fontSize: 13
    },
    codebankRow: {
        height: 65,
        display: "flex",
        alignItems: "center"
    },
    tagContainer: {
        overflowX: "scroll",
        padding: 4,
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    leftIconLast: {
        marginRight: 16,
        marginTop: 16
    },
    leftIcon: {
        marginRight: 16,
        marginTop: 0
    },
    leftIconDocument: {
        marginRight: 4,
        marginLeft: 8,
        marginTop: 0,
        marginBottom: 8
    },
    leftIconLastDocument: {
        marginRight: 4,
        marginLeft: 8,
        marginTop: 4,
        marginBottom: 0
    },
    sharedWithText: {
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        },
        whiteSpace: "nowrap",
        overflow: "scroll",
        maxWidth: "100%"
    }
})

const mapStateToProps = ({ state }) => {
    return {
        searchValue: state.searchValue,
        currentFolder: state.currentFolder,
        mainFolders: state.mainFolders,
        currentCompany: state.currentCompany
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setCurrentFileIdx, setMainFolders, setCurrentFile, setSearchValue, setCurrentFolder, setCurrentMainFolder }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(FileActionBar))