import React, { Fragment } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { makeStyles } from '@material-ui/styles'
import { Grid, Paper, Typography, Dialog, List, DialogContent, DialogTitle, TextField, DialogContentText, Button, Icon, Tabs, Tab, CircularProgress, Divider, IconButton, ListItem, ListItemText, ListItemSecondaryAction, Checkbox } from '@material-ui/core'
import { Close, Alarm } from "@material-ui/icons"
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter, Redirect } from "react-router-dom"
import CalendarComponent from "react-material-ui-calendar"
import DocumentModal from "../components/DocumentModal"
import DocumentValuesContainer from "../components/DocumentValuesContainer"
import moment from "moment"
import api, { escapeForwardSlash } from "../api"
import dateFns from 'date-fns';
import { setCurrentFile, setDeadlineItems, setMainFolders } from "../actions/actions"

class Calendar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isExceededItemsDialogOpen: false,
            isUpcomingItemsDialogOpen: false,
            isDocumentValuesContainerModalOpen: false,
            isDocumentModalOpen: false,
            lastWasExceeded: null,
            selectedDayItems: null
        }
    }

    componentDidMount() {
        this.getDeadlineItems()
    }

    getDeadlineItems = () => {
        const { mainFolders, setDeadlineItems } = this.props
        const { lastWasExceeded } = this.state
        const currentDate = moment().format("YYYY-MM-DD")

        const exceededItems = [] 
        const upcomingItems = []
        Object.values(mainFolders).forEach(mainFolder => {
            Object.values(mainFolder.folders).forEach(folder => {
                folder.files.forEach(folderFile => {
                    if (moment(folderFile.dateDue).isBefore(currentDate, "day") && !folderFile.isRemovedExceededDeadlines) {
                        exceededItems.push({ ...folderFile, sortDate: folderFile.dateDue, isReminder: false })
                    }
                    if (moment(folderFile.nextReminder).isBefore(currentDate, "day") && !folderFile.isRemovedExceededDeadlines) {
                        exceededItems.push({ ...folderFile, sortDate: folderFile.nextReminder, isReminder: true })
                    }

                    if (moment(folderFile.dateDue).diff(currentDate, "days") >= 0 && moment(folderFile.dateDue).diff(currentDate, "years") <= 1 && !folderFile.isRemovedUpcomingDeadlines) {
                        upcomingItems.push({ ...folderFile, sortDate: folderFile.dateDue, isReminder: false })
                    }
                    if (moment(folderFile.nextReminder).diff(currentDate, "days") >= 0 && moment(folderFile.nextReminder).diff(currentDate, "years") <= 1 && !folderFile.isRemovedUpcomingDeadlines) {
                        upcomingItems.push({ ...folderFile, sortDate: folderFile.nextReminder, isReminder: true })
                    }
                })

                Object.values(folder.subFolders).forEach(subFolder => {
                    subFolder.files.forEach(subFolderFile => {
                        if (moment(subFolderFile.dateDue).isBefore(currentDate, "day") && !subFolderFile.isRemovedExceededDeadlines) {
                            exceededItems.push({ ...subFolderFile, sortDate: subFolderFile.dateDue, isReminder: false })
                        }
                        if (moment(subFolderFile.nextReminder).isBefore(currentDate, "day") && !subFolderFile.isRemovedExceededDeadlines) {
                            exceededItems.push({ ...subFolderFile, sortDate: subFolderFile.nextReminder, isReminder: true })
                        }

                        if (moment(subFolderFile.dateDue).diff(currentDate, "days") >= 0 && moment(subFolderFile.dateDue).diff(currentDate, "years") <= 1 && !subFolderFile.isRemovedUpcomingDeadlines) {
                            upcomingItems.push({ ...subFolderFile, sortDate: subFolderFile.dateDue, isReminder: false })
                        }
                        if (moment(subFolderFile.nextReminder).diff(currentDate, "days") >= 0 && moment(subFolderFile.nextReminder).diff(currentDate, "years") <= 1 && !subFolderFile.isRemovedUpcomingDeadlines) {
                            upcomingItems.push({ ...subFolderFile, sortDate: subFolderFile.nextReminder, isReminder: true })
                        }
                    })
                })
            })
        })

        setDeadlineItems({ upcoming: upcomingItems, exceeded: exceededItems })
    }

    handleCalendarDayClicked = (selectedDay, items, isExceeded) => {
        const { deadlineItems } = this.props

        this.setState({
            selectedDayItems: items,
            lastWasExceeded: isExceeded,
            isExceededItemsDialogOpen: !items ? false : (isExceeded ? true : false),
            isUpcomingItemsDialogOpen: !items ? false : (!isExceeded ? true : false)
        })
    }

    handleDocumentModalClose = () => {
        this.setState({ isDocumentModalOpen: false })
    }

    handleDeleteItem = (items) => {
        const { setDeadlineItems } = this.props
        this.setState({ selectedDayItems: items }, () => this.getDeadlineItems())
    }

    handleDocumentValuesContainerModalClose = (e) => {
        const { setCurrentFile } = this.props

        e && e.stopPropagation()

        this.setState({ isDocumentValuesContainerModalOpen: false }, () => setCurrentFile(null))
    }

    handleDocumentValuesContainerModalOpen = (file, subFolder) => {
        const { setCurrentFile, mainFolders, currentMainFolder, currentFolder, setCurrentSubFolder } = this.props
        setCurrentFile(file)
        subFolder && setCurrentSubFolder(mainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[subFolder.name])
        this.setState({ isDocumentValuesContainerModalOpen: true, isExceededItemsDialogOpen: false, isUpcomingItemsDialogOpen: false })
    }

    handleDocumentModalOpen = (file, subFolder, didClickAttachedFileButton) => {
        const { setCurrentFile, handleDocumentValuesContainerModalOpen, loggedInUser, currentCompany } = this.props

        if (file.type == "password") {
            return
        } else if (didClickAttachedFileButton) {
            setCurrentFile(file)
            this.setState({ isDocumentModalOpen: true })
        } else if (file.type == "note" || file.type == "document") {
            setCurrentFile(file)
            handleDocumentValuesContainerModalOpen(file, subFolder)

            const newActivity = {
                company: currentCompany.name,
                user: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                date: moment(),
                event: file.name
            }

            api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Åbnet" })
        } 
    }

    handleSetNewAttachedFile = file => {
        this.setState({ newAttachedFile: file })
    }

    render() {
        const { classes, deadlineItems, currentFile, currentCompany } = this.props
        const { isExceededItemsDialogOpen, newAttachedFile, isUpcomingItemsDialogOpen, isDocumentModalOpen, selectedDayItems, isDocumentValuesContainerModalOpen } = this.state

        return (    
            <div className={classes.root}>
                <CalendarComponent light={true} deadlineItems={deadlineItems} handleCalendarDayClicked={this.handleCalendarDayClicked} />

                <Dialog open={isExceededItemsDialogOpen} maxWidth={false} onClose={() => this.setState({ isExceededItemsDialogOpen: false })} onClick={(e) => e.stopPropagation()}>
                    {ExceededDeadlinesBox({ ...this.props, selectedDayItems, handleDeleteItem: this.handleDeleteItem, handleDocumentValuesContainerModalOpen: this.handleDocumentValuesContainerModalOpen })}
                </Dialog>

                <Dialog open={isUpcomingItemsDialogOpen} maxWidth={false} onClose={() => this.setState({ isUpcomingItemsDialogOpen: false })} onClick={(e) => e.stopPropagation()}>
                    {UpcomingDeadlinesBox({ ...this.props, selectedDayItems, handleDeleteItem: this.handleDeleteItem, handleDocumentValuesContainerModalOpen: this.handleDocumentValuesContainerModalOpen })}
                </Dialog>

                {isDocumentModalOpen &&
                    <DocumentModal
                        isOpen={isDocumentModalOpen}
                        handleModalClose={this.handleDocumentModalClose}
                        newAttachedFile={newAttachedFile}
                    />
                }

                {isDocumentValuesContainerModalOpen &&
                    <Dialog open={isDocumentValuesContainerModalOpen} onClose={e => this.handleDocumentValuesContainerModalClose(e)} classes={{ paperScrollPaper: classes.paperScrollPaper }}>
                        <DocumentValuesContainer
                            handleDocumentModalOpen={this.handleDocumentModalOpen}
                            selectedFileType={currentFile.type}
                            handleModalClose={this.handleDocumentValuesContainerModalClose}
                            handleSetNewAttachedFile={this.handleSetNewAttachedFile}
                            isDirectLink={true}
                            refreshDashboardItems={this.getDeadlineItems}
                        />
                    </Dialog>
                }
            </div>
        )
    }
}

const ExceededDeadlinesBox = (props) => {
    const { classes, selectedDayItems, handleDeleteItem, handleDocumentValuesContainerModalOpen } = props

    return (
        <Grid item style={{ width: "50vw", height: "100%" }}>
            <Paper className={classes.paper}>
                <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" justify="space-between" style={{ width: "100%" }}>
                            <Grid item>
                                <Typography variant="h6">Overskredne frister og påmindelser</Typography>
                            </Grid>
                            <Grid item>
                                <Alarm style={{ fontSize: "1.8rem", color: "crimson" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: "calc(100% - 36px)", width: "100%" }}>
                        <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                            {selectedDayItems && selectedDayItems.length > 0 
                                ?
                                <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", width: "100%", overflow: "scroll", paddingRight: 12, paddingLeft: 12 }}>
                                    <ExceededDeadlines items={selectedDayItems} {...props} handleDelete={handleDeleteItem} handleOpenDVC={handleDocumentValuesContainerModalOpen} />
                                </Grid>
                                :
                                <Grid item style={{ marginTop: 8, paddingLeft: 12 }}>
                                    <Typography>Der er ingen overskredne frister</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

const UpcomingDeadlinesBox = (props) => {
    const { classes, selectedDayItems, handleDeleteItem, handleDocumentValuesContainerModalOpen } = props

    return (
        <Grid item style={{ width: "50vw", height: "100%" }}>
            <Paper className={classes.paper}>
                <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                    <Grid item style={{ width: "100%" }}>
                        <Grid container direction="row" justify="space-between" style={{ width: "100%" }}>
                            <Grid item>
                                <Typography variant="h6">Kommende frister og påmindelser</Typography>
                            </Grid>
                            <Grid item>
                                <Alarm style={{ fontSize: "1.8rem", color: "limegreen" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: "calc(100% - 36px)",  width: "100%" }}>
                        <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                            {selectedDayItems && selectedDayItems.length > 0
                                ?
                                <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", overflow: "scroll", paddingRight: 12, paddingLeft: 12, width: "100%" }}>
                                    <UpcomingDeadlines items={selectedDayItems} {...props} handleDelete={handleDeleteItem} handleOpenDVC={handleDocumentValuesContainerModalOpen} />
                                </Grid>
                                :
                                <Grid item>
                                    <Typography style={{ marginTop: 8, paddingLeft: 12 }}>Der er ingen kommende frister</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

const ExceededDeadlines = (props) => {
    const { items, mainFolders, setMainFolders, currentCompany, history, handleOpenDVC, setCurrentFile, setSearchValue, setSearchResults, handleDelete, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left"
            }
        }
    })

    const classes = useStyles()

    const handleClick = (idx, sortedItems) => {
        const newItems = [ ...items ]

        const removedItem = sortedItems[idx]
        removedItem.isRemovedExceededDeadlines = true
        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(removedItem.name)}/${escapeForwardSlash(removedItem.mainFolder)}/${escapeForwardSlash(removedItem.folder)}`, removedItem)

        if (removedItem.isReminder) {
            for (const itemIdx in newItems) {
                if (newItems[itemIdx].name == removedItem.name) {
                    newItems.splice(itemIdx, 1)
                    break
                }
            }
        } else {
            for (const itemIdx in newItems) {
                if (newItems[itemIdx].name == removedItem.name) {
                    newItems.splice(itemIdx, 1)
                    break
                }
            }
        }

        handleDelete(newItems)

        const newMainFolders = { ...mainFolders }

        if (removedItem.subFolder) {
            const subFolderFiles = newMainFolders[removedItem.mainFolder].folders[removedItem.folder].subFolders[removedItem.subFolder].files
            for (const fileIdx in subFolderFiles) {
                if (subFolderFiles[fileIdx].name == removedItem.name) {
                    subFolderFiles[fileIdx] = removedItem
                }
            }
        } else {
            const folderFiles = newMainFolders[removedItem.mainFolder].folders[removedItem.folder].files
            for (const fileIdx in folderFiles) {
                if (folderFiles[fileIdx].name == removedItem.name) {
                    folderFiles[fileIdx] = removedItem
                }
            }
        }

        setMainFolders(newMainFolders)
    }

    const handleNameClick = (item) => {
        handleOpenDVC(item)
    }

    const currentDate = moment().format("YYYY-MM-DD")
    const sortedItems = items.sort((a, b) => a.name < b.name ? 1 : -1)

    return (
        <Grid container direction="column">
            {sortedItems.map((item, idx) => {
                let dateToDisplay = item.isReminder ? `(${moment(item.nextReminder).format("DD.MM.YYYY")})` : moment(item.dateDue).format("DD.MM.YYYY")
                let daysInParenthesis = (item.isReminder ? () => {
                    const dateDiff = moment(item.nextReminder).diff(currentDate, "days")
                    if (dateDiff == -1) {
                        return "i går"
                    } else {
                        return `${Math.abs(dateDiff)}d`
                    }
                }
                    :
                    () => {
                        const dateDiff = moment(item.dateDue).diff(currentDate, "days")
                        if (dateDiff == -1) {
                            return "i går"
                        } else {
                            return `${Math.abs(dateDiff)}d`
                        }
                    })()
                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={4} style={{ display: "flex" }}>
                                <Typography>{`${dateToDisplay}`}</Typography>
                                <Typography style={{ color: "crimson", marginLeft: 4 }}>{`(${daysInParenthesis})`}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleNameClick(item)}
                                    style={{ width: "100%" }}
                                >
                                    <Typography style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.name}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton
                                    onClick={() => handleClick(idx, sortedItems)}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const UpcomingDeadlines = (props) => {
    const { items, currentCompany, setMainFolders, mainFolders, history, setItemsModified, handleOpenDVC, setCurrentFile, setSearchValue, setSearchResults, handleDelete, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left"
            }
        }
    })

    const classes = useStyles()

    const handleClick = (idx, sortedItems) => {
        const newItems = [ ...items ]

        const removedItem = sortedItems[idx]
        removedItem.isRemovedUpcomingDeadlines = true
        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(removedItem.name)}/${escapeForwardSlash(removedItem.mainFolder)}/${escapeForwardSlash(removedItem.folder)}`, removedItem)

        if (removedItem.isReminder) {
            for (const itemIdx in newItems) {
                if (newItems[itemIdx].name == removedItem.name) {
                    newItems.splice(itemIdx, 1)
                    break
                }
            }
        } else {
            for (const itemIdx in newItems) {
                if (newItems[itemIdx].name == removedItem.name) {
                    newItems.splice(itemIdx, 1)
                    break
                }
            }
        }

        handleDelete(newItems)

        const newMainFolders = { ...mainFolders }

        if (removedItem.subFolder) {
            const subFolderFiles = newMainFolders[removedItem.mainFolder].folders[removedItem.folder].subFolders[removedItem.subFolder].files
            for (const fileIdx in subFolderFiles) {
                if (subFolderFiles[fileIdx].name == removedItem.name) {
                    subFolderFiles[fileIdx] = removedItem
                }
            }
        } else {
            const folderFiles = newMainFolders[removedItem.mainFolder].folders[removedItem.folder].files
            for (const fileIdx in folderFiles) {
                if (folderFiles[fileIdx].name == removedItem.name) {
                    folderFiles[fileIdx] = removedItem
                }
            }
        }

        setMainFolders(newMainFolders)
    }

    const handleNameClick = (item) => {
        handleOpenDVC(item)
    }

    const currentDate = moment().format("YYYY-MM-DD")
    const sortedItems = items.sort((a, b) => a.name < b.name ? 1 : -1)

    return (
        <Grid container direction="column">
            {sortedItems.map((item, idx) => {
                let dateToDisplay = item.isReminder ? `(${moment(item.nextReminder).format("DD.MM.YYYY")})` : moment(item.dateDue).format("DD.MM.YYYY")
                let daysInParenthesis = (item.isReminder ? () => {
                    const dateDiff = moment(item.nextReminder).diff(currentDate, "days")
                    if (dateDiff == 0) {
                        return "i dag"
                    } else if (dateDiff == 1) {
                        return "i morgen"
                    } else {
                        return `${dateDiff}d`
                    }
                }
                    :
                    () => {
                        const dateDiff = moment(item.dateDue).diff(currentDate, "days")
                        if (dateDiff == 0) {
                            return "i dag"
                        } else if (dateDiff == 1) {
                            return "i morgen"
                        } else {
                            return `${dateDiff}d`
                        }
                    })()

                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{`${dateToDisplay} (${daysInParenthesis})`}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleNameClick(item)}
                                    style={{ width: "100%" }}
                                >
                                    <Typography style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.name}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton
                                    onClick={() => handleClick(idx, sortedItems)}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const styles = theme => ({
    root: {
        width: "unset",
        margin: "unset",
        height: "calc(100vh - 150px)",
        padding: theme.spacing(4),
        position: "relative"
    },
    paper: {
        height: "100%",
        width: "100%",
        minHeight: 210,
        padding: 12
    }
})

const mapStateToProps = ({ state }) => {
    return {
        mainFolders: state.mainFolders,
        isLoading: state.isLoading,
        loggedInUser: state.loggedInUser,
        deadlineItems: state.deadlineItems,
        currentFile: state.currentFile,
        currentCompany: state.currentCompany
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setCurrentFile, setDeadlineItems, setMainFolders }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Calendar))