let initialState = {
    sharing: {},
    companies: [],
    currentCompany: null,
    isLoading: true,
    mainFolders: {},
    mainFoldersEntities: {},
    uploadedFiles: {},
    searchValue: "",
    loggedInUser: null,
    currentMainFolder: null,
    currentFolder: null,
    currentSubFolder: null,
    currentFile: null,
    currentFileIdx: null,
    currentMainFolderEntities: null,
    currentFolderEntities: null,
    currentSubFolderEntities: null,
    currentDomain: null
}

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SHARING":
            return {
                ...state,
                sharing: action.payload
            }
        case "SET_CURRENT_DOMAIN":
            return {
                ...state,
                currentDomain: action.payload
            }
        case "SET_COMPANIES":
            return {
                ...state,
                companies: action.payload
            }
        case "SET_CURRENT_COMPANY":
            const newCompany = { ...action.payload }
            return {
                ...state,
                currentCompany: newCompany
            }
        case "SET_MAIN_FOLDERS":
            return {
                ...state,
                mainFolders: action.payload
            }
        case "SET_UPLOADED_FILES":
            return {
                ...state,
                uploadedFiles: action.payload
            }
        case "SET_CURRENT_MAIN_FOLDER":
            return {
                ...state,
                currentMainFolder: action.payload
            }
        case "SET_CURRENT_FOLDER":
            return {
                ...state,
                currentFolder: action.payload
            }
        case "SET_CURRENT_SUB_FOLDER":
            return {
                ...state,
                currentSubFolder: action.payload
            }
        case "SET_CURRENT_FILE":
            return {
                ...state,
                currentFile: action.payload
            }
        case "SET_CURRENT_FILE_IDX":
            return {
                ...state,
                currentFileIdx: action.payload
            }
        case "SET_MAIN_FOLDERS_ENTITIES":
            return {
                ...state,
                mainFoldersEntities: action.payload
            }
        case "SET_CURRENT_MAIN_FOLDER_ENTITIES":
            return {
                ...state,
                currentMainFolderEntities: action.payload
            }
        case "SET_CURRENT_FOLDER_ENTITIES":
            return {
                ...state,
                currentFolderEntities: action.payload
            }
        case "SET_CURRENT_SUB_FOLDER_ENTITIES":
            return {
                ...state,
                currentSubFolderEntities: action.payload
            }
        case "SET_SEARCH_VALUE":
            return {
                ...state,
                searchValue: action.payload
            }
        case "SET_SEARCH_RESULTS":
            return {
                ...state,
                searchResults: action.payload
            }
        case "SET_LOGGED_IN_USER":
            return {
                ...state,
                loggedInUser: action.payload
            }
        case "SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_DEADLINE_ITEMS":
            return {
                ...state,
                deadlineItems: action.payload
            }
        default:
            return state
    }
}

export default reducers