import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"

import App from "./App"
import LoginRequiredRoute from "./LoginRequiredRoute"
import Login from "./screens/Login"

class MainApp extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/login" component={Login} />
                <LoginRequiredRoute component={App} />            
            </Switch>
        )
    }
}


export default withRouter(MainApp)