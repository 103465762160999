const urgencyLevels = {
    HIGH: "HIGH",
    LOW: "LOW",
    DEFAULT: "DEFAULT"
}

const entityTypes = {
    PERSON: "person",
    COMPANY: "company"
}

const folderIcons = {
    KEY: "VpnKey",
    FOLDER: "Folder",
    COMPANY: "Business",
    PERSON: "Face"
}

const fileTypes = {
    DOCUMENT: "document",
    NOTE: "note",
    PASSWORD: "password",
    SHARE: "share"
}

module.exports = {
    urgencyLevels: urgencyLevels,
    entityTypes: entityTypes,
    folderIcons: folderIcons,
    fileTypes: fileTypes
}