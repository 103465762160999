import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { Toolbar, Grid, Typography, Button } from '@material-ui/core'
import { } from "@material-ui/icons"

class Footer extends React.PureComponent {
    render() {
        const { classes } = this.props

        return (
            <footer className={classes.footer}>
                <Toolbar style={{ minHeight: 48 }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography 
                                variant="subtitle1" 
                                color="textSecondary"
                                style={{ fontSize: 18 }}
                            >© MinVirksomhed</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                style={{ color: "#ffffff" }}
                                size="medium"
                            >Feedback & Kontakt</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </footer>
        )
    }
}

const styles = theme => ({
    footer: {
        marginTop: "auto",
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.secondary.main,
        color: "#fff"
    },
    button: {
        '&:hover': {
            color: "#ffffff",
            background: "rgba(44, 130, 201, 0.2)"
        },
    }
})

export default compose(
    withStyles(styles),
)(withRouter(Footer))