import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, AppBar, Toolbar, Typography, List, ListItem, ListItemText, Avatar, ListItemAvatar, ButtonBase, Drawer, InputBase, InputAdornment, IconButton, Button, Divider } from '@material-ui/core'
import { Search, Close, Settings } from "@material-ui/icons"
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from "moment"
import api, { escapeForwardSlash } from "../api"
import InformationSnackbar from "./InformationSnackbar"
import { setIsLoading, setLoggedInUser, setSearchResults, setSharing, setSearchValue, setMainFolders, setUploadedFiles, setCurrentCompany, setCurrentSubFolder, setCurrentMainFolder, setCurrentFolder, setCurrentFile, setCurrentFileIdx } from "../actions/actions"

class Header extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isSelectCompanyDrawerOpen: false,
            warningBarMessage: "",
            isWarningBarOpen: false
        }
    }

    handleLogout = () => {
        const { setLoggedInUser, setSearchValue, setSearchResults } = this.props
        setLoggedInUser(null)
        setSearchValue("")
        setSearchResults(null)
    }

    handleDrawerOpen = () => {
        this.setState({ isSelectCompanyDrawerOpen: true })
    }

    handleDrawerClose = () => {
        this.setState({ isSelectCompanyDrawerOpen: false })
    }

    handleWarningBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isWarningBarOpen: false, warningBarMessage: "" })
    }

    handleWarningBarOpen = (message) => {
        this.setState({ isWarningBarOpen: true, warningBarMessage: message })
    }

    handleSearchChange = (e) => {
        const { setSearchValue, setSearchResults, getSearchResults } = this.props

        const newValue = e.target.value
        setSearchValue(newValue)
        setSearchResults(getSearchResults(newValue))
    }

    handleCompanyChange = (company) => {
        const { setIsLoading, setSharing, setMainFolders, setSearchResults, setSearchValue, setUploadedFiles, setCurrentCompany, setCurrentSubFolder, setCurrentMainFolder, setCurrentFolder, setCurrentFile, setCurrentFileIdx } = this.props

        setIsLoading(true)

        api.get(`/${escapeForwardSlash(company.name)}/fetchAllData`)
            .then(res => {
                setMainFolders(res.data.mainFolders)
                setSharing(res.data.sharing)
                setUploadedFiles(res.data.uploadedFiles)
                setCurrentCompany(company)
                setCurrentFile(null)
                setCurrentFileIdx(null)
                setCurrentSubFolder(null)
                setCurrentFolder(null)
                setCurrentMainFolder(null)
                setSearchValue("")
                setSearchResults(null)
                setIsLoading(false)
            })

        this.handleDrawerClose()
    }

    handleAdminPageClicked = () => {
        const { loggedInUser, setSearchValue, setSearchResults } = this.props

        if (loggedInUser.username == "zahid" || loggedInUser.username == "mathias") {
            setSearchValue("")
            setSearchResults(null)
            this.props.history.push("/admin")
        } else {
            this.handleWarningBarOpen("Kun administratorer kan åbne denne side")
        }
    }

    render() {
        const { isSelectCompanyDrawerOpen, isWarningBarOpen, warningBarMessage } = this.state
        const { classes, searchValue, loggedInUser, setSearchValue, currentCompany, isLoading, companies, setSearchResults } = this.props

        return (
            <Fragment>
                <AppBar className={classes.appBar} position="sticky">
                    <Toolbar style={{ padding: 0 }}>
                        <Grid className={classes.root} container>
                            <Grid className={`${classes.root} ${classes.headerTop}`} item>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <ButtonBase onClick={this.handleDrawerOpen}>
                                            <Grid container direction="row" justify="center" alignItems="center">
                                                {currentCompany &&
                                                    <Fragment>
                                                        <Grid className={classes.logoContainer} item>
                                                            <Avatar
                                                                className={classes.logo}
                                                                src={currentCompany.logoSrc}
                                                                alt="Company Logo"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{ lineHeight: 1.2 }}
                                                                        variant="h6"
                                                                        align="left"
                                                                        color="textSecondary"
                                                                    >{currentCompany.name}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{ lineHeight: 1.2, fontSize: 14 }}
                                                                        variant="subtitle1"
                                                                        align="left"
                                                                        color="textSecondary"
                                                                    >{`CVR ${currentCompany.cvr}`}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>
                                                }
                                            </Grid>
                                        </ButtonBase>
                                    </Grid>
                                    <Grid style={{ marginRight: 6 }} item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item>
                                                <div className={classes.search}>
                                                    <div className={classes.searchIcon}>
                                                        <Search />
                                                    </div>
                                                    <InputBase
                                                        placeholder="Søg..."
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput,
                                                        }}
                                                        onChange={this.handleSearchChange}
                                                        value={searchValue}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton size="small" style={{ color: "black" }} onClick={() => { setSearchValue(""); setSearchResults(null) }}>
                                                                    <Close />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />

                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Button onClick={this.handleAdminPageClicked} style={{ marginRight: -8, textTransform: "unset" }}>
                                                            <Typography
                                                                style={{ lineHeight: 1.2 }}
                                                                align="right"
                                                                variant="h6"
                                                                color="textSecondary"
                                                            >
                                                                {loggedInUser ? `${loggedInUser.firstname} ${loggedInUser.lastname}` : "Ikke logget ind"}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            style={{ lineHeight: 1.2, fontSize: 14 }}
                                                            align="right"
                                                            variant="subtitle1"
                                                            color="textSecondary"
                                                        >{moment().format("DD.MM.YYYY")}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.root} item>
                                <Divider className={classes.divider} variant="fullWidth" />
                            </Grid>
                            <Grid className={`${classes.root} ${classes.headerBottom}`} item>
                                <Grid container direction="row" justify="space-between">
                                    <Grid item>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/home") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Dashboard</Typography></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/folderlist") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Mapper</Typography></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/sharing") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Deling</Typography></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/upload") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Upload</Typography></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/calendar") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Kalender</Typography></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/assignments") }}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Opgaver</Typography></Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <IconButton
                                                    size="small"
                                                    style={{ color: "white" }}
                                                    onClick={() => { setSearchValue(""); setSearchResults(null); this.props.history.push("/settings") }}
                                                ><Settings /></IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={classes.appBarButton}
                                                    size="medium"
                                                    onClick={this.handleLogout}
                                                ><Typography color="textSecondary" className={classes.buttonText}>Log ud</Typography></Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                    {companies &&
                        <Drawer open={isSelectCompanyDrawerOpen} onClose={this.handleDrawerClose}>
                            <List>
                                {companies.sort((a, b) => (b.name < a.name ? 1 : (a.name < b.name ? -1 : 0))).map((company, idx) => {
                                    return (
                                        <ListItem button key={`${company.name}_${idx}`} style={{ "paddingRight": 24 }} onClick={() => this.handleCompanyChange(company)}>
                                            <ListItemAvatar style={{ marginRight: 8 }}>
                                                <Avatar classes={{ root: classes.avatarRoot }} src={company.logoSrc} />
                                            </ListItemAvatar>
                                            <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary={company.name} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Drawer>
                    }
                </AppBar>
                <InformationSnackbar handleBarClose={this.handleWarningBarClose} isBarOpen={isWarningBarOpen} message={warningBarMessage} />
            </Fragment>
        )
    }
}

const styles = theme => ({
    root: {
        width: "100%"
    },
    headerTop: {
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: theme.palette.primary.main
    },
    headerBottom: {
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: theme.palette.secondary.main
    },
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    appBar: {
        height: 102,
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarButton: {
        fontSize: 16,
        '&:hover': {
            background: "rgba(44, 130, 201, 0.2)"
        },
    },
    logoContainer: {
        width: 64,
        height: 64,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "48px",
        height: "48px"
    },
    divider: {
        color: theme.palette.primary.light
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.45),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.55),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "black"
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    buttonText: {
        fontSize: 18,
        fontWeight: 500
    },
    avatarRoot: {
        width: 64,
        height: 64
    },
    listItemTextPrimary: {
        fontSize: "1.3rem"
    }
})

const mapStateToProps = ({ state }) => {
    return {
        mainFolders: state.mainFolders,
        companies: state.companies,
        currentMainFolder: state.currentMainFolder,
        currentFolder: state.currentFolder,
        currentSubFolder: state.currentSubFolder,
        searchValue: state.searchValue,
        loggedInUser: state.loggedInUser,
        isLoading: state.isLoading,
        currentCompany: state.currentCompany
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setLoggedInUser, setSearchResults, setSharing, setSearchValue, setIsLoading, setMainFolders, setUploadedFiles, setCurrentCompany, setCurrentSubFolder, setCurrentMainFolder, setCurrentFolder, setCurrentFile, setCurrentFileIdx }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Header))