import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, IconButton, ListItemText, ListItemIcon, Button, ListItem, Menu, Divider, MenuItem, Typography, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@material-ui/core'
import { ChevronLeft, Share, MoreVert, PersonAdd, Note } from "@material-ui/icons"
import MenuIcon from "@material-ui/icons/Menu"
import Header from "../components/Header"
import Footer from "../components/Footer"
import InformationSnackbar from "../components/InformationSnackbar"
import { entityTypes } from "../enums"
import { sharedWith, drawerWidth, optionsFolders, createListItemIcon } from "../constants"
import FolderExpansionPanel from "../components/FolderExpansionPanel"
import FileActionBar from "../components/FileActionBar"
import MoreOptionsMenu from "../components/MoreOptionsMenu"
import { setCurrentFile, setCurrentFileIdx, setSearchValue, setSharing, setCurrentDomain } from "../actions/actions"
import api, { escapeForwardSlash } from "../api"

class Sharing extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isDrawerVisible: true,
            moreOptionsFolderTarget: null,
            moreOptionsMainFolderTarget: null,
            moreOptionsSubFolderTarget: null,
            isDocumentValuesContainerModalOpen: false,
            addNewShareDialog: false,
            newActivityDialog: false,
            isInformationSnackbarOpen: false,
            newShareName: null,
            newShareNote: null,
            newShareEmail: null,
            newShareRole: null,
            newShareDateFrom: null,
            newShareDateTo: null,
            newActivity: null,
            newActivityTime: null,
            newActivityDate: null,
            isModifying: false,
            oldName: null,
            oldEmail: null,
            oldDomain: null,
            modifyingIdx: null,
            selectedDomainIdx: null,
            selectedFile: null,
            selectedFileIdx: null
        }
    }

    componentDidMount = () => {
        const { sharing, setCurrentDomain } = this.props

        if (Object.keys(sharing).length > 0) {
            setCurrentDomain(Object.entries(sharing).sort((a, b) => a[0] > b[0] ? 1 : -1)[0][1])
            this.setState({ selectedDomainIdx: 0, currentDomainName: Object.entries(sharing).sort((a, b) => a[0] > b[0] ? 1 : -1)[0][0] })
        }
    }

    componentWillUnmount() {
        const { setCurrentFile, setCurrentDomain, setCurrentFileIdx, setSearchValue } = this.props
        setCurrentFile(null)
        setCurrentFileIdx(null)
        setCurrentDomain(null)
    }

    addNewShare = () => {
        const { currentCompany, sharing, setSharing } = this.props
        const { modifyingIdx, oldDomain, oldName, oldEmail, newShareNote, newShareName, isModifying, newShareEmail, newShareRole, newShareDateFrom, newShareDateTo } = this.state

        if (!newShareName || (!newShareEmail || !newShareEmail.includes("@"))) {
            this.setState({ isInformationSnackbarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Navn og E-mail skal være udfyldt" })
            return
        }

        const domain = newShareEmail.split("@")[1].toLowerCase()

        const newShare = {
            name: newShareName,
            email: newShareEmail,
            domain,
            role: newShareRole || null,
            dateFrom: newShareDateFrom || null,
            dateTo: newShareDateTo || null,
            note: newShareNote || null,
            company: currentCompany.name,
            activities: []
        }

        if (!isModifying) {
            if (!sharing[domain] || sharing[domain].filter(person => (person.name == newShareName || person.email == newShareEmail)).length == 0) {
                const newSharing = { ...sharing }
                if (!newSharing[domain]) {
                    newSharing[domain] = []
                }
                newSharing[domain].push(newShare)

                api.post("/sharing", newShare)
                    .then((res) => {
                        if (res.data.code != 1001) {
                            setSharing(newSharing)
                            this.handleDialogClose("addNewShareDialog")
                        } else {
                            this.setState({ isInformationSnackbarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Personen er allerede oprettet" })
                        }
                    })
            } else {
                this.setState({ isInformationSnackbarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Personen er allerede oprettet" })
            }
        } else {
            if (!sharing[domain] || sharing[domain].filter(person => (person.name == newShareName || person.email == newShareEmail)).length == 0) {
                api.put(`/sharing/${escapeForwardSlash(oldName)}/${escapeForwardSlash(oldEmail)}`, newShare)
                    .then((res) => {
                        const newSharing = { ...sharing }

                        if (oldDomain === domain) {
                            newSharing[oldDomain].splice(modifyingIdx, 1, newShare)
                        } else {
                            newSharing[oldDomain].splice(modifyingIdx, 1)
                            if (!newSharing[domain]) {
                                newSharing[domain] = []
                                newSharing[domain].push(newShare)
                            } else {
                                newSharing[domain].push(newShare)
                            }
                        }

                        console.log(newSharing)
                        if (res.data.code != 1001) {
                            setSharing(newSharing)
                            this.handleDialogClose("addNewShareDialog")
                        } else {
                            this.setState({ isInformationSnackbarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Personen er allerede oprettet" })
                        }
                    })
            } else {
                this.setState({ isInformationSnackbarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Navn og/eller e-mail allerede i brug" })
            }
        }
    }

    handleInformationSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isInformationSnackbarOpen: false })
    }

    handleDialogOpen = (dialog) => {
        this.setState({ [dialog]: true })
    }

    handleDialogClose = (dialog) => {
        this.setState({ [dialog]: false, isModifying: false, oldName: null, oldEmail: null, newShareNote: null, newShareName: null, newShareEmail: null, newShareRole: null, newShareDateTo: null, newShareDateFrom: null, newActivity: null, newActivityDate: null, newActivityTime: null, moreOptionsSubFolderTarget: null })
    }

    handleDrawerOpen = () => {
        this.setState({ isDrawerVisible: true })
    }

    handleDrawerClose = () => {
        this.setState({ isDrawerVisible: false })
    }

    handleDomainClicked = (domain) => {
        const { setCurrentDomain, sharing } = this.props

        setCurrentDomain(sharing[domain])

        this.setState({ currentDomainName: domain })
    }

    handleTextFieldChange = (event) => {
        const field = event.target.name
        const newValue = event.target.value

        this.setState({ [field]: newValue })
    }

    handleMoreOptionsClicked = (event, file) => {
        this.setState({ moreOptionsSubFolderTarget: event.currentTarget })
    }

    handleMoreOptionsClose = () => {
        this.setState({ moreOptionsSubFolderTarget: null })
    }

    handleDocumentValuesContainerModalClose = () => {
        const { setCurrentFile } = this.props
        this.setState({ isDocumentValuesContainerModalOpen: false, moreOptionsFolderTarget: null }, () => setCurrentFile(null))
    }

    handleDeleteSharing = (file, idx) => {
        const { sharing, setSharing } = this.props

        const newSharing = { ...sharing }
        newSharing[file.domain].splice(idx, 1)

        setSharing(newSharing)
        api.delete(`/sharing/${escapeForwardSlash(file.name)}/${escapeForwardSlash(file.email)}/${escapeForwardSlash(file.company)}`)
    }

    handleNewActivityPressed = () => {
        this.handleDialogOpen("newActivityDialog")
    }

    handleEditSharedPerson = (person, personIdx) => {
        this.setState({
            oldName: person.name,
            oldEmail: person.email,
            oldDomain: person.domain,
            newShareName: person.name,
            newShareNote: person.note,
            newShareEmail: person.email,
            newShareRole: person.role,
            newShareDateFrom: person.dateFrom,
            newShareDateTo: person.dateTo,
            isModifying: true,
            modifyingIdx: personIdx,
        }, this.handleDialogOpen("addNewShareDialog"))
    }

    addNewActivity = () => {
        const { sharing, setSharing } = this.props
        const { newActivity, newActivityDate, newActivityTime, selectedFile, selectedFileIdx, currentDomain, currentDomainName } = this.state

        const newSelectedFile = { ...selectedFile }
        const newSelectedFileStringified = { ...selectedFile }
        const newSharing = { ...sharing }

        const newActivityObj = {
            activity: newActivity,
            date: newActivityDate,
            time: newActivityTime
        }

        newSelectedFile.activities.push(newActivityObj)
        newSharing[currentDomainName].splice(selectedFileIdx, 1, newSelectedFile)
        setSharing(newSharing)

        newSelectedFileStringified.activities = newSelectedFileStringified.activities.map(activity => JSON.stringify(activity))
        newSelectedFileStringified.activities.push(JSON.stringify(newActivityObj))

        api.put(`/sharing/${selectedFile.name}/${selectedFile.email}`, newSelectedFileStringified)
            .then(() => {
                this.setState({ newActivityDialog: false, moreOptionsSubFolderTarget: null, newActivity: null, newActivityDate: null, newActivityTime: null })
            })
    }

    setSharingState = (state) => {
        this.setState(state, () => this.handleDialogOpen("addNewShareDialog"))
    }

    setSelectedFileIdx = (file, fileIdx) => {
        this.setState({ selectedFile: file, selectedFileIdx: fileIdx })
    }

    createDomains = (classes) => {
        const { sharing } = this.props
        const { moreOptionsMainFolderTarget, moreOptionsFolderTarget, shared, selectedDomainIdx } = this.state

        return Object.entries(sharing).filter(entry => entry[1].length).sort((a, b) => a > b ? 1 : -1).map((entry, entryIdx) => {
            return (
                <Grid item key={`${entry[0]}_${entryIdx}`}>
                    <ListItem className={classes.drawerItem} button selected={entryIdx == selectedDomainIdx} onClick={(e) => { e.stopPropagation(); this.handleDomainClicked(entry[0]); this.setState({ selectedDomainIdx: entryIdx }) }}>
                        <ListItemText primary={entry[0]} />
                    </ListItem>
                </Grid>
            )
        })
    }

    render() {
        const { classes, searchResults, currentDomain, sharing } = this.props
        const { newShareDateFrom, newShareNote, selectedFile, moreOptionsSubFolderTarget, newActivity, newActivityDate, newActivityTime, newActivityDialog, newShareDateTo, newShareRole, newShareName, newShareEmail, isDrawerVisible, moreOptionsTarget, currentDomainName, isInformationSnackbarOpen, informationSnackbarMessage, informationSnackbarTopMargin, addNewShareDialog, moreOptionsFolderTarget, isDocumentValuesContainerModalOpen, selectedFileType } = this.state

        const textFieldInputLabelProps = {
            classes: {
                root: classes.textField
            },
            shrink: true
        }

        return (
            <div>
                <div className={classes.root}>
                    <div className={classes.rootGrid}>
                        <Grid container direction="column" className={`${classes.drawerContainer} ${!isDrawerVisible ? classes.drawerContainerShifted : ""}`}>
                            <Grid item className={classes.drawerHeader}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeft />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ height: "calc(100% - 64px)", overflow: "scroll" }}>
                                <Grid container direction="column" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                    {this.createDomains(classes)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={`${classes.contentContainer} ${!isDrawerVisible ? classes.contentContainerShifted : ""}`}>
                            <Grid item style={{ width: "100%" }}>
                                <Grid container className={classes.contentHeader} direction="row">
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item>
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="Open drawer"
                                                    onClick={this.handleDrawerOpen}
                                                    edge="start"
                                                    className={`${isDrawerVisible ? classes.hide : classes.menuIcon}`}
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Typography
                                                    variant="h6"
                                                    noWrap
                                                    className={`${classes.menuTitle} ${!isDrawerVisible ? classes.menuTitleShifted : ""}`}
                                                >
                                                    {currentDomain ? `${currentDomainName} (${currentDomain.length})` : "Ingen mappe valgt"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item style={{ marginRight: 4 }}>
                                                <IconButton onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("addNewShareDialog") }}>
                                                    <PersonAdd />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.content}>
                                <Grid container className={classes.root} direction="column" spacing={2} style={{ height: "100%", margin: 0, padding: 16 }}>
                                    {currentDomain && currentDomain.length > 0
                                        ?
                                        <Grid item style={{ height: "100%", padding: 0 }}>
                                            <Grid container direction="column" justify="space-between" style={{ height: "100%" }}>
                                                <Grid item>
                                                    <Grid container direction="column" alignItems="center" justify="center">
                                                        {currentDomain.sort((a, b) => a.name > b.name ? 1 : -1).map((file, fileIdx) => {
                                                            return (
                                                                <ExpansionPanel key={`${file.name}_${fileIdx}`} style={{ width: "100%" }}>
                                                                    <ExpansionPanelSummary
                                                                        classes={{ root: classes.subFolderBarRoot }}
                                                                        className={classes.subFolderBar}
                                                                        aria-controls={`panel${fileIdx + 1}-content`}
                                                                        id={`panel${fileIdx + 1}-header`}
                                                                        onClick={e => e.stopPropagation()}
                                                                    >
                                                                        <Grid container direction="row" justify="space-between">
                                                                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                                                                <Typography className={classes.heading} color="textSecondary">{file.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end", marginRight: 12 }}>
                                                                                <IconButton style={{ color: "white" }} onClick={(e) => { e.stopPropagation(); this.handleMoreOptionsClicked(e, file); this.setSelectedFileIdx(file, fileIdx) }}>
                                                                                    <MoreVert />
                                                                                </IconButton>
                                                                                <Menu id={`menu_${fileIdx}`} anchorEl={moreOptionsSubFolderTarget} open={Boolean(moreOptionsSubFolderTarget) && selectedFile.name == file.name} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose("moreOptionsSubFolderTarget") }}>
                                                                                    <MenuItem key="Ny deling hændelse" onClick={(e) => { e.stopPropagation(); this.handleNewActivityPressed() }}>
                                                                                        Ny deling hændelse
                                                                                    </MenuItem>
                                                                                    <MenuItem key="Redigér" onClick={(e) => { e.stopPropagation(); this.handleEditSharedPerson(file, fileIdx) }}>
                                                                                        Redigér
                                                                                    </MenuItem>
                                                                                    <MenuItem key="Slet" onClick={(e) => { e.stopPropagation(); this.handleDeleteSharing(file, fileIdx) }}>
                                                                                        Slet
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ExpansionPanelSummary>
                                                                    <ExpansionPanelDetails classes={{ root: classes.rootExpansionPanelDetails }}>
                                                                        <Grid container direction="column" alignItems="center" justify="center">
                                                                            {file.activities.map((activity, activityIdx) => {
                                                                                return (
                                                                                    <Fragment key={`activity-${activityIdx}`}>
                                                                                        <Grid item xs className={classes.fileActionBarRow}>
                                                                                            <Grid container direction="column" justify="center" alignItems="flex-start" style={{ padding: 12, paddingLeft: 24 }}>
                                                                                                <Grid item>
                                                                                                    <Typography variant="h6">
                                                                                                        {activity.activity}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                {(activity.date || activity.time) &&
                                                                                                    <Grid item >
                                                                                                        <Typography variant="subtitle1">
                                                                                                            {`${activity.date != null ? activity.date : ""} ${(activity.date != null && activity.time != null ? "|" : "")} ${activity.time != null ? activity.time : ""}`}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        {activityIdx !== file.activities.length &&
                                                                                            <Grid item className={classes.fileActionBarRowDivider}>
                                                                                                <Divider />
                                                                                            </Grid>
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    </ExpansionPanelDetails>
                                                                </ExpansionPanel>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item style={{ height: "100%" }}>
                                            <Grid container direction="column" justify="space-between" style={{ height: "100%" }}>
                                                <Typography>Mappen er tom</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {addNewShareDialog &&
                    <Dialog open={addNewShareDialog} onClose={() => this.handleDialogClose("addNewShareDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Deling</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Udfyld nedenstående oplysninger om personen der deles med
                            </DialogContentText>
                            <Grid container direction="column" spacing={2}>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        autoFocus={true}
                                        variant="outlined"
                                        margin="dense"
                                        name="newShareName"
                                        label="Navn"
                                        value={newShareName || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        variant="outlined"
                                        margin="dense"
                                        name="newShareEmail"
                                        label="E-mail"
                                        type="email"
                                        value={newShareEmail || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        variant="outlined"
                                        margin="dense"
                                        name="newShareRole"
                                        label="Rolle"
                                        value={newShareRole || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                InputLabelProps={textFieldInputLabelProps}
                                                variant="outlined"
                                                margin="dense"
                                                name="newShareDateFrom"
                                                label="Deling fra"
                                                type="date"
                                                value={newShareDateFrom || ""}
                                                fullWidth
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={this.handleTextFieldChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                InputLabelProps={textFieldInputLabelProps}
                                                variant="outlined"
                                                margin="dense"
                                                name="newShareDateTo"
                                                label="Deling til"
                                                value={newShareDateTo || ""}
                                                type="date"
                                                fullWidth
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={this.handleTextFieldChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Note"
                                        name="newShareNote"
                                        label="Note"
                                        value={newShareNote || ""}
                                        multiline
                                        rows={2}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogClose("addNewShareDialog")} color="primary">
                                Annullér
                            </Button>
                            <Button onClick={this.addNewShare} color="primary">
                                Gem
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {newActivityDialog &&
                    <Dialog open={newActivityDialog} onClose={() => this.handleDialogClose("newActivityDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Ny deling hændelse</DialogTitle>
                        <DialogContent>
                            <Grid container direction="column" spacing={2} style={{ width: 380 }}>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        autoFocus={true}
                                        variant="outlined"
                                        margin="dense"
                                        name="newActivity"
                                        label="Hændelse"
                                        value={newActivity || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        variant="outlined"
                                        margin="dense"
                                        name="newActivityDate"
                                        label="Dato"
                                        type="date"
                                        value={newActivityDate || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <TextField
                                        InputLabelProps={textFieldInputLabelProps}
                                        variant="outlined"
                                        margin="dense"
                                        name="newActivityTime"
                                        label="Klokkeslæt"
                                        type="time"
                                        value={newActivityTime || ""}
                                        fullWidth
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={this.handleTextFieldChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogClose("newActivityDialog")} color="primary">
                                Annullér
                            </Button>
                            <Button onClick={this.addNewActivity} color="primary">
                                Gem
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <InformationSnackbar handleBarClose={this.handleInformationSnackbarClose} isBarOpen={isInformationSnackbarOpen} message={informationSnackbarMessage} topMargin={informationSnackbarTopMargin} />
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        width: "100%",
        height: "calc(100vh - 150px)",
        padding: theme.spacing(3),
        position: "relative",
    },
    rootGrid: {
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        height: "100%"
    },
    rootExpansionPanelDetails: {
        padding: 0,
        flexDirection: "column"
    },
    drawerContainer: {
        width: drawerWidth,
        height: "100%",
        background: theme.palette.background.paper,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    drawerContainerShifted: {
    },
    subFolderBar: {
        background: "dimgrey"
    },
    subFolderBarRoot: {
        padding: "0 0 0 24px"
    },
    heading: {
        fontSize: 20,
        fontWeight: 400,
        hyphens: "auto",
        wordBreak: "break-word"
    },
    drawerHeader: {
        height: 64,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    drawerItem: {
        height: 64,
        width: drawerWidth,
    },
    fileActionBarRow: {
        width: "100%",
        padding: 24
    },
    contentContainer: {
        position: "absolute",
        top: theme.spacing(3),
        left: theme.spacing(3) + drawerWidth,
        width: `calc(100% - ${drawerWidth + theme.spacing(6)}px)`,
        height: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentContainerShifted: {
        left: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    fileActionBarRowDivider: {
        width: "100%",
        paddingleft: 24,
        paddingRight: 24
    },
    contentHeader: {
        height: 64,
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.paper
    },
    content: {
        background: "whitesmoke",
        width: "100%",
        height: `calc(100% - 64px)`,
        overflow: "auto"
    },
    hide: {
        display: 'none'
    },
    menuIcon: {
        marginLeft: 0
    },
    menuTitle: {
        margin: 0,
        marginLeft: theme.spacing(3)
    },
    menuTitleShifted: {
        marginLeft: 0
    },
    mainFolderBar: {
        background: "dimgrey"
    },
    rootExpansionPanelDetails: {
        padding: 0,
    },
    rootMainFolder: {
        padding: "0 0 0 16px"
    },
    expandedMainFolder: {
        margin: "0px !important"
    },
    focusedMainFolder: {
        backgroundColor: "dimgrey !important"
    },
    textField: {
        color: theme.palette.primary.main
    },
    leftIcon: {
        marginRight: 16,
        marginTop: 0
    },
    fileActionBar: {
        height: 93,
        width: "100%",
        background: "white",
        '&:hover': {
            background: "rgba(44, 130, 201, 0.05)"
        },
        '&:not(:last-child)': {
            paddingBottom: 0
        },
        transition: "background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        padding: "8px 20px 17px",
    },
    fileActionBarRow: {
        width: "100%"
    },
    focusedMainFolder: {
        backgroundColor: "dimgrey !important"
    },
    fileActionBarFileName: {
        fontSize: 18,
        fontWeight: 500,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileActionBarText: {
        fontSize: 16
    },
    fileActionBarLowerText: {
        fontSize: 13
    }
})

const mapStateToProps = ({ state }) => {
    return {
        sharing: state.sharing,
        currentDomain: state.currentDomain,
        currentCompany: state.currentCompany,
        currentFile: state.currentFile,
        searchResults: state.searchResults
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setSearchValue, setCurrentFileIdx, setCurrentDomain, setCurrentFile, setSharing }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Sharing))