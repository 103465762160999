import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Icon, Grid, IconButton, Fab, ListItemText, Chip, Radio, Input, RadioGroup, InputLabel, FormControl, Select, FormControlLabel, CircularProgress, Modal, ListItemIcon, ListItem, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ChevronLeft, Share, MoreVert, Add, UnfoldLess, UnfoldMore } from "@material-ui/icons"
import MenuIcon from "@material-ui/icons/Menu"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { entityTypes } from "../enums"
import { sharedWith, drawerWidth, createListItemIcon } from "../constants"
import FolderExpansionPanel from "../components/FolderExpansionPanel"
import { fileTypes, folderIcons, urgencyLevels } from "../enums"
import InformationSnackbar from "../components/InformationSnackbar"
import moment from "moment"
import api, { escapeForwardSlash } from "../api"
import { setMainFolders, setSearchValue, setIsLoading, setCurrentMainFolder, setCurrentFileIdx, setCurrentFile, setCurrentFolder, setCurrentSubFolder } from "../actions/actions"

class FolderList extends React.PureComponent {
    constructor(props) {
        super(props)

        const { mainFolders, classes } = this.props

        this.mainFolderRefs = []

        this.state = {
            sharingDialog: false,
            selectedDomain: null,
            selectedPerson: null,
            wasCodebankPasswordCorrect: false,
            didPasswordAttemptFail: false,
            isWarningBarOpen: false,
            isDrawerVisible: true,
            moreOptionsFolderTarget: null,
            moreOptionsMainFolderTarget: null,
            moreOptionsSubFolderTarget: null,
            addNewMainFolderDialog: false,
            addNewFolderDialog: false,
            addNewSubFolderDialog: false,
            addNewNoteDialog: false,
            addNewPasswordDialog: false,
            deleteMainFolderDialog: false,
            deleteFolderDialog: false,
            deleteSubFolderDialog: false,
            deleteFileDialog: false,
            codebankPasswordDialog: false,
            renameMainFolderDialog: false,
            renameFolderDialog: false,
            renameSubFolderDialog: false,
            informationSnackbarMessage: "",
            informationSnackbarTopMargin: 86,
            isDocumentValuesContainerModalOpen: false,
            newFolderType: "regnskab",
            isExpansionPanelExpandedMap: {},
            isExpandAll: false
        }
    }

    componentDidUpdate(prevProps) {
        const { mainFolders, currentCompany, setCurrentSubFolder, setCurrentFolder, setCurrentMainFolder, currentFolder } = this.props

        if (Object.keys(prevProps.mainFolders).length == 0 && Object.keys(mainFolders).length != 0) {
            this.selectFirstFolder()
        }

        // if (currentFolder == null) {
        //     this.selectFirstFolder()
        // }
    }

    componentDidMount = () => {
        const { mainFolders, setCurrentMainFolder, setCurrentFolder, currentCompany } = this.props
        this.selectFirstFolder()

        const isExpansionPanelExpandedMap = {}
        Object.values(mainFolders).filter(mf => mf.company == currentCompany.name).sort(((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1)).forEach((mainFolder, mainFolderIdx) => {
            isExpansionPanelExpandedMap[mainFolder.name] = true
        })

        this.setState({ isExpansionPanelExpandedMap })
    }

    componentWillUnmount() {
        const { setCurrentFile, setCurrentFileIdx, setSearchValue, setCurrentFolder, setCurrentMainFolder, setCurrentSubFolder } = this.props
        setCurrentFile(null)
        setCurrentFileIdx(null)
        setCurrentFolder(null)
        setCurrentMainFolder(null)
        setCurrentSubFolder(null)
    }

    selectFirstFolder = () => {
        const { mainFolders, setCurrentFolder, setCurrentMainFolder } = this.props

        if (Object.keys(mainFolders).length > 0) {
            const firstMainFolder = Object.values(mainFolders).sort(((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1))[0]
            if (Object.keys(mainFolders[firstMainFolder.name].folders).length > 0) {
                const firstFolder = Object.values(mainFolders[firstMainFolder.name].folders).sort(((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))))[0]
                setCurrentFolder(firstFolder)
            }

            setCurrentMainFolder(firstMainFolder)
        }
    }

    handleDocumentValuesContainerModalClose = (e) => {
        const { setCurrentFile } = this.props

        e && e.stopPropagation()

        this.setState({ isDocumentValuesContainerModalOpen: false, moreOptionsFolderTarget: null, moreOptionsSubFolderTarget: null }, () => setCurrentFile(null))
    }

    handleDocumentValuesContainerModalOpen = (file, subFolder) => {
        const { setCurrentFile, mainFolders, currentMainFolder, currentFolder, setCurrentSubFolder } = this.props
        setCurrentFile(file)
        subFolder && setCurrentSubFolder(mainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[subFolder.name])
        this.setState({ isDocumentValuesContainerModalOpen: true })
    }

    handleAddNewNoteClicked = (fileType) => {
        this.setState({ selectedFileType: fileType }, () => this.handleDocumentValuesContainerModalOpen())
    }

    handleUploadDocumentClicked = (fileType) => {
        this.setState({ selectedFileType: fileType }, () => this.handleDocumentValuesContainerModalOpen())
    }

    handleDrawerOpen = () => {
        this.setState({ isDrawerVisible: true })
    }

    handleDrawerClose = () => {
        this.setState({ isDrawerVisible: false })
    }

    handleFolderClicked = (mainFolderName, folderName) => {
        const { mainFolders, setCurrentMainFolder, setCurrentFolder } = this.props

        setCurrentMainFolder(mainFolders[mainFolderName])
        setCurrentFolder(mainFolders[mainFolderName].folders[folderName])

        if (mainFolders[mainFolderName].folders[folderName].folderContentType === "kodebank") {
            this.handleDialogOpen("codebankPasswordDialog")
        }
    }

    handleMoreOptionsClicked = (event, target, mainFolderName, folderName, subFolderName) => {
        const { mainFolders, currentFolder, setCurrentMainFolder, setCurrentFolder, setCurrentSubFolder } = this.props

        setCurrentMainFolder(mainFolders[mainFolderName])

        if (typeof folderName != "undefined" && folderName != null) {
            setCurrentFolder(mainFolders[mainFolderName].folders[folderName])
        }
        if (typeof subFolderName != "undefined" && subFolderName != null) {
            setCurrentSubFolder(mainFolders[mainFolderName].folders[folderName].subFolders[subFolderName])
        } else {
            setCurrentSubFolder(null)
        }

        this.setState({ [target]: event.currentTarget })
    }

    handleSharingDelete = (personIdx) => {
        const { currentFile, currentFileIdx, currentCompany, currentFolder, setCurrentFile, setCurrentFolder } = this.props
        
        if (currentFile) {
            const newCurrentFile = { ...currentFile }
            const newCurrentFolder = { ...currentFolder }
            newCurrentFile.sharedWith.splice(personIdx, 1)
            newCurrentFolder.files.splice(currentFileIdx, 1, newCurrentFile)
            setCurrentFile(newCurrentFile)
            setCurrentFolder(newCurrentFolder)
            api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(currentFile.name)}/${escapeForwardSlash(currentFile.mainFolder)}/${escapeForwardSlash(currentFile.folder)}`, newCurrentFile)
        } else {
            const newCurrentFolder = { ...currentFolder } 
            newCurrentFolder.sharedWith.splice(personIdx, 1)
            setCurrentFolder(newCurrentFolder)
            api.put(`/${escapeForwardSlash(currentCompany.name)}/folder/${escapeForwardSlash(currentFolder.name)}/${escapeForwardSlash(currentFolder.mainFolder)}`, newCurrentFolder)
        }
    }

    handleWarningBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isWarningBarOpen: false })
    }

    handleMoreOptionsClose = (target) => {
        this.setState({ [target]: null })
    }

    handleDialogOpen = (dialog, fileIdx) => {
        this.setState({ [dialog]: true, fileIdxToDelete: fileIdx })
    }

    handleDialogClose = (dialog) => {
        this.setState({ [dialog]: false, moreOptionsFolderTarget: null, moreOptionsMainFolderTarget: null, moreOptionsSubFolderTarget: null })
    }

    handleAddNewMainFolder = () => {
        const { newMainFolderName } = this.state
        const { mainFolders, setMainFolders, currentMainFolder, currentCompany } = this.props

        if (newMainFolderName == null || newMainFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Hovedmappen mangler et navn" })
            return
        }

        const newMainFolder = {
            name: newMainFolderName.charAt(0).toUpperCase() + newMainFolderName.substring(1),
            sortIndex: currentMainFolder ? currentMainFolder.sortIndex + 1 : 0,
            folders: {},
            type: "main",
            company: currentCompany.name
        }

        const newMainFolders = { ...mainFolders }
        newMainFolders[newMainFolder.name] = newMainFolder

        for (const mainFolder of Object.values(mainFolders)) {
            if (mainFolder.sortIndex >= newMainFolder.sortIndex && mainFolder.name != newMainFolder.name) {
                const newSortIndex = mainFolder.sortIndex + 1
                newMainFolders[mainFolder.name].sortIndex = newSortIndex
                api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(mainFolder.name)}`, newMainFolders[mainFolder.name])
            }
        }

        api.post(`/${escapeForwardSlash(currentCompany.name)}/mainFolder`, newMainFolder)
        setMainFolders(newMainFolders)
        this.setState({ addNewMainFolderDialog: false, moreOptionsMainFolderTarget: null })
    }

    handleAddNewFolder = () => {
        const { newFolderName, newFolderType } = this.state
        const { mainFolders, setMainFolders, currentMainFolder, currentCompany } = this.props

        if (newFolderName == null || newFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Mappen mangler et navn" })
            return
        }

        const newFolder = {
            name: newFolderName.charAt(0).toUpperCase() + newFolderName.substring(1),
            files: [],
            mainFolder: currentMainFolder.name,
            icon: newFolderType === "kodebank" ? folderIcons.KEY : folderIcons.FOLDER,
            urgency: urgencyLevels.LOW,
            subFolders: {},
            folderContentType: newFolderType,
            type: "main",
            sharedWith: [],
            company: currentCompany.name
        }

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name].folders[newFolder.name] = newFolder

        api.post(`/${escapeForwardSlash(currentCompany.name)}/folder`, newFolder)
        setMainFolders(newMainFolders)
        this.setState({ addNewFolderDialog: false, moreOptionsMainFolderTarget: null })
    }

    handleAddNewSubFolder = () => {
        const { newSubFolderName } = this.state
        const { mainFolders, setMainFolders, currentMainFolder, currentFolder, currentCompany } = this.props

        if (newSubFolderName == null || newSubFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Undermappen mangler et navn" })
            return
        }

        const newSubFolder = {
            name: newSubFolderName.charAt(0).toUpperCase() + newSubFolderName.substring(1),
            files: [],
            mainFolder: currentMainFolder.name,
            folder: currentFolder.name,
            type: "main",
            company: currentCompany.name
        }

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[newSubFolder.name] = newSubFolder

        api.post(`/${escapeForwardSlash(currentCompany.name)}/subFolder`, newSubFolder)
        setMainFolders(newMainFolders)
        this.setState({ addNewSubFolderDialog: false, moreOptionsFolderTarget: null })
    }

    setWarningBarAndLabel = (message) => {
        this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 74, informationSnackbarMessage: message })
    }

    handleAddNewNote = (subFolder) => {
        const { newNoteName, newNoteText } = this.state
        const { loggedInUser, mainFolders, setMainFolders, currentCompany, currentMainFolder, currentFolder } = this.props

        if (newNoteName == null || newNoteName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Noten mangler et navn" })
            return
        }

        const newNote = {
            name: newNoteName,
            type: fileTypes.NOTE,
            uploaderName: loggedInUser.firstname + " " + loggedInUser.lastname,
            nextReminder: null,
            dateDue: null,
            periodFrom: null,
            periodTo: null,
            isEntity: false,
            amount: "",
            mainFolder: currentMainFolder.name,
            folder: currentFolder.name,
            company: currentCompany.name,
            subFolder: subFolder ? subFolder : null,
            GDPR: [],
            tags: [],
            note: newNoteText || "",
            isArchived: true,
            sharedWith: [],
            uploadDate: moment().format("YYYY-MM-DD"),
            src: null,
            isRemovedExceededDeadlines: false,
            isRemovedUpcomingDeadlines: false,
            urgency: urgencyLevels.DEFAULT
        }

        api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newNote)
            .then(res => {
                if (res.data && res.data.code != 1001) {
                    const newMainFolders = { ...mainFolders }
                    if (subFolder) {
                        newMainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[subFolder].files.push(newNote)
                    } else {
                        newMainFolders[currentMainFolder.name].folders[currentFolder.name].files.push(newNote)
                    }
                    setMainFolders(newMainFolders)
                    this.setState({ addNewNoteDialog: false, moreOptionsFolderTarget: null, moreOptionsSubFolderTarget: null })
                } else {
                    this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 74, informationSnackbarMessage: "En fil med samme navn findes allerede" })
                }
            })
    }

    handleAddNewPassword = () => {
        const { newPasswordName, newPasswordPassword, newPasswordIP } = this.state
        const { mainFolders, loggedInUser, setMainFolders, currentCompany, currentMainFolder, currentFolder } = this.props

        if ((newPasswordName == null || newPasswordName == "") || (newPasswordPassword == null || newPasswordPassword == "")) {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Kodeordet mangler en titel og/eller selve kodeordet" })
            return
        }

        const newPassword = {
            name: newPasswordName,
            type: fileTypes.PASSWORD,
            isEntity: false,
            password: newPasswordPassword,
            IP: newPasswordIP,
            mainFolder: currentMainFolder.name,
            folder: currentFolder.name,
            company: currentCompany.name,
            subFolder: null,
            uploaderName: loggedInUser.firstname + " " + loggedInUser.lastname,
            uploadDate: moment().format("YYYY-MM-DD"),
            tags: [],
            sharedWith: []
        }

        api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newPassword)
            .then(res => {
                if (res.data && res.data.code != 1001) {
                    const newMainFolders = { ...mainFolders }
                    newMainFolders[currentMainFolder.name].folders[currentFolder.name].files.push(newPassword)
                    setMainFolders(newMainFolders)
                    this.setState({ addNewPasswordDialog: false, moreOptionsFolderTarget: null })
                } else {
                    this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 74, informationSnackbarMessage: "Et kodeord med samme navn findes allerede" })
                }
            })
    }

    handleDeleteMainFolder = () => {
        const { mainFolders, setMainFolders, currentCompany, currentFolder, currentMainFolder, setCurrentFolder, setCurrentMainFolder } = this.props

        const newMainFolders = { ...mainFolders }

        if (Object.keys(newMainFolders).length > 1) {
            for (const mainFolder of Object.values(mainFolders)) {
                if (mainFolder.sortIndex > currentMainFolder.sortIndex) {
                    const newSortIndex = newMainFolders[mainFolder.name].sortIndex - 1
                    newMainFolders[mainFolder.name].sortIndex = newSortIndex
                    api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(mainFolder.name)}`, newMainFolders[mainFolder.name])
                }
            }

            api.delete(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(currentMainFolder.name)}`)
            delete newMainFolders[currentMainFolder.name]
            setMainFolders(newMainFolders)

            if (currentFolder && currentFolder.name in currentMainFolder.folders) {
                if (Object.keys(newMainFolders).length > 0) {
                    const firstMainFolder = Object.values(newMainFolders).sort(((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1))[0]
                    if (Object.keys(mainFolders[firstMainFolder.name].folders).length > 0) {
                        const firstFolder = Object.values(newMainFolders[firstMainFolder.name].folders).sort(((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))))[0]
                        setCurrentFolder(firstFolder)
                    } else {
                        setCurrentFolder(null)
                    }

                    setCurrentMainFolder(firstMainFolder)
                }

                this.setState({
                    deleteMainFolderDialog: false,
                    moreOptionsMainFolderTarget: null
                })
            } else {
                this.setState({
                    deleteMainFolderDialog: false,
                    moreOptionsMainFolderTarget: null
                })
            }
        } else {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Du kan ikke slette den sidste hovedmappe", deleteMainFolderDialog: false, moreOptionsMainFolderTarget: null })
        }
    }

    handleDeleteFolder = () => {
        const { mainFolders, setMainFolders, currentCompany, setCurrentFile, currentFolder, currentMainFolder, setCurrentFolder, setCurrentMainFolder } = this.props

        const newMainFolder = { ...mainFolders[currentMainFolder.name] }
        delete newMainFolder.folders[currentFolder.name]

        api.delete(`/${escapeForwardSlash(currentCompany.name)}/folder/${escapeForwardSlash(currentFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}`)

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name] = newMainFolder

        setMainFolders(newMainFolders)
        setCurrentMainFolder(null)
        setCurrentFolder(null)
        setCurrentFile(null)

        this.setState({ deleteFolderDialog: false, moreOptionsFolderTarget: null })
    }

    handleDeleteSubFolder = () => {
        const { mainFolders, setMainFolders, currentCompany, setCurrentFile, currentFolder, currentSubFolder, currentMainFolder, setCurrentSubFolder, setCurrentFolder, setCurrentMainFolder } = this.props

        const newMainFolder = { ...mainFolders[currentMainFolder.name] }
        delete newMainFolder.folders[currentFolder.name].subFolders[currentSubFolder.name]

        api.delete(`/${escapeForwardSlash(currentCompany.name)}/subFolder/${escapeForwardSlash(currentSubFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`)

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name] = newMainFolder

        setMainFolders(newMainFolders)
        setCurrentSubFolder(null)
        setCurrentFile(null)

        this.setState({ deleteSubFolderDialog: false, moreOptionsSubFolderTarget: null })
    }

    handleFileDelete = () => {
        const { fileIdxToDelete } = this.state
        const { currentFile, mainFolders, currentCompany, setCurrentFile, mainFoldersEntities, setMainFoldersEntities } = this.props

        const newMainFolders = { ...mainFolders }

        switch (currentFile.type) {
            default:
                if (currentFile.subFolder == null) {
                    newMainFolders[currentFile.mainFolder].folders[currentFile.folder].files.splice(fileIdxToDelete, 1)
                } else {
                    newMainFolders[currentFile.mainFolder].folders[currentFile.folder].subFolders[currentFile.subFolder].files.splice(fileIdxToDelete, 1)
                }
                setMainFolders(newMainFolders)
                api.delete(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(currentFile.name)}/${escapeForwardSlash(currentFile.mainFolder)}/${escapeForwardSlash(currentFile.folder)}`)
                setCurrentFile(null)
        }

        this.setState({ deleteFileDialog: false })
    }

    handleOpenCodebank = () => {
        const { mainFolders, currentMainFolder, currentFolder, setCurrentMainFolder, setCurrentFolder } = this.props
        const { codebankPassword } = this.state

        if (codebankPassword == "123468") {
            this.setState({ wasCodebankPasswordCorrect: true, codebankPasswordDialog: false, didPasswordAttemptFail: false })
        } else {
            this.setState({ wasCodebankPasswordCorrect: false, didPasswordAttemptFail: true })
        }
    }

    handleTextFieldChange = (event) => {
        const field = event.target.name
        const newValue = event.target.value

        this.setState({ [field]: newValue })
    }

    handleRenameMainFolder = () => {
        const { newMainFolderName } = this.state
        const { mainFolders, setMainFolders, setCurrentMainFolder, setCurrentFolder, setCurrentSubFolder, currentMainFolder, currentFolder, currentSubFolder, currentCompany } = this.props

        if (newMainFolderName == null || newMainFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Hovedmappen mangler et navn" })
            return
        }
        const newMainFolder = { ...currentMainFolder }
        newMainFolder.name = newMainFolderName

        const newMainFolders = { ...mainFolders }
        newMainFolders[newMainFolder.name] = newMainFolder
        delete newMainFolders[currentMainFolder.name]

        api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(currentMainFolder.name)}`, newMainFolder)
            .then(() => {
                for (const folder of Object.values(newMainFolder.folders)) {
                    const newFolder = { ...folder }
                    newFolder.mainFolder = newMainFolder.name
                    newMainFolders[newMainFolder.name].folders[folder.name] = newFolder

                    api.put(`/${escapeForwardSlash(currentCompany.name)}/folder/${escapeForwardSlash(folder.name)}/${escapeForwardSlash(currentMainFolder.name)}`, newFolder)
                    for (const fileIdx in folder.files) {
                        const newFile = { ...folder.files[fileIdx] }
                        newFile.mainFolder = newMainFolder.name
                        for (const tagIdx in newFile.tags) {
                            if (newFile.tags[tagIdx] == currentMainFolder.name) {
                                newFile.tags.splice(tagIdx, 1, newMainFolder.name)
                            }
                        }
                        newMainFolders[newMainFolder.name].folders[folder.name].files.splice(fileIdx, 1, newFile)

                        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(folder.files[fileIdx].name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(folder.name)}`, newFile)
                    }
                    for (const subFolder of Object.values(folder.subFolders)) {
                        const newSubFolder = { ...subFolder }
                        newSubFolder.mainFolder = newMainFolder.name
                        newMainFolders[newMainFolder.name].folders[folder.name].subFolders[subFolder.name] = newSubFolder

                        api.put(`/${escapeForwardSlash(currentCompany.name)}/subFolder/${escapeForwardSlash(subFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(folder.name)}`, newSubFolder)
                        for (const fileIdx in subFolder.files) {
                            const newFile = { ...subFolder.files[fileIdx] }
                            newFile.mainFolder = newMainFolder.name
                            for (const tagIdx in newFile.tags) {
                                if (newFile.tags[tagIdx] == currentMainFolder.name) {
                                    newFile.tags.splice(tagIdx, 1, newMainFolder.name)
                                }
                            }
                            newMainFolders[newMainFolder.name].folders[folder.name].subFolders[subFolder.name].files.splice(fileIdx, 1, newFile)

                            api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(subFolder.files[fileIdx].name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(folder.name)}`, newFile)
                        }
                    }
                }
            })


        if (currentFolder != null) {
            const newCurrentFolder = { ...currentFolder }
            newCurrentFolder.mainFolder = newMainFolderName
            setCurrentFolder(newCurrentFolder)
        }

        setCurrentMainFolder(newMainFolder)
        if (currentSubFolder != null) {
            const newCurrentSubFolder = { ...currentSubFolder }
            newCurrentSubFolder.mainFolder = newMainFolderName
            setCurrentSubFolder(newCurrentSubFolder)
        }
        setMainFolders(newMainFolders)

        this.setState({ renameMainFolderDialog: false, moreOptionsMainFolderTarget: null })
    }

    handleRenameFolder = () => {
        const { newFolderName } = this.state
        const { mainFolders, setMainFolders, setCurrentFolder, currentSubFolder, setCurrentSubFolder, currentMainFolder, currentFolder, currentCompany } = this.props

        if (newFolderName == null || newFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Mappen mangler et navn" })
            return
        }

        const newFolder = { ...currentFolder }
        newFolder.name = newFolderName

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name].folders[newFolder.name] = newFolder
        delete newMainFolders[currentMainFolder.name].folders[currentFolder.name]

        api.put(`/${escapeForwardSlash(currentCompany.name)}/folder/${escapeForwardSlash(currentFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}`, newFolder)
            .then(() => {
                for (const fileIdx in newFolder.files) {
                    const newFile = { ...newFolder.files[fileIdx] }
                    newFile.folder = newFolder.name
                    for (const tagIdx in newFile.tags) {
                        if (newFile.tags[tagIdx] == currentFolder.name) {
                            newFile.tags.splice(tagIdx, 1, newFolder.name)
                        }
                    }
                    newMainFolders[currentMainFolder.name].folders[newFolder.name].files.splice(fileIdx, 1, newFile)

                    api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(newFolder.files[fileIdx].name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`, newFile)
                }
                for (const subFolder of Object.values(newFolder.subFolders)) {
                    const newSubFolder = { ...subFolder }
                    newSubFolder.folder = newFolder.name
                    newMainFolders[currentMainFolder.name].folders[newFolder.name].subFolders[newSubFolder.name] = newSubFolder

                    api.put(`/${escapeForwardSlash(currentCompany.name)}/subFolder/${escapeForwardSlash(subFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`, newSubFolder)
                    for (const fileIdx in subFolder.files) {
                        const newFile = { ...subFolder.files[fileIdx] }
                        newFile.folder = newFolder.name

                        for (const tagIdx in newFile.tags) {
                            if (newFile.tags[tagIdx] == currentFolder.name) {
                                newFile.tags.splice(tagIdx, 1, newFolder.name)
                            }
                        }
                        newMainFolders[currentMainFolder.name].folders[newFolder.name].subFolders[subFolder.name].files.splice(fileIdx, 1, newFile)

                        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(subFolder.files[fileIdx].name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`, newFile)
                    }
                }
            })

        if (currentFolder != null) {
            setCurrentFolder(newFolder)
        }

        if (currentSubFolder != null) {
            const newCurrentSubFolder = { ...currentSubFolder }
            newCurrentSubFolder.mainFolder = currentMainFolder.name
            setCurrentSubFolder(newCurrentSubFolder)
        }
        setMainFolders(newMainFolders)

        this.setState({ renameFolderDialog: false, moreOptionsFolderTarget: null })
    }

    handleRenameSubFolder = () => {
        const { newSubFolderName } = this.state
        const { mainFolders, setMainFolders, setCurrentSubFolder, currentMainFolder, currentFolder, currentSubFolder, currentCompany } = this.props

        if (newSubFolderName == null || newSubFolderName == "") {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Undermappen mangler et navn" })
            return
        }

        const newSubFolder = { ...currentSubFolder }
        newSubFolder.name = newSubFolderName

        const newMainFolders = { ...mainFolders }
        newMainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[newSubFolder.name] = newSubFolder
        delete newMainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[currentSubFolder.name]

        api.put(`/${escapeForwardSlash(currentCompany.name)}/subfolder/${escapeForwardSlash(currentSubFolder.name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`, newSubFolder)
            .then(() => {
                for (const fileIdx in newSubFolder.files) {
                    const newFile = { ...newSubFolder.files[fileIdx] }
                    newFile.subFolder = newSubFolder.name
                    for (const tagIdx in newFile.tags) {
                        if (newFile.tags[tagIdx] == currentSubFolder.name) {
                            newFile.tags.splice(tagIdx, 1, newSubFolder.name)
                        }
                    }
                    newMainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[newSubFolder.name].files.splice(fileIdx, 1, newFile)
                    api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(newSubFolder.files[fileIdx].name)}/${escapeForwardSlash(currentMainFolder.name)}/${escapeForwardSlash(currentFolder.name)}`, newFile)
                }
            })

        setCurrentSubFolder(newSubFolder)
        setMainFolders(newMainFolders)

        this.setState({ renameSubFolderDialog: false, moreOptionsSubFolderTarget: null })
    }

    setDVCType = (didClickUploadDocument) => {
        this.setState({ didClickUploadDocument })
    }

    toggleExpansionPanel = () => {
        const { isExpansionPanelExpandedMap, isExpandAll } = this.state

        const newIsExpansionPanelExpandedMap = { ...isExpansionPanelExpandedMap }
        Object.entries(newIsExpansionPanelExpandedMap).forEach(entry => {
            newIsExpansionPanelExpandedMap[entry[0]] = isExpandAll
        })

        this.setState({ isExpansionPanelExpandedMap: newIsExpansionPanelExpandedMap, isExpandAll: !isExpandAll })
    }

    moveMainFolderUp = () => {
        const { currentMainFolder, mainFolders, currentCompany, setMainFolders } = this.props

        const newMainFolder = { ...currentMainFolder }
        const newMainFolders = { ...mainFolders }

        if (newMainFolder.sortIndex == 0) {
            this.setState({ moreOptionsMainFolderTarget: null })
            return
        }

        newMainFolder.sortIndex = newMainFolder.sortIndex - 1

        api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(newMainFolder.name)}`, newMainFolder)
        newMainFolders[newMainFolder.name] = newMainFolder

        for (const mainFolder of Object.values(mainFolders)) {
            if (mainFolder.sortIndex == newMainFolder.sortIndex && mainFolder.name != newMainFolder.name) {
                const newSortIndex = mainFolder.sortIndex + 1
                newMainFolders[mainFolder.name].sortIndex = newSortIndex
                api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(mainFolder.name)}`, newMainFolders[mainFolder.name])
            }
        }

        setMainFolders(newMainFolders)
        this.setState({ moreOptionsMainFolderTarget: null })
    }

    moveMainFolderDown = () => {
        const { currentMainFolder, mainFolders, currentCompany, setMainFolders } = this.props

        const newMainFolder = { ...currentMainFolder }
        const newMainFolders = { ...mainFolders }

        if (newMainFolder.sortIndex == Object.keys(mainFolders).length - 1) {
            this.setState({ moreOptionsMainFolderTarget: null })
            return
        }

        newMainFolder.sortIndex = newMainFolder.sortIndex + 1

        api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(newMainFolder.name)}`, newMainFolder)
        newMainFolders[newMainFolder.name] = newMainFolder

        for (const mainFolder of Object.values(mainFolders)) {
            if (mainFolder.sortIndex == newMainFolder.sortIndex && mainFolder.name != newMainFolder.name) {
                const newSortIndex = mainFolder.sortIndex - 1
                newMainFolders[mainFolder.name].sortIndex = newSortIndex
                api.put(`/${escapeForwardSlash(currentCompany.name)}/mainFolder/${escapeForwardSlash(mainFolder.name)}`, newMainFolders[mainFolder.name])
            }
        }

        setMainFolders(newMainFolders)
        this.setState({ moreOptionsMainFolderTarget: null })
    }

    handleShareClicked = (file, idx) => {
        const { currentFolder, setCurrentFile, setCurrentFileIdx } = this.props

        setCurrentFile(file)
        setCurrentFileIdx(idx)
        this.handleDialogOpen("sharingDialog")
    }   

    handleSharingSelect = (selectField) => (event) => {
        this.setState({ [selectField]: event.target.value })
    }

    handleAddNewShare = () => {
        const { selectedPerson } = this.state
        const { currentFile, currentFolder, currentFileIdx, setCurrentFile, setCurrentFolder } = this.props

        if (!selectedPerson) {
            this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Der mangler at vælges en person" })
            return
        }

        if (currentFile) {
            const newFile = { ...currentFile }
            const newCurrentFolder = { ...currentFolder }
            
            if (newFile.sharedWith.indexOf(selectedPerson.name) != -1) {
                this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Personen deles allerede med" })
                return
            }

            newFile.sharedWith.push(selectedPerson.name)
            newCurrentFolder.files.splice(currentFileIdx, 1, newFile)

            api.put(`/${escapeForwardSlash(newFile.company)}/file/${escapeForwardSlash(newFile.name)}/${escapeForwardSlash(newFile.mainFolder)}/${escapeForwardSlash(newFile.folder)}`, newFile)
                .then(() => {
                    setCurrentFile(newFile)
                    setCurrentFolder(newCurrentFolder)
                    this.setState({ selectedPerson: null })
                })
        } else if (currentFolder) {
            const newFolder = { ...currentFolder }

            if (newFolder.sharedWith.indexOf(selectedPerson.name) != -1) {
                this.setState({ isWarningBarOpen: true, informationSnackbarTopMargin: 94, informationSnackbarMessage: "Personen deles allerede med" })
                return
            }

            newFolder.sharedWith.push(selectedPerson.name)

            api.put(`/${escapeForwardSlash(newFolder.name)}/folder/${escapeForwardSlash(newFolder.name)}/${escapeForwardSlash(newFolder.mainFolder)}`, newFolder)
                .then(() => {
                    setCurrentFolder(newFolder)
                    this.setState({ selectedPerson: null })
                })
        }
    }

    createFolders = (classes) => {
        const { moreOptionsMainFolderTarget, heading, isExpansionPanelExpandedMap } = this.state
        const { mainFolders, currentFolder, currentMainFolder, currentCompany } = this.props

        const getMainFolderFileCount = (mainFolder) => {
            let count = 0
            Object.values(mainFolder.folders).forEach(folder => {
                count += folder.files.length
                if (folder.subFolders) {
                    Object.values(folder.subFolders).forEach(subFolder => {
                        count += subFolder.files.length
                    })
                }
            })

            return count === 0 ? "" : `(${count})`
        }

        return Object.values(mainFolders).filter(mf => mf.company == currentCompany.name).sort(((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1)).map((mainFolder, mainFolderIdx) => {
            return (
                <Grid item key={`${mainFolderIdx}_${mainFolder.name}`} style={{ width: "100%" }}>
                    <ExpansionPanel expanded={typeof isExpansionPanelExpandedMap[mainFolder.name] != "undefined" ? isExpansionPanelExpandedMap[mainFolder.name] : true} onChange={(obj, exp) => this.setState({ isExpansionPanelExpandedMap: { ...isExpansionPanelExpandedMap, [mainFolder.name]: exp } })} key={`${mainFolder.name}_${mainFolderIdx}`}>
                        <ExpansionPanelSummary
                            className={classes.mainFolderBar}
                            classes={{ content: classes.expandedMainFolder, root: classes.rootMainFolder, focused: classes.focusedMainFolder }}
                            aria-controls={`panel${mainFolderIdx + 1}-${mainFolder.name}-content`}
                            id={`panel${mainFolderIdx + 1}-${mainFolder.name}-header`}
                        >
                            <Grid container direction="row" alignItems="center" justify="space-between">
                                <Grid item xs>
                                    <Typography className={heading} color="textSecondary">{mainFolder.name} {getMainFolderFileCount(mainFolder)}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ marginRight: 6 }}>
                                    <IconButton style={{ color: "white" }} onClick={(e) => { e.stopPropagation(); this.handleMoreOptionsClicked(e, "moreOptionsMainFolderTarget", mainFolder.name) }}>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu id={`menu_${mainFolderIdx}`} anchorEl={moreOptionsMainFolderTarget} open={Boolean(moreOptionsMainFolderTarget) && currentMainFolder.name == mainFolder.name} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose("moreOptionsMainFolderTarget") }}>
                                        <MenuItem key="Opret hovedmappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("addNewMainFolderDialog") }}>
                                            Opret hovedmappe
                                        </MenuItem>
                                        <MenuItem key="Opret mappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("addNewFolderDialog") }}>
                                            Opret mappe
                                        </MenuItem>
                                        <MenuItem key="Flyt op" onClick={(e) => { e.stopPropagation(); this.moveMainFolderUp() }}>
                                            Flyt op
                                        </MenuItem>
                                        <MenuItem key="Flyt ned" onClick={(e) => { e.stopPropagation(); this.moveMainFolderDown() }}>
                                            Flyt ned
                                        </MenuItem>
                                        <MenuItem key="Omdøb hovedmappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("renameMainFolderDialog") }}>
                                            Omdøb
                                        </MenuItem>
                                        <MenuItem key="Slet hovedmappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("deleteMainFolderDialog") }}>
                                            Slet
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.rootExpansionPanelDetails }}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                {Object.values(mainFolder.folders).sort(((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)))).map((folder, folderIdx) => {
                                    return (
                                        <Grid item key={`${folder.name}_${folderIdx}`}>
                                            <ListItem className={classes.drawerItem} selected={currentFolder ? currentFolder.name == folder.name && currentFolder.mainFolder == mainFolder.name : false} button onClick={() => this.setState({ wasCodebankPasswordCorrect: false }, () => this.handleFolderClicked(mainFolder.name, folder.name))}>
                                                <ListItemIcon style={{ minWidth: 36 }}>{createListItemIcon(folder.icon, folder.folderContentType)}</ListItemIcon>
                                                <ListItemText className={classes.heading} primary={folder.name} />
                                            </ListItem>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            )
        })
    }

    render() {
        const { sharingDialog, selectedDomain, selectedPerson, informationSnackbarMessage, newFolderType, renameSubFolderDialog, isExpandAll, renameFolderDialog, selectedFileType, didClickUploadDocument, moreOptionsSubFolderTarget, isDocumentValuesContainerModalOpen, renameMainFolderDialog, informationSnackbarTopMargin, isDrawerVisible, moreOptionsFolderTarget, addNewMainFolderDialog, addNewFolderDialog, addNewPasswordDialog, deleteMainFolderDialog, deleteFolderDialog, deleteSubFolderDialog, addNewSubFolderDialog, addNewNoteDialog, deleteFileDialog, isWarningBarOpen, codebankPasswordDialog, wasCodebankPasswordCorrect, didPasswordAttemptFail } = this.state
        const { sharing, currentFile, classes, mainFolders, currentFolder, currentMainFolder, currentSubFolder, searchValue, loggedInUser, isLoading } = this.props

        const textFieldInputLabelProps = {
            classes: {
                root: classes.textField
            }
        }

        return (
            <div>
                {!isLoading
                    ?
                    <Fragment>
                        <div className={classes.root}>
                            <div className={classes.rootGrid}>
                                <Grid container direction="column" className={`${classes.drawerContainer} ${!isDrawerVisible ? classes.drawerContainerShifted : ""}`}>
                                    <Grid item className={classes.drawerHeader}>
                                        <Grid container direction="row" justify="flex-end">
                                            <Grid item>
                                                <IconButton onClick={this.toggleExpansionPanel}>
                                                    {isExpandAll
                                                        ?
                                                        <UnfoldMore />
                                                        :
                                                        <UnfoldLess />
                                                    }
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={this.handleDrawerClose}>
                                                    <ChevronLeft />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.foldersContainer}>
                                        <Grid container direction="column">
                                            {Object.keys(mainFolders).length > 0
                                                ?
                                                (this.createFolders(classes))
                                                :
                                                <Grid item style={{ display: "flex", justifyContent: "center", marginTop: 24 }}>
                                                    <Fab color="primary" style={{ color: "white" }} onClick={() => this.handleDialogOpen("addNewMainFolderDialog")}>
                                                        <Add />
                                                    </Fab>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={`${classes.contentContainer} ${!isDrawerVisible ? classes.contentContainerShifted : ""}`}>
                                    <Grid item style={{ width: "100%" }}>
                                        <Grid container className={classes.contentHeader} direction="row">
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                                    <Grid item>
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="Open drawer"
                                                            onClick={this.handleDrawerOpen}
                                                            edge="start"
                                                            className={`${isDrawerVisible ? classes.hide : classes.menuIcon}`}
                                                        >
                                                            <MenuIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Typography
                                                            variant="h6"
                                                            noWrap
                                                            className={`${classes.menuTitle} ${!isDrawerVisible ? classes.menuTitleShifted : ""}`}
                                                        >
                                                            {currentFolder ? `${currentFolder.name} (${Object.values(currentFolder.subFolders).map(subFolder => subFolder.files.length).reduce((a, b) => a + b, 0) + currentFolder.files.length})` : "Ingen mappe valgt"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {currentFolder &&
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="center">
                                                        <Grid item>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); this.handleShareClicked() }}>
                                                                <Share />
                                                            </IconButton>
                                                        </Grid>
                                                        {currentFolder.sharedWith.length > 0 &&
                                                            <Grid item className={classes.tagContainer}>
                                                                <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }}>
                                                                    {currentFolder.sharedWith.map((name, idx) => {
                                                                        let initials = name.split(" ").map(namePart => namePart[0]).join("")
                                                                        
                                                                        return (
                                                                            <Grid item key={`${name}_${idx}_${name}`}>
                                                                                <Chip 
                                                                                    style={{ background: "lightgreen", fontWeight: 500 }}
                                                                                    label={initials}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                            </Grid>
                                                        </Grid>
                                                        }
                                                        <Grid item>
                                                            <IconButton onClick={(e) => this.handleMoreOptionsClicked(e, "moreOptionsFolderTarget", currentFolder.mainFolder, currentFolder.name)}>
                                                                <MoreVert />
                                                            </IconButton>
                                                            <Menu id="simple-menu" anchorEl={moreOptionsFolderTarget} open={Boolean(moreOptionsFolderTarget)} onClose={(e) => { e.stopPropagation(); this.handleMoreOptionsClose("moreOptionsFolderTarget") }}>
                                                                {currentFolder.folderContentType == "kodebank"
                                                                    ?
                                                                    <MenuItem key="Opret kodeord" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("addNewPasswordDialog") }}>
                                                                        Opret kodeord
                                                                    </MenuItem>
                                                                    :
                                                                    <MenuItem key="Opret note" onClick={(e) => { e.stopPropagation(); this.setDVCType(false); this.handleAddNewNoteClicked(fileTypes.NOTE) }}>
                                                                        Opret note
                                                                    </MenuItem>
                                                                }
                                                                {currentFolder.folderContentType != "kodebank" &&
                                                                    <MenuItem key="Upload dokument" onClick={(e) => { e.stopPropagation(); this.setDVCType(true); this.handleUploadDocumentClicked(fileTypes.DOCUMENT) }}>
                                                                        Upload dokument
                                                                    </MenuItem>
                                                                }
                                                                <MenuItem key="Opret undermappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("addNewSubFolderDialog") }}>
                                                                    Opret undermappe
                                                                </MenuItem>
                                                                <MenuItem key="Omdøb mappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("renameFolderDialog") }}>
                                                                    Omdøb
                                                                </MenuItem>
                                                                <MenuItem key="Slet mappe" onClick={(e) => { e.stopPropagation(); this.handleDialogOpen("deleteFolderDialog") }}>
                                                                    Slet
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.content}>
                                        <FolderExpansionPanel
                                            handleShareClicked={this.handleShareClicked}
                                            setWarningBarAndLabel={this.setWarningBarAndLabel}
                                            wasCodebankPasswordCorrect={wasCodebankPasswordCorrect}
                                            handleFileDelete={this.handleFileDelete}
                                            handleDialogOpen={this.handleDialogOpen}
                                            handleMoreOptionsClicked={this.handleMoreOptionsClicked}
                                            handleMoreOptionsClose={this.handleMoreOptionsClose}
                                            currentFolder={currentFolder}
                                            handleAddNewNoteClicked={this.handleAddNewNoteClicked}
                                            handleDocumentValuesContainerModalOpen={this.handleDocumentValuesContainerModalOpen}
                                            handleDocumentValuesContainerModalClose={this.handleDocumentValuesContainerModalClose}
                                            isDocumentValuesContainerModalOpen={isDocumentValuesContainerModalOpen}
                                            selectedFileType={selectedFileType}
                                            moreOptionsSubFolderTarget={moreOptionsSubFolderTarget}
                                            setDVCType={this.setDVCType}
                                            didClickUploadDocument={didClickUploadDocument}
                                            handleUploadDocumentClicked={this.handleUploadDocumentClicked}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <Grid container justify="center" alignItems="center" style={{ height: "calc(100vh - 150px)", width: "100%" }}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                {sharingDialog && 
                <Dialog open={sharingDialog} onClose={() => { this.handleDialogClose("sharingDialog"); this.setState({ selectedDomain: null, selectedPerson: null }) }} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Deling</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Vælg delinger
                        </DialogContentText>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="domain">Domæne</InputLabel>
                            <Select
                                value={selectedDomain || ""}
                                input={<Input id="domain" />}
                                onChange={this.handleSharingSelect("selectedDomain")}
                            >
                                {sharing && Object.keys(sharing).length > 0
                                    ?
                                    (Object.keys(sharing).filter(domain => sharing[domain].length > 0).map(domain => {
                                        return (
                                            <MenuItem key={domain} value={domain}>{domain}</MenuItem>
                                        )
                                    }))
                                    :
                                    <MenuItem value=""><em>Ingen oprettede domæner</em></MenuItem>
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="person">Person</InputLabel>
                            <Select
                                value={selectedPerson || ""}
                                input={<Input id="person" />}
                                onChange={this.handleSharingSelect("selectedPerson")}
                            >
                                {sharing && sharing[selectedDomain] && sharing[selectedDomain].length > 0
                                    ?
                                    (sharing[selectedDomain].map((person, personIdx) => {
                                        return (
                                            <MenuItem key={`${person.name}_${personIdx}`} value={person}>{person.name}</MenuItem>
                                        )
                                    }))
                                    :
                                    <MenuItem value=""><em>Ingen oprettede personer</em></MenuItem>
                                }
                            </Select>
                        </FormControl>
                        <Grid container direction="row" spacing={1} style={{ maxWidth: 372 }}>
                            {currentFile  
                                ?
                                (currentFile.sharedWith.length < 1
                                    ?
                                        <Grid item>
                                            <Typography>Ingen delinger endnu</Typography>
                                        </Grid>
                                    :
                                    (currentFile.sharedWith.map((person, personIdx) => {
                                        let icon
                                        return (
                                            <Grid item key={`${person}_${personIdx}`}>
                                                <Chip
                                                    key={`${personIdx}_${person}`}
                                                    icon={icon}
                                                    label={person}
                                                    onDelete={() => this.handleSharingDelete(personIdx)}
                                                />
                                            </Grid>
                                        )
                                    }))
                                )
                                :
                                (currentFolder 
                                    &&
                                    (currentFolder.sharedWith.length < 1
                                        ?
                                            <Grid item>
                                                <Typography>Ingen delinger endnu</Typography>
                                            </Grid>
                                        :
                                        (currentFolder.sharedWith.map((person, personIdx) => {
                                            let icon
                                            return (
                                                <Grid item key={`${person}_${personIdx}`}>
                                                    <Chip
                                                        key={`${personIdx}_${person}`}
                                                        icon={icon}
                                                        label={person}
                                                        onDelete={() => this.handleSharingDelete(personIdx)}
                                                    />
                                                </Grid>
                                            )
                                        }))
                                    )
                                )
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.handleDialogClose("sharingDialog"); this.setState({ selectedDomain: null, selectedPerson: null }) }} color="primary">
                            Luk
                        </Button>
                        <Button onClick={this.handleAddNewShare} color="primary">
                            Del
                        </Button>
                    </DialogActions>
                </Dialog>
                }

                {renameMainFolderDialog &&
                <Dialog open={renameMainFolderDialog} onClose={() => this.handleDialogClose("renameMainFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Omdøb hovedmappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et nyt navn til hovedmappen
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newMainFolderName"
                            label="Hovedmappens navn"
                            defaultValue={(currentMainFolder && currentMainFolder.name) || ""}
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("renameMainFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleRenameMainFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {renameFolderDialog &&
                <Dialog open={renameFolderDialog} onClose={() => this.handleDialogClose("renameFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Omdøb Mappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et nyt navn til mappen
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newFolderName"
                            label="Mappens navn"
                            defaultValue={(currentFolder && currentFolder.name) || ""}
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("renameFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleRenameFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {renameSubFolderDialog &&
                <Dialog open={renameSubFolderDialog} onClose={() => this.handleDialogClose("renameSubFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Omdøb undermappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et nyt navn til undermappen
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newSubFolderName"
                            label="Undermappens navn"
                            defaultValue={(currentSubFolder && currentSubFolder.name) || ""}
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("renameSubFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleRenameSubFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {addNewMainFolderDialog && 
                <Dialog open={addNewMainFolderDialog} onClose={() => this.handleDialogClose("addNewMainFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Opret ny hovedmappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et navn til den nye hovedmappe
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newMainFolderName"
                            label="Hovedmappens navn"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("addNewMainFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleAddNewMainFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {addNewFolderDialog &&
                <Dialog open={addNewFolderDialog} onClose={() => this.handleDialogClose("addNewFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Opret ny mappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et navn og vælg en type til den nye mappe
                        </DialogContentText>
                        <RadioGroup
                            name="newFolderType"
                            value={newFolderType}
                            onChange={this.handleTextFieldChange}
                            className={classes.radioGroup}
                        >
                            <FormControlLabel value="regnskab" control={<Radio color="primary" className={classes.radioButton} classes={{ colorSecondary: classes.radioButton }} />} label="Regnskab" labelPlacement="top"></FormControlLabel>
                            <FormControlLabel value="persondata" control={<Radio color="primary" className={classes.radioButton} classes={{ colorSecondary: classes.radioButton }} />} label="Persondata" labelPlacement="top"></FormControlLabel>
                            <FormControlLabel value="kodebank" control={<Radio color="primary" className={classes.radioButton} classes={{ colorSecondary: classes.radioButton }} />} label="Kodebank" labelPlacement="top"></FormControlLabel>
                        </RadioGroup>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newFolderName"
                            label="Mappens navn"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("addNewFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleAddNewFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {addNewSubFolderDialog &&
                <Dialog open={addNewSubFolderDialog} onClose={() => this.handleDialogClose("addNewSubFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Opret ny undermappe</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast et navn til den nye undermappe
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newSubFolderName"
                            label="Undermappens navn"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("addNewSubFolderDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleAddNewSubFolder} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {addNewPasswordDialog && 
                <Dialog open={addNewPasswordDialog} onClose={() => this.handleDialogClose("addNewPasswordDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>Opret nyt kodeord</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Indtast en titel og IP til kodeordet, samt selve kodeordet
                        </DialogContentText>
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            autoFocus={true}
                            variant="outlined"
                            margin="dense"
                            name="newPasswordName"
                            label="Titel"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            variant="outlined"
                            margin="dense"
                            name="newPasswordIP"
                            label="IP"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                        <TextField
                            InputLabelProps={textFieldInputLabelProps}
                            variant="outlined"
                            margin="dense"
                            name="newPasswordPassword"
                            label="Kodeord"
                            type="password"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("addNewPasswordDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleAddNewPassword} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {deleteMainFolderDialog &&
                <Dialog open={deleteMainFolderDialog} onClose={() => this.handleDialogClose("deleteMainFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>
                        Er du sikker på du vil slette hovedmappen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Alle hovedmappens mapper, undermapper og filer vil blive slettet
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("deleteMainFolderDialog")} color="primary">
                            Afbryd
                        </Button>
                        <Button onClick={this.handleDeleteMainFolder} color="primary">
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {deleteFolderDialog &&
                <Dialog open={deleteFolderDialog} onClose={() => this.handleDialogClose("deleteFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>
                        Er du sikker på du vil slette mappen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Alle mappens undermapper og filer vil blive slettet
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("deleteFolderDialog")} color="primary">
                            Afbryd
                        </Button>
                        <Button onClick={this.handleDeleteFolder} color="primary">
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {deleteSubFolderDialog &&
                <Dialog open={deleteSubFolderDialog} onClose={() => this.handleDialogClose("deleteSubFolderDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>
                        Er du sikker på du vil slette undermappen?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            Alle undermappens filer vil blive slettet
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("deleteSubFolderDialog")} color="primary">
                            Afbryd
                        </Button>
                        <Button onClick={this.handleDeleteSubFolder} color="primary">
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {deleteFileDialog && 
                <Dialog open={deleteFileDialog} onClose={() => this.handleDialogClose("deleteFileDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>
                        Er du sikker på du vil slette filen?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("deleteFileDialog")} color="primary">
                            Afbryd
                        </Button>
                        <Button onClick={this.handleFileDelete} color="primary">
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                {codebankPasswordDialog &&
                <Dialog open={codebankPasswordDialog} onClose={() => this.handleDialogClose("codebankPasswordDialog")} onClick={(e) => e.stopPropagation()}>
                    <DialogTitle>
                        Indtast kodeordet for at se mappens indhold
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            error={didPasswordAttemptFail}
                            helperText={didPasswordAttemptFail ? "Forkert kodeord indtastet" : ""}
                            InputLabelProps={textFieldInputLabelProps}
                            variant="outlined"
                            margin="dense"
                            name="codebankPassword"
                            label="Password"
                            type="password"
                            fullWidth
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.handleTextFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose("codebankPasswordDialog")} color="primary">
                            Annullér
                        </Button>
                        <Button onClick={this.handleOpenCodebank} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                }
                <InformationSnackbar handleBarClose={this.handleWarningBarClose} isBarOpen={isWarningBarOpen} message={informationSnackbarMessage} topMargin={informationSnackbarTopMargin} />
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        width: "100%",
        height: "calc(100vh - 150px)",
        padding: theme.spacing(3),
        position: "relative",
    },
    rootGrid: {
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        height: "100%"
    },
    drawerContainer: {
        width: drawerWidth,
        height: "100%",
        background: theme.palette.background.paper,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    drawerContainerShifted: {
    },
    drawerHeader: {
        height: 64,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    drawerItem: {
        height: 64,
        width: drawerWidth,
    },
    contentContainer: {
        position: "absolute",
        top: theme.spacing(3),
        left: theme.spacing(3) + drawerWidth,
        width: `calc(100% - ${drawerWidth + theme.spacing(6)}px)`,
        height: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentContainerShifted: {
        left: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    contentHeader: {
        height: 64,
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.paper
    },
    content: {
        background: "whitesmoke",
        width: "100%",
        height: `calc(100% - 64px)`,
        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    foldersContainer: {
        height: "calc(100% - 64px)",
        overflow: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    hide: {
        display: 'none'
    },
    menuTitle: {
        margin: 0,
        marginLeft: theme.spacing(3)
    },
    menuTitleShifted: {
        marginLeft: 0
    },
    menuIcon: {
        marginLeft: 0
    },
    rootExpansionPanelDetails: {
        padding: 0,
    },
    mainFolderBar: {
        background: "dimgrey"
    },
    rootMainFolder: {
        padding: "0 0 0 16px"
    },
    expandedMainFolder: {
        margin: "0px !important"
    },
    focusedMainFolder: {
        backgroundColor: "dimgrey !important"
    },
    textField: {
        color: theme.palette.primary.main
    },
    paperScrollPaper: {
        maxHeight: "calc(100% - 58px)"
    },
    radioButton: {
        color: "black"
    },
    radioGroup: {
        flexDirection: "row",
        justifyContent: "center"
    },
    heading: {
        hyphens: "auto",
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    tagContainer: {
        overflowX: "scroll",
        padding: 4,
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    }
})

const mapStateToProps = ({ state }) => {
    return {
        sharing: state.sharing,
        currentCompany: state.currentCompany,
        isLoading: state.isLoading,
        mainFolders: state.mainFolders,
        currentMainFolder: state.currentMainFolder,
        currentFolder: state.currentFolder,
        currentFile: state.currentFile,
        currentSubFolder: state.currentSubFolder,
        searchValue: state.searchValue,
        loggedInUser: state.loggedInUser,
        currentFileIdx: state.currentFileIdx
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setIsLoading, setSearchValue, setMainFolders, setCurrentMainFolder, setCurrentSubFolder, setCurrentFolder, setCurrentFile, setCurrentFileIdx }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(FolderList))