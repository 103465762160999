import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { makeStyles } from '@material-ui/styles'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Paper, Typography, Dialog, List, Button, Icon, Tabs, Tab, CircularProgress, Divider, IconButton, ListItem, ListItemText, ListItemSecondaryAction, Checkbox } from '@material-ui/core'
import { Close, Timeline, Alarm, Attachment } from "@material-ui/icons"
import moment from "moment"
import DocumentValuesContainer from "../components/DocumentValuesContainer"
import DocumentModal from "../components/DocumentModal"
import api, { escapeForwardSlash } from "../api"
import { fileTypes } from "../enums"
import { setSearchValue, setDeadlineItems, setSearchResults, setCurrentFile, setCurrentSubFolder } from "../actions/actions"

class Home extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            exceededItems: [],
            upcomingItems: [],
            activities: [],
            missingDocuments: [],
            numberOfFiles: 0,
            numberOfNotes: 0,
            numberOfUsers: 0,
            yearsAndMonths: 0,
            yearsAndMonthsLabel: "",
            isLoading: true,
            selectedTabIdx: 0,
            isDocumentValuesContainerModalOpen: false,
            itemsModified: null,
            isDocumentModalOpen: false,
            infographicsEconomyDimensions: null,
            infographicsCustomersDimensions: null
        }
    }

    componentDidMount() {
        const { currentCompany } = this.props

        this.setup()
        this.setState({
            infographicsEconomyDimensions: this.calculateInfographicsDimensions(currentCompany.economySrc),
            infographicsCustomersDimensions: this.calculateInfographicsDimensions(currentCompany.customersSrc)
        })
    }

    setup = () => {
        this.getDeadlineItems()
        this.getActivities()
        this.getMissingDocuments()
        this.getLeftTilesItems()
    }

    calculateInfographicsDimensions = async (src) => {
        return await this.getImageDimensions(src)
    }

    getImageDimensions = (src) => {
        return new Promise((resolved, rejected) => {
            var image = new Image()
            image.onload = function () {
                resolved({ w: image.width, h: image.height })
            }
            image.src = src
        })
    }

    getMissingDocuments = () => {
        const { currentCompany, mainFolders } = this.props

        const missingDocuments = []

        Object.values(mainFolders).forEach(mainFolder => {
            Object.values(mainFolder.folders).forEach(folder => {
                folder.files.forEach(folderFile => {
                    if (folderFile.urgency == "HIGH") {
                        missingDocuments.push(folderFile)
                    }
                })
                if (folder.subFolders != null) {
                    Object.values(folder.subFolders).forEach(subFolder => {
                        subFolder.files.forEach(subFolderFile => {
                            if (subFolderFile.urgency == "HIGH") {
                                missingDocuments.push(subFolderFile)
                            }
                        })
                    })
                }
            })
        })

        this.setState({ missingDocuments })
    }

    getActivities = () => {
        const { currentCompany } = this.props

        api.get(`/${escapeForwardSlash(currentCompany.name)}/activity`)
            .then(res => {
                if (res.data && res.data.rows.length > 0) {
                    this.setState({ activities: res.data.rows.sort((a, b) => moment(a.date) < moment(b.date) ? 1 : -1) })
                }
            })
    }

    getLeftTilesItems = () => {
        const { currentCompany, mainFolders } = this.props

        let numberOfFiles = 0
        let numberOfNotes = 0
        let numberOfUsers = 0
        let yearsAndMonths = 0
        let yearsAndMonthsLabel = ""

        Object.values(mainFolders).forEach(mainFolder => {
            Object.values(mainFolder.folders).forEach(folder => {
                for (const file of folder.files) {
                    if (file.src != null) {
                        numberOfFiles += 1
                    }
                    numberOfNotes += 1
                }
                if (folder.subFolders != null) {
                    Object.values(folder.subFolders).forEach(subFolder => {
                        for (const file of subFolder.files) {
                            if (file.src != null) {
                                numberOfFiles += 1
                            }
                            numberOfNotes += 1
                        }
                    })
                }
            })
        })

        const duration = moment.duration(moment().add(1, "days").diff(moment(currentCompany.establishedDate)))
        const months = duration._data.months
        const years = duration._data.years
        if (months == 0 && years > 0) {
            yearsAndMonths = `${years}`
            yearsAndMonthsLabel = "År"
        } else if (years == 0 && months > 0) {
            yearsAndMonths = `${months}`
            yearsAndMonthsLabel = "Måneder"
        } else if (years == 0 && months == 0) {
            yearsAndMonths = `${duration._data.days}`
            yearsAndMonthsLabel = "Dage"
        } else {
            yearsAndMonths = `${years}/${months}`
            yearsAndMonthsLabel = "År/md"
        }

        api.get(`/user`)
            .then(res => {
                if (res.data && res.data.rows.length > 0) {
                    numberOfUsers = res.data.rows.length
                }
                this.setState({ numberOfFiles, numberOfNotes, numberOfUsers, yearsAndMonths, yearsAndMonthsLabel, isLoading: false })
            })
    }

    getDeadlineItems = () => {
        const { mainFolders, setDeadlineItems } = this.props
        const currentDate = moment().format("YYYY-MM-DD")

        const exceededItems = { reminders: [], exceeded: [] }
        const upcomingItems = { reminders: [], upcoming: [] }
        Object.values(mainFolders).forEach(mainFolder => {
            Object.values(mainFolder.folders).forEach(folder => {
                folder.files.forEach(folderFile => {
                    if (moment(folderFile.dateDue).isBefore(currentDate, "day") && !folderFile.isRemovedExceededDeadlines) {
                        exceededItems.exceeded.push({ ...folderFile, sortDate: folderFile.dateDue, isReminder: false })
                    }
                    if (moment(folderFile.nextReminder).isBefore(currentDate, "day") && !folderFile.isRemovedExceededDeadlines) {
                        exceededItems.reminders.push({ ...folderFile, sortDate: folderFile.nextReminder, isReminder: true })
                    }

                    if (moment(folderFile.dateDue).diff(currentDate, "days") >= 0 && moment(folderFile.dateDue).diff(currentDate, "years") <= 1 && !folderFile.isRemovedUpcomingDeadlines) {
                        upcomingItems.upcoming.push({ ...folderFile, sortDate: folderFile.dateDue, isReminder: false })
                    }
                    if (moment(folderFile.nextReminder).diff(currentDate, "days") >= 0 && moment(folderFile.nextReminder).diff(currentDate, "years") <= 1 && !folderFile.isRemovedUpcomingDeadlines) {
                        upcomingItems.reminders.push({ ...folderFile, sortDate: folderFile.nextReminder, isReminder: true })
                    }
                })

                Object.values(folder.subFolders).forEach(subFolder => {
                    subFolder.files.forEach(subFolderFile => {
                        if (moment(subFolderFile.dateDue).isBefore(currentDate, "day") && !subFolderFile.isRemovedExceededDeadlines) {
                            exceededItems.exceeded.push({ ...subFolderFile, sortDate: subFolderFile.dateDue, isReminder: false })
                        }
                        if (moment(subFolderFile.nextReminder).isBefore(currentDate, "day") && !subFolderFile.isRemovedExceededDeadlines) {
                            exceededItems.reminders.push({ ...subFolderFile, sortDate: subFolderFile.nextReminder, isReminder: true })
                        }

                        if (moment(subFolderFile.dateDue).diff(currentDate, "days") >= 0 && moment(subFolderFile.dateDue).diff(currentDate, "years") <= 1 && !subFolderFile.isRemovedUpcomingDeadlines) {
                            upcomingItems.upcoming.push({ ...subFolderFile, sortDate: subFolderFile.dateDue, isReminder: false })
                        }
                        if (moment(subFolderFile.nextReminder).diff(currentDate, "days") >= 0 && moment(subFolderFile.nextReminder).diff(currentDate, "years") <= 1 && !subFolderFile.isRemovedUpcomingDeadlines) {
                            upcomingItems.reminders.push({ ...subFolderFile, sortDate: subFolderFile.nextReminder, isReminder: true })
                        }
                    })
                })
            })
        })

        setDeadlineItems({ upcoming: upcomingItems.upcoming, exceeded: exceededItems.exceeded })
        this.setState({ exceededItems, upcomingItems })
    }

    handleDeleteExceededItem = (items) => {
        this.setState({ exceededItems: items })
    }

    handleDeleteUpcomingItem = (items) => {
        this.setState({ upcomingItems: items })
    }

    handleDocumentValuesContainerModalClose = (e) => {
        const { setCurrentFile } = this.props

        e && e.stopPropagation()

        this.setState({ isDocumentValuesContainerModalOpen: false }, () => setCurrentFile(null))
    }

    handleDocumentValuesContainerModalOpen = (file, subFolder) => {
        const { setCurrentFile, mainFolders, currentMainFolder, currentFolder, setCurrentSubFolder } = this.props
        setCurrentFile(file)
        subFolder && setCurrentSubFolder(mainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[subFolder.name])
        this.setState({ isDocumentValuesContainerModalOpen: true })
    }

    handleDocumentModalOpen = (file, subFolder, didClickAttachedFileButton) => {
        const { setCurrentFile, loggedInUser, currentCompany } = this.props

        if (file.type == "password") {
            return
        } else if (didClickAttachedFileButton) {
            setCurrentFile(file)
            this.setState({ isDocumentModalOpen: true })
        } else if (file.type == "note" || file.type == "document") {
            setCurrentFile(file)
            this.handleDocumentValuesContainerModalOpen(file, subFolder)

            const newActivity = {
                company: currentCompany.name,
                user: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                date: moment(),
                event: file.name
            }

            api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Åbnet" })
        }
    }

    handleDocumentModalClose = () => {
        this.setState({ isDocumentModalOpen: false })
    }

    handleSetNewAttachedFile = file => {
        this.setState({ newAttachedFile: file })
    }

    setItemsModified = (itemsModified) => {
        this.setState({ itemsModified })
    }

    getRefreshDashboardFunction = () => {
        const { itemsModified } = this.state

        if (itemsModified == "activities") return this.getActivities
        if (itemsModified == "upcomingDeadlines" || itemsModified == "exceededDeadlines") return this.getDeadlineItems
        if (itemsModified == "missingDocuments") return this.getMissingDocuments
    }

    render() {
        const { exceededItems, missingDocuments, infographicsEconomyDimensions, infographicsCustomersDimensions, selectedTabIdx, newAttachedFile, isDocumentModalOpen, upcomingItems, yearsAndMonths, yearsAndMonthsLabel, numberOfFiles, numberOfNotes, numberOfUsers, isLoading, activities, isDocumentValuesContainerModalOpen } = this.state
        const { classes, mainFolders, currentCompany, currentFile } = this.props

        return (
            <Fragment>
                <Grid container direction="row" spacing={2} className={classes.root}>
                    {isLoading
                        ?
                        <CircularProgress style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                        :
                        <Fragment>
                            <Paper square style={{ position: "absolute", top: 9, left: 40 }}>
                                <Tabs
                                    classes={{ root: classes.rootTabs }}
                                    value={selectedTabIdx}
                                    indicatorColor="primary"
                                    onChange={(e, newValue) => this.setState({ selectedTabIdx: newValue })}
                                    textColor="primary"
                                >
                                    <Tab classes={{ root: classes.rootTab }} label="Drift" />
                                    <Tab classes={{ root: classes.rootTab }} label="Økonomi" />
                                    <Tab classes={{ root: classes.rootTab }} label="Kunder" />
                                </Tabs>
                            </Paper>
                            {selectedTabIdx === 0
                                ?
                                <Fragment>
                                    <Grid item xs={6} style={{ height: "100%", width: "100%", paddingBottom: 4 }}>
                                        <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                            <Grid item style={{ height: "100%", paddingBottom: 4, width: "100%" }}>
                                                <Grid container direction="column" style={{ height: "100%", width: "100%", flexWrap: "nowrap" }}>
                                                    <Grid item style={{ width: "100%", marginBottom: 8, flexGrow: 1, maxHeight: "45%" }}>
                                                        <img
                                                            src={currentCompany.infographics1Src}
                                                            alt="infographics1"
                                                            style={{ height: "100%", width: "100%", boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)" }}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flexGrow: 1 }}>
                                                        <Grid container direction="row" spacing={1} style={{ height: "100%", marginBottom: 8 }}>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{moment(currentCompany.establishedDate).format("YYYY")}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">Stiftet</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{yearsAndMonths}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">{yearsAndMonthsLabel}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{currentCompany.numOfEmployees || 0}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">Medarbejdere</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{numberOfNotes}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">Noter</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{numberOfFiles}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">Filer</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileHeaderText} variant="h4">{currentCompany.numOfUsers || 0}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography className={classes.smallTileFooterText} variant="subtitle1">Brugere</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{ height: "calc(100% - 66px)", width: "100%", flexGrow: 1, overflow: "scroll" }}>
                                                        <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                            <Grid item style={{ height: "calc(100% - 20px)", width: "100%", paddingBottom: 8 }}>
                                                                <Paper className={classes.paper}>
                                                                    <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                                        <Grid item>
                                                                            <Grid container direction="row" justify="space-between">
                                                                                <Grid item>
                                                                                    <Typography variant="h6">Seneste aktiviteter</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <img src={process.env.PUBLIC_URL + '/data/images/Activityicon.png'} style={{ height: "1.8rem", width: "1.8rem" }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item style={{ height: "calc(100% - 36px)", width: "100%" }}>
                                                                            <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                                                {activities.length > 0
                                                                                    ?
                                                                                    <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", width: "100%", overflow: "scroll", paddingRight: 12, paddingLeft: 12, marginTop: 8 }}>
                                                                                        <Activities items={activities} {...this.props} handleOpenDVC={this.handleDocumentValuesContainerModalOpen} setItemsModified={this.setItemsModified} />
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item>
                                                                                        <Typography style={{ marginTop: 8, paddingLeft: 12 }}>Der er ingen aktiviteter</Typography>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item style={{ height: 20 }}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/data/images/infographics2_766x20.png'}
                                                                    alt="infographics2"
                                                                    style={{ height: "100%", width: "100%", boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)" }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} style={{ height: "100%", width: "100%" }}>
                                        <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                            <Grid item style={{ height: "calc(33.33% - 16px)", width: "100%", marginBottom: 16 }}>
                                                <Paper className={classes.paper}>
                                                    <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                        <Grid item style={{ width: "100%" }}>
                                                            <Grid container direction="row" justify="space-between" style={{ width: "100%" }}>
                                                                <Grid item>
                                                                    <Typography variant="h6">Overskredne frister og påmindelser ({exceededItems.reminders.length + exceededItems.exceeded.length})</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Alarm style={{ fontSize: "1.8rem", color: "crimson" }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{ height: "calc(100% - 36px)", width: "100%" }}>
                                                            <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                                {exceededItems.exceeded.length > 0 || exceededItems.reminders.length > 0
                                                                    ?
                                                                    <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", width: "100%", overflow: "scroll", paddingRight: 12, paddingLeft: 12 }}>
                                                                        <ExceededDeadlines items={exceededItems} handleDelete={this.handleDeleteExceededItem} {...this.props} handleOpenDVC={this.handleDocumentValuesContainerModalOpen} setItemsModified={this.setItemsModified} />
                                                                    </Grid>
                                                                    :
                                                                    <Grid item style={{ marginTop: 8, paddingLeft: 12 }}>
                                                                        <Typography>Der er ingen overskredne frister</Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item style={{ height: "33.33%", width: "100%" }}>
                                                <Paper className={classes.paper}>
                                                    <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                        <Grid item style={{ width: "100%" }}>
                                                            <Grid container direction="row" justify="space-between" style={{ width: "100%" }}>
                                                                <Grid item>
                                                                    <Typography variant="h6">Kommende frister og påmindelser ({upcomingItems.reminders.length + upcomingItems.upcoming.length})</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Alarm style={{ fontSize: "1.8rem", color: "limegreen" }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{ height: "calc(100% - 36px)", width: "100%" }}>
                                                            <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                                {upcomingItems.upcoming.length > 0 || upcomingItems.reminders.length > 0
                                                                    ?
                                                                    <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", overflow: "scroll", paddingRight: 12, paddingLeft: 12, width: "100%" }}>
                                                                        <UpcomingDeadlines items={upcomingItems} handleDelete={this.handleDeleteUpcomingItem} {...this.props} handleOpenDVC={this.handleDocumentValuesContainerModalOpen} setItemsModified={this.setItemsModified} />
                                                                    </Grid>
                                                                    :
                                                                    <Grid item>
                                                                        <Typography style={{ marginTop: 8, paddingLeft: 12 }}>Der er ingen kommende frister</Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item style={{ height: "calc(33.33% - 16px)", width: "100%", marginTop: 16 }}>
                                                <Paper className={classes.paper}>
                                                    <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                        <Grid item style={{ width: "100%" }}>
                                                            <Grid container direction="row" justify="space-between" style={{ width: "100%" }}>
                                                                <Grid item>
                                                                    <Typography variant="h6">Manglende dokumenter ({missingDocuments.length})</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Icon style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", fontSize: "1.8rem", background: "crimson" }}>
                                                                        <Attachment style={{ color: "white", fontSize: 18 }} />
                                                                    </Icon>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{ height: "calc(100% - 36px)", width: "100%" }}>
                                                            <Grid container direction="column" style={{ height: "100%", width: "100%" }}>
                                                                {missingDocuments.length > 0
                                                                    ?
                                                                    <Grid item className={classes.deadlinesContainer} style={{ height: "calc(100% - 12px)", overflow: "scroll", paddingRight: 12, paddingLeft: 12, width: "100%" }}>
                                                                        <MissingDocuments items={missingDocuments} {...this.props} handleOpenDVC={this.handleDocumentValuesContainerModalOpen} setItemsModified={this.setItemsModified} />
                                                                    </Grid>
                                                                    :
                                                                    <Grid item>
                                                                        <Typography style={{ marginTop: 8, paddingLeft: 12 }}>Der er ingen manglende dokumenter</Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                                :
                                (selectedTabIdx === 1
                                    ?
                                    <div style={{ height: "100%", width: "100%", padding: 8 }}>
                                        <div>
                                            <img
                                                src={currentCompany.economySrc}
                                                alt="infographicsEconomy"
                                                style={{ display: "block", height: "auto", width: "100%", boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)" }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div style={{ height: "100%", width: "100%", padding: 8 }}>
                                        <img
                                            src={currentCompany.customersSrc}
                                            alt="infographicsCustomers"
                                            style={{ display: "block", height: "auto", width: "100%", boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)" }}
                                        />
                                    </div>
                                )
                            }
                        </Fragment>
                    }
                </Grid>

                {isDocumentModalOpen &&
                    <DocumentModal
                        isOpen={isDocumentModalOpen}
                        handleModalClose={this.handleDocumentModalClose}
                        newAttachedFile={newAttachedFile}
                    />
                }

                {isDocumentValuesContainerModalOpen &&
                    <Dialog open={isDocumentValuesContainerModalOpen} onClose={e => this.handleDocumentValuesContainerModalClose(e)} classes={{ paperScrollPaper: classes.paperScrollPaper }}>
                        <DocumentValuesContainer
                            handleDocumentModalOpen={this.handleDocumentModalOpen}
                            selectedFileType={currentFile.type}
                            handleModalClose={this.handleDocumentValuesContainerModalClose}
                            handleSetNewAttachedFile={this.handleSetNewAttachedFile}
                            isDirectLink={true}
                            fromDashboard={true}
                            refreshDashboardItems={this.setup}
                        />
                    </Dialog>
                }
            </Fragment>
        )
    }
}

const ExceededDeadlines = (props) => {
    const { items, currentCompany, setItemsModified, history, handleOpenDVC, setCurrentFile, setSearchValue, setSearchResults, handleDelete, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left"
            }
        }
    })

    const classes = useStyles()

    const handleClick = (idx, groupedItems) => {
        const newItems = { ...items }

        const removedItem = groupedItems.splice(idx, 1)[0]
        removedItem.isRemovedExceededDeadlines = true
        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(removedItem.name)}/${escapeForwardSlash(removedItem.mainFolder)}/${escapeForwardSlash(removedItem.folder)}`, removedItem)

        if (removedItem.isReminder) {
            for (const itemIdx in newItems.reminders) {
                if (newItems.reminders[itemIdx].name == removedItem.name) {
                    newItems.reminders.splice(itemIdx, 1)
                    break
                }
            }
        } else {
            for (const itemIdx in newItems.exceeded) {
                if (newItems.exceeded[itemIdx].name == removedItem.name) {
                    newItems.exceeded.splice(itemIdx, 1)
                    break
                }
            }
        }

        handleDelete(newItems)
    }

    const handleNameClick = (item) => {
        setItemsModified("exceededDeadlines")
        handleOpenDVC(item)
    }

    const currentDate = moment().format("YYYY-MM-DD")
    const groupedItems = [...items.exceeded, ...items.reminders].sort((a, b) => moment(a.sortDate) < moment(b.sortDate) ? 1 : -1)

    return (
        <Grid container direction="column">
            {groupedItems.map((item, idx) => {
                let dateToDisplay = item.isReminder ? moment(item.nextReminder).format("DD.MM.YYYY") : moment(item.dateDue).format("DD.MM.YYYY")
                let daysInParenthesis = (item.isReminder ? () => {
                    const dateDiff = moment(item.nextReminder).diff(currentDate, "days")
                    if (dateDiff == -1) {
                        return "i går"
                    } else {
                        return `${Math.abs(dateDiff)}d`
                    }
                }
                    :
                    () => {
                        const dateDiff = moment(item.dateDue).diff(currentDate, "days")
                        if (dateDiff == -1) {
                            return "i går"
                        } else {
                            return `${Math.abs(dateDiff)}d`
                        }
                    })()
                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={4} style={{ display: "flex" }}>
                                <Typography>{`${dateToDisplay}`}</Typography>
                                <Typography style={{ color: "crimson", marginLeft: 4 }}>{`(${daysInParenthesis})`}</Typography>
                                <Typography style={{ marginLeft: 4 }}>{`${!item.isReminder ? "Frist" : ""}`}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleNameClick(item)}
                                    style={{ width: "100%" }}
                                >
                                    <Typography style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.name}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton
                                    onClick={() => handleClick(idx, groupedItems)}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const UpcomingDeadlines = (props) => {
    const { items, currentCompany, history, setItemsModified, handleOpenDVC, setCurrentFile, setSearchValue, setSearchResults, handleDelete, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left"
            }
        }
    })

    const classes = useStyles()

    const handleClick = (idx, groupedItems) => {
        const newItems = { ...items }

        const removedItem = groupedItems.splice(idx, 1)[0]
        removedItem.isRemovedUpcomingDeadlines = true
        api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(removedItem.name)}/${escapeForwardSlash(removedItem.mainFolder)}/${escapeForwardSlash(removedItem.folder)}`, removedItem)

        if (removedItem.isReminder) {
            for (const itemIdx in newItems.reminders) {
                if (newItems.reminders[itemIdx].name == removedItem.name) {
                    newItems.reminders.splice(itemIdx, 1)
                    break
                }
            }
        } else {
            for (const itemIdx in newItems.upcoming) {
                if (newItems.upcoming[itemIdx].name == removedItem.name) {
                    newItems.upcoming.splice(itemIdx, 1)
                    break
                }
            }
        }

        handleDelete(newItems)
    }

    const handleNameClick = (item) => {
        setItemsModified("upcomingDeadlines")
        handleOpenDVC(item)
    }

    const currentDate = moment().format("YYYY-MM-DD")
    const groupedItems = [...items.upcoming, ...items.reminders].sort((a, b) => moment(a.sortDate) > moment(b.sortDate) ? 1 : -1)

    return (
        <Grid container direction="column">
            {groupedItems.map((item, idx) => {
                let dateToDisplay = item.isReminder ? moment(item.nextReminder).format("DD.MM.YYYY") : moment(item.dateDue).format("DD.MM.YYYY")
                let daysInParenthesis = (item.isReminder ? () => {
                    const dateDiff = moment(item.nextReminder).diff(currentDate, "days")
                    if (dateDiff == 0) {
                        return "i dag"
                    } else if (dateDiff == 1) {
                        return "i morgen"
                    } else {
                        return `${dateDiff}d`
                    }
                }
                    :
                    () => {
                        const dateDiff = moment(item.dateDue).diff(currentDate, "days")
                        if (dateDiff == 0) {
                            return "i dag"
                        } else if (dateDiff == 1) {
                            return "i morgen"
                        } else {
                            return `${dateDiff}d`
                        }
                    })()

                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={4} style={{ display: "flex" }}>
                                <Typography>{`${dateToDisplay}`}</Typography>
                                <Typography style={{ color: "crimson", marginLeft: 4 }}>{`(${daysInParenthesis})`}</Typography>
                                <Typography style={{ marginLeft: 4 }}>{`${!item.isReminder ? "Frist" : ""}`}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleNameClick(item)}
                                    style={{ width: "100%" }}
                                >
                                    <Typography style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.name}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton
                                    onClick={() => handleClick(idx, groupedItems)}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const MissingDocuments = (props) => {
    const { items, handleOpenDVC, currentCompany, setItemsModified, setCurrentFile, history, setSearchValue, setSearchResults, handleDelete, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left",
                display: "flex",
                justifyContent: "left"
            }
        }
    })

    const classes = useStyles()

    const handleNameClick = (item) => {
        setItemsModified("missingDocuments")
        handleOpenDVC(item)
    }

    return (
        <Grid container direction="column">
            {items.map((item, idx) => {
                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{moment(item.missingDocumentDate).format("DD.MM.YYYY")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleNameClick(item)}
                                    style={{ width: "100%", display: "flex", justifyContent: "left" }}
                                >
                                    {item.name} | {item.mainFolder}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const Activities = (props) => {
    const { items, handleOpenDVC, setItemsModified, currentCompany, history, setCurrentFile, setSearchValue, setSearchResults, getSearchResults } = props

    const useStyles = makeStyles(theme => {
        return {
            eventButtonRoot: {
                textTransform: "none",
                display: "flex",
                justifyContent: "left"
            },
            eventButtonSpan: {
                textAlign: "left"
            }
        }
    })

    const classes = useStyles()

    const handleClick = (item) => {
        setSearchValue(item.event)
        setSearchResults(getSearchResults(item.event))
    }

    return (
        <Grid container direction="column">
            {items.slice(0, 100).map((item, idx) => {
                return (
                    <Grid item key={`${item.name}_${idx}`} xs={12} style={{ width: "100%", height: "calc(100% - 16px)" }}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={3} md={2}>
                                <Typography>{moment(item.date).format("DD.MM")}</Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Button
                                    classes={{ root: classes.eventButtonRoot, label: classes.eventButtonSpan }}
                                    onClick={() => handleClick(item)}
                                    style={{ width: "100%" }}
                                >
                                    <Typography style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.event}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={3} md={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Typography>{item.status}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const styles = theme => ({
    root: {
        width: "unset",
        margin: "unset",
        height: "calc(100vh - 150px)",
        padding: theme.spacing(4),
        position: "relative"
    },
    plainTile: {
        height: "100%"
    },
    paper: {
        height: "100%",
        width: "100%",
        padding: 12,
        paddingBottom: 0
    },
    deadlinesContainer: {
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    smallTileHeaderText: {
        fontSize: "1.6rem",
        '@media (min-width:1500px)': {
            fontSize: '2.125rem',
        },
    },
    smallTileFooterText: {
        fontSize: "0.715rem",
        '@media (min-width:1500px)': {
            fontSize: '0.9rem',
        },
    },
    rootTabs: {
        height: 24,
        minHeight: 24
    },
    rootTab: {
        minHeight: 24,
        height: 24,
        paddingTop: 2,
        color: "grey"
    },
    paperScrollPaper: {
        maxHeight: "calc(100% - 58px)"
    }
})

const mapStateToProps = ({ state }) => {
    return {
        currentCompany: state.currentCompany,
        mainFolders: state.mainFolders,
        loggedInUser: state.loggedInUser,
        currentMainFolder: state.currentMainFolder,
        currentFolder: state.currentFolder,
        currentFile: state.currentFile
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setDeadlineItems, setSearchValue, setSearchResults, setCurrentFile, setCurrentSubFolder }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Home))