import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { IconButton, Paper, Button, Grid, Modal, Typography, CircularProgress } from '@material-ui/core'
import { Close, Share, GetApp, Create, ZoomIn, ZoomOut } from "@material-ui/icons"
import { Document, Page, pdfjs } from 'react-pdf'
import api, { escapeForwardSlash } from "../api"
import { save } from "save-file"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocumentModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            canvasWidth: 0,
            canvasHeight: 0,
            numberOfPages: 0,
            fileSrc: null
        }

        this.pdfWrapper = React.createRef()
    }

    componentDidMount() {
        const { currentCompany, currentFile } = this.props

        this.setContainerSize(0.8)
        console.log(currentFile)
        api.get(`/${escapeForwardSlash(currentCompany.name)}/fileSrc/${currentFile.src}`)
            .then(res => {
                if (Object.keys(res.data).length > 0) {
                    if (res.data.row) {
                        this.setState({ fileSrc: res.data.row.src })
                    }
                }
            })    
    }

    componentDidUpdate(prevProps) {
        const { currentCompany, currentFile, newAttachedFile } = this.props

        if (prevProps.newAttachedFile == null && newAttachedFile && newAttachedFile.src != null) {
            api.get(`/${escapeForwardSlash(currentCompany.name)}/fileSrc/${newAttachedFile ? newAttachedFile.src : currentFile.src}`)
                .then(res => {
                    if (Object.keys(res.data).length > 0) {
                        if (res.data.row) {
                            this.setState({ fileSrc: res.data.row.src })
                        }
                    }
                })
        }
    }

    handleLoadSuccess = (pdf) => {
        this.setState({ numberOfPages: pdf.numPages })
    }

    setContainerSize = (percentage) => {
        this.setState({
            canvasHeight: 1684 * percentage,
            currentPercentage: percentage
        })
    }

    handleZoomIn = () => {
        const { currentPercentage } = this.state
        const newPercentage = currentPercentage >= 0.9 ? 1 : currentPercentage + 0.1
        this.setContainerSize(newPercentage)
    }

    handleZoomOut = () => {
        const { currentPercentage } = this.state
        const newPercentage = currentPercentage <= 0.6 ? 0.5 : currentPercentage - 0.1
        this.setContainerSize(newPercentage)
    }

    render() {
        const { classes, isOpen, handleModalClose, currentFile, newAttachedFile } = this.props
        const { canvasHeight, canvasWidth, fileSrc, numberOfPages } = this.state

        let fileToView = currentFile
        if (newAttachedFile) {
            fileToView = newAttachedFile
        }

        return (
            <Modal open={isOpen} onClose={handleModalClose} classes={{ root: classes.modalRoot }}>
                <div className={classes.root}>
                    {fileToView && 
                        <Fragment>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Grid item>
                                    <IconButton onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="h6">
                                        {fileToView && fileToView.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" style={{ height: 64 }} justify="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Button variant="outlined" onClick={async function () { await save(fileSrc.data, currentFile.uploadedName) }}>
                                                Download
                                                <GetApp style={{ marginLeft: 8 }} />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined">
                                                Share
                                                <Share style={{ marginLeft: 8 }} />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined">
                                                Underskriv
                                                <Create style={{ marginLeft: 8 }} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.paperContainer} direction="column" justify="flex-end">
                                <Grid item style={{ height: "100%", width: "100%" }}>
                                    <Paper className={classes.paper}>
                                        <Grid container className={classes.documentContainer} direction="column" justify="center" alignContent="center">
                                            <Grid item style={{ margin: 8, marginBottom: 0 }}>
                                                <div style={{ display: "flex", justifyContent: "center", borderBottom: "1px solid rgba(255, 255, 255, 0.2)", background: "#3b4d56", boxShadow: "inset 0px -5px 20px -6px rgba(0,0,0,0.15)", position: "absolute", top: 0, left: "50%", transform: "translateX(-50%)", width: "100%", zIndex: 1200 }}>
                                                    <IconButton size="small" style={{ color: "white" }} onClick={this.handleZoomIn}>
                                                        <ZoomIn />
                                                    </IconButton>
                                                    <IconButton size="small" style={{ color: "white" }} onClick={this.handleZoomOut}>
                                                        <ZoomOut />
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                            <Grid item style={{ margin: 8, marginTop: 30, overflow: "scroll", height: "calc(100% - 46px)" }}>
                                                <Paper>
                                                    <Document
                                                        file={fileSrc}
                                                        onLoadSuccess={(pdf) => this.handleLoadSuccess(pdf)}
                                                        loading={<div style={{ height: "100%", width: "100%" }}><CircularProgress style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} /></div>}
                                                    >
                                                        {createPageRows(numberOfPages, canvasHeight, canvasWidth, classes)}
                                                    </Document>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Fragment>
                    }
                </div>
            </Modal>
        )
    }
}

const createPageRows = (numberOfPages, canvasHeight, canvasWidth, classes) => {
    const rows = []
    for (let i = 0; i < numberOfPages; i++) {
        rows.push(<Page key={i} className={classes.page} width={canvasWidth} height={canvasHeight} pageIndex={i} renderAnnotationLayer={false} renderTextLayer={false} />)
    }
    return rows
}

const styles = theme => ({
    root: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 'none',
        height: "calc(100% - 16px)"
    },
    modalRoot: {
        overflow: "scroll",
        height: "100%"
    },
    paperContainer: {
        height: "calc(100% - 144px)"
    },
    paper: {
        margin: theme.spacing(3),
        padding: theme.spacing(3),
        height: "calc(100% - 48px)",
    },
    loadingPaper: {
        height: "100%",
        position: "relative",
        padding: theme.spacing(3),
        margin: theme.spacing(3)
    },
    documentContainer: {
        height: "100%",
        background: "#263238",
        position: "relative",
        overflow: "hidden"
    },
    page: {

    }
})

const mapStateToProps = ({ state }) => {
    return {
        currentFile: state.currentFile,
        currentCompany: state.currentCompany
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({ setMainFolders, setCurrentMainFolder, setCurrentFolder }, dispatch)
// }

export default compose(
    withStyles(styles),
    connect(mapStateToProps, null),
)(withRouter(DocumentModal))