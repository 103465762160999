import React, { Fragment } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import md5 from "md5"
import { withRouter, Redirect } from "react-router-dom"
import InformationSnackbar from "../components/InformationSnackbar"
import { setCompanies, setLoggedInUser } from "../actions/actions"
import { Grid, AppBar, Paper, Toolbar, Typography, Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, List, Chip, FormControl, InputLabel, Select, MenuItem, TextField, ListItem, ListItemText, Avatar, ListItemAvatar, ButtonBase, Drawer, InputBase, InputAdornment, IconButton, Button, Divider } from '@material-ui/core'
import api, { escapeForwardSlash } from "../api"
const qs = require('qs');

class Admin extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newCompanyName: "",
            newUserUsername: "",
            newUserPassword: "",
            newUserEmail: "",
            newUserFirstName: "",
            newUserLastName: "",
            informationSnackbarMessage: "",
            isInformationSnackbarOpen: false,
            usersList: null,
            selectedUsers: [],
            selectedCompanies: [],
            selectedUserForDelete: "",
            selectedCompanyForDelete: "",
            confirmDeleteCompanyDialog: false
        }
    }

    componentDidMount() {
        api.get("/user")
            .then(res => {
                this.setState({ usersList: res.data.rows.map(user => user.username) })
            })
    }

    createUser = () => {
        const { newUserUsername, newUserPassword, newUserFirstName, newUserLastName, selectedCompanies, usersList } = this.state

        if (newUserUsername === "" || newUserPassword === "" || newUserFirstName === "" || newUserLastName === "") {
            this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: "Alle brugeroplysninger skal udfyldes" })
            return
        }

        const newUser = {
            username: newUserUsername,
            password: md5(newUserPassword),
            firstname: newUserFirstName,
            lastname: newUserLastName,
            companies: selectedCompanies.join(",")
        }

        api.post("/user", newUser)
            .then(res => {
                if (res.data.code == 1001) {
                    this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: `En bruger med navnet ${newUserUsername} findes allerede` })
                    return
                } else {
                    this.setState({ 
                        isInformationSnackbarOpen: true, 
                        informationSnackbarMessage: `Bruger ${newUserUsername} oprettet`,
                        newUserUsername: "", 
                        newUserPassword: "", 
                        newUserFirstName: "", 
                        newUserLastName: "",
                        selectedCompanies: [],
                        usersList: [...usersList, newUserUsername]
                    })
                }
            })
    }

    createCompany = () => {
        const { setCompanies, setLoggedInUser, loggedInUser } = this.props
        const { newCompanyName, selectedUsers } = this.state

        if (newCompanyName === "" || selectedUsers.length === 0) {
            this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: "Virksomhedsnavn skal være udfyldt og mindst 1 bruger skal være valgt" })
            return
        }

        api.post(`/company/new`, { name: newCompanyName, users: selectedUsers })
            .then(res => {
                if (res.data.code == 19) {
                    this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: `En virksomhed med navnet ${newCompanyName} findes allerede` })
                    return
                }
                api.get("/company")
                    .then(res2 => {
                        const userCompanies = [...loggedInUser.companies.split(","), newCompanyName]
                        let companies = res2.data.rows.filter(row => userCompanies.includes(row.name)).sort((a, b) => (b.name < a.name ? 1 : (a.name < b.name ? -1 : 0))).map(company => company)
                        api.get(`/company/fileSrc/all/images`, {
                            params: {
                                images: companies.map(company => [company.logo, company.infographics1]).flat()
                            },
                            paramsSerializer: params => {
                                return qs.stringify(params)
                            }
                        })
                            .then(res3 => {
                                const newCompanies = companies.map(newCompany => {
                                    return {
                                        ...newCompany, logoSrc: res3.data.rows.filter(logoSrc => { return logoSrc.name == newCompany.logo }).length > 0 ? res3.data.rows.filter(logoSrc => { return logoSrc.name == newCompany.logo })[0].src : null,
                                        infographics1Src: res3.data.rows.filter(infographics1Src => { return infographics1Src.name == newCompany.infographics1 }).length > 0 ? res3.data.rows.filter(infographics1Src => { return infographics1Src.name == newCompany.infographics1 })[0].src : null
                                    }
                                })
                                setLoggedInUser({ ...loggedInUser, companies: userCompanies.join(",") })
                                setCompanies(newCompanies)
                                this.setState({ 
                                    isInformationSnackbarOpen: true, 
                                    informationSnackbarMessage: `Virksomhed ${newCompanyName} oprettet`,
                                    newCompanyName: "",
                                    selectedUsers: []
                                })
                            })
                    })

            })
    }

    deleteCompany = () => {
        const { loggedInUser, setLoggedInUser, setCompanies, companies } = this.props
        const { selectedCompanyForDelete } = this.state

        if (selectedCompanyForDelete === "") {
            this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: "Der er ikke valgt en virksomhed endnu" })
            return
        }

        api.delete(`/company/${escapeForwardSlash(selectedCompanyForDelete)}/${escapeForwardSlash(loggedInUser.username)}`)
            .then(res => {
                this.setState({ selectedCompanyForDelete: "", isInformationSnackbarOpen: true, informationSnackbarMessage: `Virksomhed ${selectedCompanyForDelete} slettet`, }, () => {
                    setLoggedInUser({ ...loggedInUser, companies: res.data.loggedInUserCompanies })
                    setCompanies(companies.filter(company => res.data.loggedInUserCompanies.split(",").includes(company.name)))
                })
            })
    }

    deleteUser = () => {
        const { selectedUserForDelete, usersList } = this.state

        if (selectedUserForDelete === "") {
            this.setState({ isInformationSnackbarOpen: true, informationSnackbarMessage: "Der er ikke valgt en bruger endnu" })
            return
        }
        

        api.delete(`/user/${escapeForwardSlash(selectedUserForDelete)}`)
            .then(res => {
                const newUsersList = [...usersList]
                newUsersList.splice(newUsersList.indexOf(selectedUserForDelete, 1), 1)

                this.setState({ 
                    selectedUserForDelete: "", 
                    isInformationSnackbarOpen: true, 
                    informationSnackbarMessage: `Bruger ${selectedUserForDelete} slettet`,
                    usersList: newUsersList
                })
            })
    }

    handleDialogOpen = (dialog) => {
        this.setState({ [dialog]: true })
    }

    handleDialogClose = (dialog) => {
        this.setState({ [dialog]: false })
    }

    handleInformationSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isInformationSnackbarOpen: false })
    }

    handleSelectUser = (e) => {
        const newSelectedUsers = [...this.state.selectedUsers, e.target.value]
        this.setState({ selectedUsers: newSelectedUsers })
    }

    handleSelectCompany = (e) => {
        const newSelectedCompanies = [...this.state.selectedCompanies, e.target.value]
        this.setState({ selectedCompanies: newSelectedCompanies })
    }

    handleUserChipRemove = (idx) => {
        const { selectedUsers } = this.state

        const newSelectedUsers = [...selectedUsers]
        newSelectedUsers.splice(idx, 1)

        this.setState({ selectedUsers: newSelectedUsers })
    }

    handleCompanyChipRemove = (idx) => {
        const { selectedCompanies } = this.state

        const newSelectedCompanies = [...selectedCompanies]
        selectedCompanies.splice(idx, 1)

        this.setState({ selectedCompanies: selectedCompanies })
    }

    handleTextFieldChange = (event) => {
        const { company } = this.state
        const field = event.target.name
        const newValue = event.target.value

        this.setState({ [field]: newValue })
    }
    
    handleSelectCompanyForDelete = (e) => {
        this.setState({ selectedCompanyForDelete: e.target.value })
    }

    handleSelectUserForDelete = (e) => {
        this.setState({ selectedUserForDelete: e.target.value })
    }

    render() {
        const { classes, loggedInUser, companies, currentCompany } = this.props
        const { newCompanyName, newUserUsername, selectedCompanies, selectedUserForDelete, newUserFirstName, newUserLastName, newUserEmail, newUserPassword, newCompanySelectedUser, confirmDeleteCompanyDialog, selectedCompanyForDelete, usersList, selectedUsers, informationSnackbarMessage, isInformationSnackbarOpen } = this.state

            return (
            <div className={classes.root}>
                <Grid container direction="column" alignItems="center" justify="center" spacing={4}>
                    <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={8} style={{ height: "100%" }}>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Grid container direction="column" justify="space-between" alignItems="center" style={{ height: "100%", width: "100%" }}>
                                        <Grid item>
                                            <Typography variant="h4">
                                                Opret ny bruger
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" style={{ width: "100%", paddingLeft: 24, paddingRight: 24, margin: 0 }} spacing={2}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField style={{ width: "100%" }} onChange={this.handleTextFieldChange} value={newUserUsername} variant="outlined" name="newUserUsername" label="Brugernavn" InputLabelProps={{ shrink: true, className: classes.textFieldLabel }} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField style={{ width: "100%" }} onChange={this.handleTextFieldChange} value={newUserPassword} type="password" variant="outlined" name="newUserPassword" label="Password" InputLabelProps={{ shrink: true, className: classes.textFieldLabel }} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField style={{ width: "100%" }} onChange={this.handleTextFieldChange} value={newUserFirstName} variant="outlined" name="newUserFirstName" label="Fornavn" InputLabelProps={{ shrink: true, className: classes.textFieldLabel }} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField style={{ width: "100%" }} onChange={this.handleTextFieldChange} value={newUserLastName} variant="outlined" name="newUserLastName" label="Efternavn" InputLabelProps={{ shrink: true, className: classes.textFieldLabel }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    {companies
                                                        &&
                                                        <FormControl style={{ width: "100%" }}>
                                                            <InputLabel id="user-select-label" style={{ color: "royalblue" }}>
                                                                Virksomheder
                                                            </InputLabel>
                                                            <Select
                                                                id="company-select"
                                                                labelId="company-select-label"
                                                                value=""
                                                                displayEmpty
                                                                onChange={this.handleSelectCompany}
                                                                renderValue={() => companies.filter(company => !selectedCompanies.includes(company.name)).length == 0 ? "Alle virksomheder valgt" : ""}
                                                                disabled={companies.filter(company => !selectedCompanies.includes(company.name)).length == 0}
                                                            >
                                                                {companies.filter(company => !selectedCompanies.includes(company.name)).map((company, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={company.name}>{company.name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item style={{ height: 96 }}>
                                                    <Grid container direction="row" justify="center" spacing={1} style={{ height: "100%", overflow: "scroll" }}>
                                                        {selectedCompanies.length > 0
                                                            ?
                                                            selectedCompanies.map((companyName, idx) => {
                                                                let icon
                                                                return (
                                                                    <Grid item key={`${companyName}_${idx}`}>
                                                                        <Chip
                                                                            key={`${idx}_${companyName}`}
                                                                            icon={icon}
                                                                            label={companyName}
                                                                            onDelete={() => this.handleCompanyChipRemove(idx)}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                            :
                                                            <Grid item>
                                                                <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                    <Grid item style={{ marginBottom: 24 }}>
                                                                        <Typography variant="subtitle1" style={{ color: "slategrey" }}>Ingen virksomheder valgt endnu</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" style={{ color: "white" }} onClick={this.createUser}>
                                                Opret
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Grid container direction="column" justify="space-between" alignItems="center" style={{ height: "100%", width: "100%" }}>
                                        <Grid item>
                                            <Typography variant="h4">
                                                Slet bruger
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" style={{ width: "100%", paddingLeft: 24, paddingRight: 24, margin: 0 }} spacing={2}>
                                                <Grid item>
                                                    {usersList
                                                        &&
                                                        <FormControl style={{ width: "100%" }}>
                                                            <InputLabel id="user-select-delete-label" style={{ color: "royalblue" }}>
                                                                Brugere
                                                            </InputLabel>
                                                            <Select
                                                                id="user-select-delete"
                                                                labelId="user-select-delete-label"
                                                                value={selectedUserForDelete}
                                                                onChange={this.handleSelectUserForDelete}
                                                            >
                                                                {usersList.filter(username => username !== loggedInUser.username).map((username, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={username}>{username}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" style={{ color: "white", background: "crimson" }} onClick={this.deleteUser}>
                                                Slet
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={8} style={{ height: "100%" }}>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Grid container direction="column" justify="space-between" alignItems="center" style={{ height: "100%", width: "100%" }}>
                                        <Grid item>
                                            <Typography variant="h4">
                                                Opret ny virksomhed
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" style={{ width: "100%", paddingLeft: 24, paddingRight: 24, margin: 0 }} spacing={2}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <TextField style={{ width: "100%" }} onChange={this.handleTextFieldChange} value={newCompanyName} variant="outlined" name="newCompanyName" label="Virksomhedens navn" InputLabelProps={{ shrink: true, className: classes.textFieldLabel }} />
                                                </Grid>
                                                <Grid item>
                                                    {usersList
                                                        &&
                                                        <FormControl style={{ width: "100%" }}>
                                                            <InputLabel id="user-select-label" style={{ color: "royalblue" }}>
                                                                Brugere
                                                            </InputLabel>
                                                            <Select
                                                                id="user-select"
                                                                labelId="user-select-label"
                                                                value=""
                                                                displayEmpty
                                                                onChange={this.handleSelectUser}
                                                                renderValue={() => usersList.filter(username => !selectedUsers.includes(username)).length == 0 ? "Alle brugere valgt" : ""}
                                                                disabled={usersList.filter(username => !selectedUsers.includes(username)).length == 0}
                                                            >
                                                                {usersList.filter(username => !selectedUsers.includes(username)).map((user, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={user}>{user}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item style={{ height: 96 }}>
                                                    <Grid container direction="row" justify="center" spacing={1} style={{ height: "100%", overflow: "scroll" }}>
                                                        {selectedUsers.length > 0
                                                            ?
                                                            selectedUsers.map((username, idx) => {
                                                                let icon
                                                                return (
                                                                    <Grid item key={`${username}_${idx}`}>
                                                                        <Chip
                                                                            key={`${idx}_${username}`}
                                                                            icon={icon}
                                                                            label={username}
                                                                            onDelete={() => this.handleUserChipRemove(idx)}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                            :
                                                            <Grid item>
                                                                <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                                                                    <Grid item style={{ marginBottom: 24 }}>
                                                                        <Typography variant="subtitle1" style={{ color: "slategrey" }}>Ingen brugere valgt endnu</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" style={{ color: "white" }} onClick={this.createCompany}>
                                                Opret
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Grid container direction="column" justify="space-between" alignItems="center" style={{ height: "100%", width: "100%" }}>
                                        <Grid item>
                                            <Typography variant="h4">
                                                Slet virksomhed
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" style={{ width: "100%", paddingLeft: 24, paddingRight: 24, margin: 0 }} spacing={2}>
                                                <Grid item>
                                                    {companies
                                                        &&
                                                        <FormControl style={{ width: "100%" }}>
                                                            <InputLabel id="user-select-delete-label" style={{ color: "royalblue" }}>
                                                                Virksomheder
                                                            </InputLabel>
                                                            <Select
                                                                id="company-select-delete"
                                                                labelId="company-select-delete-label"
                                                                value={selectedCompanyForDelete}
                                                                onChange={this.handleSelectCompanyForDelete}
                                                            >
                                                                {companies.filter(company => company.name !== currentCompany.name).map((company, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={company.name}>{company.name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" style={{ color: "white", background: "crimson" }} onClick={() => this.handleDialogOpen("confirmDeleteCompanyDialog")}>
                                                Slet
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {confirmDeleteCompanyDialog &&
                    <Dialog open={confirmDeleteCompanyDialog} onClose={() => this.handleDialogClose("confirmDeleteCompanyDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Virksomheden og alt data knyttet til den vil blive slettet</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)", textAlign: "center" }}>
                                Bekræft at du ønsker at fortsætte
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "space-evenly" }}>
                            <Button onClick={() => this.handleDialogClose("confirmDeleteCompanyDialog")} color="primary">
                                Annullér
                            </Button>
                            <Button onClick={() => { this.handleDialogClose("confirmDeleteCompanyDialog"); this.deleteCompany() }} color="primary">
                                Fortsæt
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                <InformationSnackbar handleBarClose={this.handleInformationSnackbarClose} isBarOpen={isInformationSnackbarOpen} message={informationSnackbarMessage} topMargin={94} />
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        width: "unset",
        margin: "unset",
        padding: theme.spacing(4),
        position: "relative"
    },
    paper: {
        height: 440,
        width: 360,
        padding: theme.spacing(2)
    },
    textFieldLabel: {
        color: "royalblue"
    }
})

const mapStateToProps = ({ state }) => {
    return {
        mainFolders: state.mainFolders,
        companies: state.companies,
        isLoading: state.isLoading,
        loggedInUser: state.loggedInUser,
        currentCompany: state.currentCompany
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setCompanies, setLoggedInUser }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Admin))