import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Typography, Button, IconButton, Snackbar, Card, CircularProgress, CardContent, Menu, MenuItem, TextField, InputAdornment } from '@material-ui/core'
import { Image, Folder, Close, DeleteForever } from "@material-ui/icons"
import { fileTypes } from "../enums"
import Header from "../components/Header"
import Footer from "../components/Footer"
import DocumentValuesContainer from "../components/DocumentValuesContainer"
import DocumentModal from "../components/DocumentModal"
import Dropzone from "../components/Dropzone"
import moment from "moment"
import api, { escapeForwardSlash } from "../api"
import { setUploadedFiles, setCurrentFile, setSearchValue, setCurrentFileIdx } from "../actions/actions"

class Upload extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            popperAnchorEl: null,
            isDocumentModalOpen: false,
        }
    }

    componentDidMount() {
        const { uploadedFiles, setUploadedFiles, setCurrentFile } = this.props
        const newUploadedFilesArray = Object.values(uploadedFiles).filter(file => !file.isArchived)
        const newUploadedFiles = {}

        for (const file of newUploadedFilesArray) {
            newUploadedFiles[file.name] = file
        }
        setCurrentFile(null)
        setUploadedFiles(newUploadedFiles)
    }

    componentWillUnmount() {
        const { setCurrentFile, setSearchValue, setCurrentFileIdx } = this.props
        setCurrentFile(null)
        setCurrentFileIdx(null)
    }

    handleAddNewFile = (file) => {
        const { setUploadedFiles, uploadedFiles, setCurrentFile } = this.props
        const newUploadedFiles = { ...uploadedFiles }
        newUploadedFiles[file.name] = { ...file }
        setUploadedFiles(newUploadedFiles)
        setCurrentFile(file)
    }

    handleClick = (event, file) => {
        const { setCurrentFile, uploadedFiles } = this.props
        setCurrentFile(file)
        this.setState({
            popperAnchorEl: this.state.popperAnchorEl ? null : event.currentTarget
        })
    }

    handleDocumentModalClose = () => {
        this.setState({ isDocumentModalOpen: false })
    }

    handleDocumentModalOpen = (file, subFolder) => {
        const { setCurrentFile, handleDocumentValuesContainerModalOpen, loggedInUser, currentCompany } = this.props

        if (file.type == "password") {
            return
        } else if (file.type == "note") {
            setCurrentFile(file)
            handleDocumentValuesContainerModalOpen(file, subFolder)

            const newActivity = {
                company: currentCompany.name,
                user: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                date: moment(),
                event: file.name
            }

            api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Åbnet" })
        } else {
            setCurrentFile(file)
            this.setState({ isDocumentModalOpen: true })
        }
    }

    handleCloseCardClick = () => {
        const { setCurrentFile } = this.props
        setCurrentFile(null)
    }

    deleteUploadedFile = (file) => {
        const { uploadedFiles, currentCompany, setUploadedFiles, setCurrentFile, currentFile } = this.props
        const newUploadedFiles = { ...uploadedFiles }

        api.delete(`/${escapeForwardSlash(currentCompany.name)}/uploadedFile/${escapeForwardSlash(file.name)}`)
            .then(res => {
                delete newUploadedFiles[file.name]
                if (currentFile && currentFile.name == file.name) {
                    setCurrentFile(null)
                }
                setUploadedFiles(newUploadedFiles)
            })
    }

    render() {
        const { classes, uploadedFiles, currentFile, currentCompany, isLoading, loggedInUser } = this.props
        const { selectedFileName, isDocumentModalOpen } = this.state

        return (
            <Fragment>
                {!isLoading
                    ?
                    <Fragment>
                        <Grid container className={classes.root} direction="column" spacing={4}>
                            <Grid item style={{ height: "100%" }}>
                                <Grid container direction="row" style={{ height: "100%" }}>
                                    <Grid item xs={6} style={{ paddingRight: 0 }}>
                                        <Grid container className={classes.uploadedFilesContainer} direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h4">
                                                    Uploadede filer
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.filesListContainer}>
                                                <Grid container direction="column" spacing={1}>
                                                    {Object.keys(uploadedFiles).length > 0 ?
                                                        (Object.values(uploadedFiles).map((file, idx) => {
                                                            return (
                                                                <Grid item key={`${file.name}_${idx}`} style={{ width: "100%" }}>
                                                                    <Card className={`${classes.file} ${file.isArchived && classes.fileArchived} ${currentFile && Object.keys(currentFile).length > 0 && file.name === currentFile.name && !file.isArchived && classes.fileSelected}`}>
                                                                        <Grid container direction="row">
                                                                            <Grid item xs={8}>
                                                                                <Grid container direction="row">
                                                                                    <Grid item xs style={{ alignSelf: "center" }}>
                                                                                        <Grid container direction="column" justify="center" alignItems="flex-start">
                                                                                            <Grid item style={{ display: "flex" }}>
                                                                                                <Image />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container direction="column" justify="flex-start">
                                                                                            <Grid item style={{ width: "100% " }}>
                                                                                                <Typography variant="h6" color="textPrimary" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                                                                    {`${file.name}.${file.uploadedName.split(".")[file.uploadedName.split(".").length - 1]}`}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item style={{ width: "100%" }}>
                                                                                                <Typography variant="body2" style={{ color: "grey", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                                                                    Uploaded: 20.05.2019 af ({file.uploaderName})
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Grid container style={{ flexWrap: "nowrap" }} direction="row" justify="flex-end" alignItems="center">
                                                                                    <Grid item>
                                                                                        <IconButton onClick={() => this.deleteUploadedFile(file)}>
                                                                                            <DeleteForever />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                    <Grid item style={{ marginLeft: 4 }}>
                                                                                        <Button className={classes.button} disabled={file.isArchived ? true : false} onClick={(event) => this.handleClick(event, file)} variant="outlined" size="medium">
                                                                                            Arkivér som
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        }))
                                                        :
                                                        (
                                                            <Typography variant="subtitle1" style={{ paddingLeft: 4, paddingRight: 4 }}>Brug panelet nedenunder til at uploade filer</Typography>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Dropzone
                                                    loggedInUser={loggedInUser}
                                                    handleAddNewFile={this.handleAddNewFile}
                                                    currentCompany={currentCompany}
                                                    inUpload={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingLeft: 32, display: "flex", alignItems: "center" }}>
                                        <DocumentValuesContainer
                                            handleDocumentModalOpen={this.handleDocumentModalOpen}
                                            handleCloseCardClick={this.handleCloseCardClick}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isDocumentModalOpen &&
                            <DocumentModal
                                isOpen={isDocumentModalOpen}
                                handleModalClose={this.handleDocumentModalClose}
                            />
                        }
                    </Fragment>
                    :
                    <Grid container justify="center" alignItems="center" style={{ height: "calc(100vh - 150px)", width: "100%" }}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
            </Fragment>
        )
    }
}

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        width: "unset",
        margin: "unset"
    },
    uploadedFilesContainer: {
        height: "calc(100% + 16px)",
    },
    filesListContainer: {
        maxHeight: 494,
        overflow: "scroll",
        width: "100%",
        paddingTop: 0,
        marginTop: 8,
        marginBottom: 8,
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    popperPaper: {
        padding: theme.spacing(2),
        width: "100%"
    },
    file: {
        padding: 12
    },
    fileSelected: {
        border: "2.5px solid rgb(0,200,255, 0.6)"
    },
    fileArchived: {
        background: "palegreen"
    },
    textField: {
        color: theme.palette.primary.main
    },
    buttonText: {
        color: "white"
    },
    button: {
        color: theme.palette.primary.dark
    }
})

const mapStateToProps = ({ state }) => {
    return {
        currentCompany: state.currentCompany,
        isLoading: state.isLoading,
        uploadedFiles: state.uploadedFiles,
        currentFile: state.currentFile,
        loggedInUser: state.loggedInUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setSearchValue, setUploadedFiles, setCurrentFile, setCurrentFileIdx }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Upload))