import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { Grid, Typography, Button, IconButton, Icon, Snackbar, Card, Chip, CardContent, Menu, Switch, FormControlLabel, MenuItem, Collapse, TextField, InputAdornment } from '@material-ui/core'
import { Search, Close, Folder, MoreVert, ArrowRight, ChevronRight, AttachFile, DeleteForever } from "@material-ui/icons"
import { fade } from '@material-ui/core/styles/colorManipulator'
import { setMainFolders, setUploadedFiles, setCurrentFile, setSearchValue, setSearchResults, setCurrentMainFolder, setCurrentFolder, setCurrentSubFolder } from "../actions/actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { fileTypes } from '../enums'
import Dropzone from "./Dropzone"
import api, { escapeForwardSlash, findHighestVersionNumber } from "../api"
import moment from "moment"
import dateFns from 'date-fns';
import InformationSnackbar from "./InformationSnackbar"

class DocumentValuesContainer extends React.PureComponent {
    constructor(props) {
        super(props)

        const { currentFile, currentFileIdx, handleModalClose, didClickUploadDocument, isDirectLink } = props

        this.state = {
            folderSelectTarget: null,
            isFileAlreadyCreated: true,
            isWarningBarOpen: false,
            currentTag: null,
            currentGDPRTag: null,
            previousName: !currentFile ? null : (currentFileIdx != null ? currentFile.name : (isDirectLink ? currentFile.name : null)),
            previousMainFolder: !currentFile ? null : (currentFileIdx != null ? currentFile.mainFolder : (isDirectLink ? currentFile.mainFolder : null)),
            previousFolder: !currentFile ? null : (currentFileIdx != null ? currentFile.folder : (isDirectLink ? currentFile.folder : null)),
            previousSubFolder: !currentFile ? null : (currentFileIdx != null ? currentFile.subFolder : (isDirectLink ? currentFile.subFolder : null)),
            isCollapsed: didClickUploadDocument ? false : (currentFile ? currentFile.isDVCCollapsed : true),
            isCollapsedTransitionFinished: false,
            warningBarMessage: ""
        }

        // handleModalClose ? (currentFile ? (currentFile.type != "note" ? (currentFileIdx != null ? true : false) : false) : true) : true
    }

    componentDidMount() {
        const { setCurrentFile, currentCompany, currentFolder, currentSubFolder, currentMainFolder, currentFile, loggedInUser, selectedFileType } = this.props

        if (!currentFile) {
            setCurrentFile({
                name: "",
                type: selectedFileType,
                uploaderName: loggedInUser.firstname[0] + loggedInUser.lastname[0],
                nextReminder: null,
                dateDue: null,
                periodFrom: null,
                periodTo: null,
                amount: "",
                isEntity: false,
                mainFolder: currentMainFolder ? currentMainFolder.name : null,
                folder: currentFolder ? currentFolder.name : null,
                subFolder: currentSubFolder ? currentSubFolder.name : null,
                company: currentCompany ? currentCompany.name : null,
                GDPR: [],
                tags: [],
                note: "",
                sharedWith: [],
                isArchived: false,
                uploadDate: moment().format("YYYY-MM-DD"),
                uploadedName: null,
                src: null,
                isRemovedExceededDeadlines: false,
                isRemovedUpcomingDeadlines: false,
                isDVCCollapsed: true
            })

            this.setState({ isFileAlreadyCreated: false })
        }
    }

    handleCollapseChange = () => {
        this.setState({ isCollapsed: !this.state.isCollapsed, isCollapsedTransitionFinished: false }, () => setTimeout(this.setState({ isCollapsedTransitionFinished: true }), 3000))
    }

    handleTextFieldChange = (event) => {
        const { uploadedFiles, currentFile, setCurrentFile, setUploadedFiles, setMainFolders } = this.props

        const field = event.target.name
        const newValue = event.target.value

        if (field == "tags") {
            this.setState({ currentTag: newValue })
        } else if (field == "GDPR") {
            this.setState({ currentGDPRTag: newValue })
        } else {
            const newSelectedFile = { ...currentFile }
            newSelectedFile[field] = newValue

            if (field == "dateDue") {
                newSelectedFile.isRemovedExceededDeadlines = false
            } else if (field == "nextReminder") {
                newSelectedFile.isRemovedUpcomingDeadlines = false
            }

            setCurrentFile(newSelectedFile)
        }

    }

    handleAddTag = (e) => {
        const { currentTag } = this.state
        const { currentFile, setCurrentFile } = this.props

        e.preventDefault()

        const newSelectedFile = { ...currentFile }
        newSelectedFile.tags.push(currentTag.trim())
        setCurrentFile(newSelectedFile)

        this.setState({ currentTag: null }, () => this.tagsTextfieldRef.focus())
    }

    handleAddGDPRTag = (e) => {
        const { currentGDPRTag } = this.state
        const { currentFile, setCurrentFile } = this.props

        e.preventDefault()

        const newSelectedFile = { ...currentFile }
        newSelectedFile.GDPR.push(currentGDPRTag.trim())
        setCurrentFile(newSelectedFile)

        this.setState({ currentGDPRTag: null }, () => this.gdprTextfieldRef.focus())
    }

    handleFolderSelectClick = (event) => {
        this.setState({ folderSelectTarget: event.currentTarget })
    }

    handleFolderSelectClose = () => {
        this.setState({ folderSelectTarget: null })
    }

    handleWarningBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isWarningBarOpen: false, warningBarMessage: "" })
    }

    handleWarningBarOpen = (message) => {
        this.setState({ isWarningBarOpen: true, warningBarMessage: message })
    }

    handleUpdateFolder = (mainFolderName, folderName, subFolderName) => {
        const { setUploadedFiles, uploadedFiles, mainFolders, currentFile, currentFileIdx, setCurrentFile, setCurrentMainFolder, setCurrentFolder, setCurrentSubFolder } = this.props

        const newCurrentFile = { ...currentFile }
        newCurrentFile.mainFolder = mainFolderName
        newCurrentFile.folder = folderName
        newCurrentFile.subFolder = subFolderName

        setCurrentFile(newCurrentFile)
        this.setState({ folderSelectTarget: null })
    }

    removeAttachedFile = () => {
        const { isFileAlreadyCreated } = this.state
        const { currentFile, currentFileIdx, setCurrentFile, handleSetNewAttachedFile, newAttachedFile, currentCompany } = this.props

        let fileToEdit = { ...currentFile }
        if (newAttachedFile) {
            fileToEdit = { ...newAttachedFile }
        }

        fileToEdit.src = null
        fileToEdit.type = fileTypes.NOTE

        if (currentFileIdx) {
            handleSetNewAttachedFile(fileToEdit)
        }

        setCurrentFile(fileToEdit)
    }

    handleAttachFile = (e) => {
        const { isFileAlreadyCreated } = this.state
        const { currentFile, mainFolders, setCurrentFile, setMainFolders, currentFileIdx, handleSetNewAttachedFile } = this.props

        let file, fileName

        if (e.target) {
            if (e.target.files.length > 1) {
                return
            }
            file = e.target.files[0]
            fileName = e.target.value.split("\\")[e.target.value.split("\\").length - 1]
        } else {
            file = e
            fileName = file.name
        }

        const newCurrentFile = { ...currentFile }
        newCurrentFile.type = fileTypes.DOCUMENT
        newCurrentFile.src = fileName
        if (newCurrentFile.name == "" || newCurrentFile.name == null) {
            newCurrentFile.name = fileName.split(".")[0]
        }

        const newMainFolders = { ...mainFolders }
        if (currentFileIdx) {
            newMainFolders[currentFile.mainFolder].folders[currentFile.folder].files[currentFileIdx] = newCurrentFile
        }

        const newGUID = dateFns.getTime(new Date())
        const newOriginalName = `${file.name.split(".")[0]}_${newGUID}.${file.name.split(".")[file.name.split(".").length - 1]}`
        newCurrentFile.uploadedName = file.name

        var formData = new FormData()
        formData.append("file", file)
        formData.append("newOriginalName", newOriginalName)

        newCurrentFile.src = newOriginalName

        api.post("/file", formData)
            .then(res => {
                if (res.data && res.data.code == 1001) {
                    api.get(`/${escapeForwardSlash(newCurrentFile.company.name)}/file/${escapeForwardSlash(newCurrentFile.mainFolder)}/${escapeForwardSlash(newCurrentFile.folder)}`)
                        .then(res2 => {
                            if (res2.data) {
                                const highestVersionNumber = findHighestVersionNumber(newCurrentFile, res2.data)
                               
                                if (!newCurrentFile.name.match(/(?<=\()\d+(?=\))/g)) {
                                    newCurrentFile.name = newCurrentFile.name += ` (${highestVersionNumber + 1})`
                                } else {
                                    newCurrentFile.name = newCurrentFile.name.replace(/(?<=\()\d+(?=\))/, highestVersionNumber + 1)
                                }

                                api.post(`/${escapeForwardSlash(newCurrentFile.company.name)}/file`, newCurrentFile)
                            }
                        })
                }
            })
        if (isFileAlreadyCreated) {
            api.put(`/${escapeForwardSlash(newCurrentFile.company)}/file/${escapeForwardSlash(newCurrentFile.name)}/${escapeForwardSlash(newCurrentFile.mainFolder)}/${escapeForwardSlash(newCurrentFile.folder)}`, newCurrentFile)
                .then(res => {
                })
        }

        setCurrentFile(newCurrentFile)

        if (currentFileIdx) {
            setMainFolders(newMainFolders)
    
            const newAttachedFile = { ...newCurrentFile, name: fileName.split(".")[0], uploadedName: fileName }
            handleSetNewAttachedFile(newAttachedFile)
            this.setState({ isCollapsed: true })
        }
    }

    handleTagDelete = (idx) => {
        const { currentFile, setCurrentFile } = this.props

        const newCurrentFile = { ...currentFile }
        newCurrentFile.tags.splice(idx, 1)

        setCurrentFile(newCurrentFile)
    }

    handleGDPRTagDelete = (idx) => {
        const { currentFile, setCurrentFile } = this.props

        const newCurrentFile = { ...currentFile }
        newCurrentFile.GDPR.splice(idx, 1)

        setCurrentFile(newCurrentFile)
    }

    handleArchiveFile = () => {
        const { previousMainFolder, previousFolder, previousSubFolder, previousName, isFileAlreadyCreated, isCollapsed } = this.state
        const { setUploadedFiles, getSearchResults, fromDashboard, setMainFolders, searchValue, setSearchResults, setCurrentFile, handleModalClose, currentCompany, loggedInUser, currentFileIdx, isDirectLink, refreshDashboardItems, mainFolders, uploadedFiles, currentFile, newAttachedFile } = this.props

        let fileToEdit = currentFile
        if (newAttachedFile) {
            fileToEdit = newAttachedFile
        }

        if ((fileToEdit.mainFolder == null && fileToEdit.folder == null) || fileToEdit.name == "") {
            this.handleWarningBarOpen("Navn og folder skal være udfyldt")
        } else {
            const processFile = (file) => {
                const newCurrentFile = file ? file : { ...fileToEdit }
                newCurrentFile.isArchived = true
                newCurrentFile.isDVCCollapsed = isCollapsed
    
                const newUploadedFiles = { ...uploadedFiles }
                newUploadedFiles[fileToEdit.uploadedName] = newCurrentFile
                
                let newMainFolders = { ...mainFolders }
        
                // File being archived in Upload
                if (currentFileIdx == null && !handleModalClose) {
                    if (fileToEdit.subFolder == null) {
                        newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].files.push(newCurrentFile)
                    } else {
                        newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files.push(newCurrentFile)
                    }
                    // File contained in a subfolder
                } else if (previousSubFolder != null && currentFileIdx != null) {
                    if (fileToEdit.mainFolder == previousMainFolder && fileToEdit.folder == previousFolder && fileToEdit.subFolder == previousSubFolder) {
                        newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files[currentFileIdx] = newCurrentFile
                    } else {
                        newMainFolders[previousMainFolder].folders[previousFolder].subFolders[previousSubFolder].files.splice(currentFileIdx, 1)
                        if (fileToEdit.subFolder == null) {
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].files.push(newCurrentFile)
                        } else {
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files.push(newCurrentFile)
                        }
                    }
                }
                // File without a subfolder - free-floating document/note
                else if (previousSubFolder == null && currentFileIdx != null) {
                    if (fileToEdit.subFolder) {
                        if (previousMainFolder == null && previousFolder == null) {
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files.push(newCurrentFile)
                        } else {
                            newMainFolders[previousMainFolder].folders[previousFolder].files.splice(currentFileIdx, 1)
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files.push(newCurrentFile)
                        }
                    } else {
                        if (previousMainFolder == fileToEdit.mainFolder && previousFolder == fileToEdit.folder) {
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].files[currentFileIdx] = newCurrentFile
                        } else {
                            if (isFileAlreadyCreated) {
                                newMainFolders[previousMainFolder].folders[previousFolder].files.splice(currentFileIdx, 1)
                            }
                            newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].files.push(newCurrentFile)
                        }
                    }
                } else if (fileToEdit.subFolder == null) {
                    if (previousMainFolder == null && previousFolder == null) {
                        newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].files.push(newCurrentFile)
                    } else {
                        newMainFolders[previousMainFolder].folders[previousFolder].files.push(newCurrentFile)
                    }
                } else if (fileToEdit.subFolder != null) {
                    newMainFolders[fileToEdit.mainFolder].folders[fileToEdit.folder].subFolders[fileToEdit.subFolder].files.push(newCurrentFile)
                }

                return { newCurrentFile, newMainFolders, newUploadedFiles }
            }

            const newActivity = {
                company: currentCompany.name,
                user: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                date: moment(),
                event: fileToEdit.name
            }

            let completed = (newUploadedFiles, newMainFolders) => {
                setUploadedFiles(newUploadedFiles)
                setMainFolders(newMainFolders)
                if (handleModalClose) {
                    handleModalClose()
                }
                setCurrentFile(null)

                if (searchValue) {
                    setSearchResults(getSearchResults(searchValue))
                }
            }

            if (currentFileIdx == null && !handleModalClose) {
                const { newMainFolders, newCurrentFile, newUploadedFiles } = processFile()
                api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Oprettet" })
                api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newCurrentFile)
                api.delete(`/${escapeForwardSlash(currentCompany.name)}/uploadedFile/${escapeForwardSlash(fileToEdit.uploadedName)}`)
                    .then(res => {
                        completed(newUploadedFiles, newMainFolders)
                    })
            } else if (!isFileAlreadyCreated) {
                const { newMainFolders, newCurrentFile, newUploadedFiles } = processFile()
                api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Oprettet" })
                api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newCurrentFile)    
                    .then(res => {
                        if (res.data && res.data.code == 1001) {
                            api.get(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(newCurrentFile.mainFolder)}/${escapeForwardSlash(newCurrentFile.folder)}`)
                                .then(res2 => {
                                    if (res2.data) {
                                        const highestVersionNumber = findHighestVersionNumber(newCurrentFile, res2.data)

                                        if (!newCurrentFile.name.match(/(?<=\()\d+(?=\))/g)) {
                                            newCurrentFile.name = newCurrentFile.name += ` (${highestVersionNumber + 1})`
                                        } else {
                                            newCurrentFile.name = newCurrentFile.name.replace(/(?<=\()\d+(?=\))/, highestVersionNumber + 1)
                                        }
                                        api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newCurrentFile)
                                            .then(() => {
                                                completed(newUploadedFiles, newMainFolders)
                                            })
                                    }
                                })
                        } else {
                            completed(newUploadedFiles, newMainFolders)
                        }
                    })
            } else {
                const newCurrentFile = { ...fileToEdit }
                newCurrentFile.isArchived = true
                newCurrentFile.isDVCCollapsed = isCollapsed

                api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Redigeret" })
                api.put(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(previousName)}/${escapeForwardSlash(previousMainFolder)}/${escapeForwardSlash(previousFolder)}`, newCurrentFile)
                    .then((res) => {
                        if (res.data && res.data.code != 1001) {
                            const { newMainFolders, newCurrentFile, newUploadedFiles } = processFile()
                            if (isDirectLink) {
                                for (const i in newMainFolders[previousMainFolder].folders[previousFolder].files) {
                                    const f = newMainFolders[previousMainFolder].folders[previousFolder].files[i]
                                    if (f.name == previousName) {
                                        if (fromDashboard) {
                                            newMainFolders[previousMainFolder].folders[previousFolder].files.splice(i, 1)
                                        } else {
                                            newMainFolders[previousMainFolder].folders[previousFolder].files.splice(i, 1)
                                            break
                                        }
                                    }
                                }
                                
                                refreshDashboardItems()
                                completed(newUploadedFiles, newMainFolders)
                            } else {
                                completed(newUploadedFiles, newMainFolders)
                            }
                        } else {
                            api.get(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(newCurrentFile.mainFolder)}/${escapeForwardSlash(newCurrentFile.folder)}`)
                                .then(res2 => {
                                    if (res2.data) {
                                        const highestVersionNumber = findHighestVersionNumber(newCurrentFile, res2.data)

                                        if (!newCurrentFile.name.match(/(?<=\()\d+(?=\))/g)) {
                                            newCurrentFile.name = newCurrentFile.name += ` (${highestVersionNumber + 1})`
                                            console.log(newCurrentFile.name)
                                        } else {
                                            newCurrentFile.name = newCurrentFile.name.replace(/(?<=\()\d+(?=\))/, highestVersionNumber + 1)
                                        }

                                        api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newCurrentFile)
                                        api.delete(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(previousName)}/${escapeForwardSlash(previousMainFolder)}/${escapeForwardSlash(previousFolder)}`)
                                            .then(() => {
                                                const { newMainFolders, newUploadedFiles } = processFile(newCurrentFile)
                                                completed(newUploadedFiles, newMainFolders)
                                            })
                                    }
                                })
                        }
                    })
            }
        }
    }

    render() {
        const { folderSelectTarget, isWarningBarOpen, isCollapsed, warningBarMessage, isCollapsedTransitionFinished, currentTag, currentGDPRTag } = this.state
        const { classes, handleModalClose, handleDocumentModalOpen, newAttachedFile, mainFolders, currentFile, currentFileIdx, setCurrentFile, currentCompany, didClickUploadDocument, loggedInUser } = this.props

        let fileToRender = currentFile
        if (newAttachedFile) {
            fileToRender = newAttachedFile
        }

        const textFieldInputLabelProps = {
            classes: {
                root: classes.textField
            },
            shrink: true
        }

        const foldersExist = () => {
            for (const [mainFolder, values] of Object.entries(mainFolders)) {
                if (values.folders.length < 1) {
                    return false
                }
            }
            return true
        }

        return (
            <Fragment>
                <Grid container justify="center" style={{ height: "100%", width: handleModalClose ? 580 : "" }}>
                    <Grid item style={{ width: "100%" }}>
                        <Card className={`${classes.card} ${!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? classes.cardHidden : "") : "") : "") : classes.cardHidden) : ""}`}>
                            <CardContent>
                                <Collapse in={isCollapsed}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item style={{ padding: currentFileIdx == null ? 0 : "", width: "100%" }}>
                                            {handleModalClose ?
                                                (
                                                    <Grid container direction="row" justify="space-between">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={isCollapsed || true} onChange={this.handleCollapseChange} />}
                                                                label="Vis mindre"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton size="small" onClick={(e) => !handleModalClose ? setCurrentFile(null) : handleModalClose(e)} disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false}>
                                                                <Close />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )
                                                :
                                                (
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <IconButton size="small" onClick={(e) => !handleModalClose ? setCurrentFile(null) : handleModalClose(e)} disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false}>
                                                            <Close />
                                                        </IconButton>
                                                    </div>
                                                )
                                            }
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <TextField disabled={!handleModalClose ? (!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="Titel" name="name" label="Titel" value={fileToRender && fileToRender.name || ""} onChange={this.handleTextFieldChange} />
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} type="date" fullWidth variant="outlined" placeholder="Frist" name="dateDue" label="Frist" value={fileToRender && fileToRender.dateDue || ""} onChange={this.handleTextFieldChange} />
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} type="date" fullWidth variant="outlined" placeholder="Næste påmindelse" name="nextReminder" label="Næste påmindelse" value={fileToRender && fileToRender.nextReminder || ""} onChange={this.handleTextFieldChange} />
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} type="date" fullWidth variant="outlined" placeholder="Gælder fra" name="periodFrom" label="Gælder fra" value={fileToRender && fileToRender.periodFrom || ""} onChange={this.handleTextFieldChange} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} type="date" fullWidth variant="outlined" placeholder="Gælder til" name="periodTo" label="Gælder til" value={fileToRender && fileToRender.periodTo || ""} onChange={this.handleTextFieldChange} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="Beløb" name="amount" label="Beløb" value={fileToRender && fileToRender.amount || ""} onChange={this.handleTextFieldChange} InputProps={{
                                                startAdornment: <InputAdornment classes={{ root: classes.rootInputAdornment }} disableTypography position="start"><Typography color="textPrimary">DKK</Typography></InputAdornment>,
                                            }} />
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item xs={1}>
                                                    <IconButton disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} onClick={this.handleFolderSelectClick}>
                                                        <Folder />
                                                    </IconButton>
                                                    {Object.keys(mainFolders).length > 0 && foldersExist() &&
                                                        <FolderSelectMenu anchorEl={folderSelectTarget} handleFolderSelectClose={this.handleFolderSelectClose} handleUpdateFolder={this.handleUpdateFolder} mainFolders={mainFolders} />
                                                    }
                                                </Grid>
                                                <Grid item xs style={{ marginLeft: 4 }}>
                                                    {Object.keys(mainFolders).length > 0 && foldersExist()
                                                        ?
                                                        (fileToRender
                                                            ?
                                                            (fileToRender.mainFolder ?
                                                                (
                                                                    <Typography>
                                                                        {fileToRender.mainFolder ? fileToRender.mainFolder : ""} {fileToRender.folder ? " | " + fileToRender.folder : ""} {fileToRender.subFolder ? " | " + fileToRender.subFolder : ""}
                                                                    </Typography>
                                                                )
                                                                : (
                                                                    <Typography style={{ fontStyle: "italic" }}>
                                                                        Endnu ikke føjet til en mappe
                                                                    </Typography>
                                                                )
                                                            )
                                                            :
                                                            (
                                                                <Typography style={{ fontStyle: "italic" }}>
                                                                    Endnu ikke føjet til en mappe
                                                                </Typography>
                                                            )
                                                        )
                                                        :
                                                        <Typography style={{ fontStyle: "italic" }}>
                                                            Der er ingen oprettede mapper endnu
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {fileToRender && fileToRender.src &&
                                            <Grid item style={{ width: "100%" }}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item xs={1}>
                                                        <input
                                                            style={{ display: "none" }}
                                                            id="attach-file-button"
                                                            type="file"
                                                            onChange={this.handleAttachFile}
                                                        />
                                                        <label htmlFor="attach-file-button">
                                                            <IconButton variant="contained" component="span">
                                                                <AttachFile />
                                                            </IconButton>
                                                        </label>
                                                    </Grid>
                                                    <Grid item style={{ marginLeft: 4 }} xs>
                                                        <Button onClick={() => handleDocumentModalOpen(fileToRender, fileToRender.subFolder, true)}>
                                                            <Typography>{fileToRender.uploadedName ? fileToRender.uploadedName : fileToRender.name}</Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={this.removeAttachedFile}>
                                                            <DeleteForever />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={1}>
                                                        {fileToRender && fileToRender.GDPR != null && typeof fileToRender.GDPR != "undefined" && fileToRender.GDPR.length < 1
                                                            ?
                                                            <Grid item>
                                                                <Typography>Ingen GDPR tags valgt endnu</Typography>
                                                            </Grid>
                                                            :
                                                            (fileToRender && fileToRender.GDPR.map((tag, idx) => {
                                                                let icon
                                                                return (
                                                                    <Grid item key={`${tag}_${idx}`}>
                                                                        <Chip
                                                                            key={`${idx}_${tag}`}
                                                                            icon={icon}
                                                                            label={tag}
                                                                            onDelete={() => this.handleGDPRTagDelete(idx)}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            }))
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <form onSubmit={this.handleAddGDPRTag}>
                                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                                            <Grid item xs>
                                                                <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="GDPR tags" name="GDPR" label="GDPR tags" inputRef={ref => this.gdprTextfieldRef = ref} value={currentGDPRTag || ""} onChange={this.handleTextFieldChange} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Button disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} variant="contained" color="primary" onClick={this.handleAddGDPRTag}>
                                                                    <Typography color="textSecondary">Tilføj</Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={1}>
                                                        {fileToRender && fileToRender.tags != null && typeof fileToRender.tags != "undefined" && fileToRender.tags.length < 1
                                                            ?
                                                            <Grid item>
                                                                <Typography>Ingen tags valgt endnu</Typography>
                                                            </Grid>
                                                            :
                                                            (fileToRender && fileToRender.tags.map((tag, idx) => {
                                                                let icon
                                                                return (
                                                                    <Grid item key={`${tag}_${idx}`}>
                                                                        <Chip
                                                                            key={`${idx}_${tag}`}
                                                                            icon={icon}
                                                                            label={tag}
                                                                            onDelete={() => this.handleTagDelete(idx)}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            }))
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <form onSubmit={this.handleAddTag}>
                                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                                            <Grid item xs>
                                                                <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="Tags" name="tags" label="Tags" inputRef={ref => this.tagsTextfieldRef = ref} value={currentTag || ""} onChange={this.handleTextFieldChange} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Button disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} variant="contained" color="primary" onClick={this.handleAddTag}>
                                                                    <Typography color="textSecondary">Tilføj</Typography>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                                <Grid container direction="column" spacing={2} style={{ paddingTop: isCollapsed ? 16 : "" }}>
                                    {!isCollapsed &&
                                        <Fragment>
                                            <Grid item>
                                                <Grid container direction="row" justify="space-between">
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={<Switch checked={isCollapsed || false} onChange={this.handleCollapseChange} />}
                                                            label="Vis mere"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton size="small" onClick={handleModalClose} disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false}>
                                                            <Close />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: "100%" }}>
                                                <TextField disabled={!handleModalClose ? (!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="Titel" name="name" label="Titel" value={fileToRender && fileToRender.name || ""} onChange={this.handleTextFieldChange} />
                                            </Grid>
                                        </Fragment>
                                    }
                                    <Grid item style={{ width: "100%" }}>
                                        <TextField disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} InputLabelProps={textFieldInputLabelProps} fullWidth variant="outlined" placeholder="Note" name="note" label="Note" value={fileToRender && fileToRender.note || ""} multiline rows={isCollapsed ? 2 : 16} onChange={this.handleTextFieldChange} />
                                    </Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        {fileToRender && handleModalClose && !fileToRender.src && didClickUploadDocument ?
                                            (
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <Dropzone
                                                            loggedInUser={loggedInUser}
                                                            handleAddNewFile={this.handleAttachFile}
                                                            currentCompany={currentCompany}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                            <Button disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} color="primary" variant="contained" onClick={this.handleArchiveFile}>
                                                                <Typography color="textSecondary">{handleModalClose ? (fileToRender && fileToRender.isArchived ? "Gem" : "Opret") : "Arkivér"}</Typography>
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                            : (fileToRender && !fileToRender.src ?
                                                (
                                                    <Grid container direction="row" justify="space-between">
                                                        <Grid item>
                                                            <input
                                                                style={{ display: "none" }}
                                                                id="raised-button-file"
                                                                multiple
                                                                type="file"
                                                                onChange={this.handleAttachFile}
                                                            />
                                                            <label htmlFor="raised-button-file">
                                                                <Button variant="contained" component="span">
                                                                    Tilknyt fil
                                                                </Button>
                                                            </label>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button color="primary" variant="contained" onClick={this.handleArchiveFile}>
                                                                <Typography color="textSecondary">{handleModalClose ? (fileToRender && fileToRender.isArchived ? "Gem" : "Opret") : "Arkivér"}</Typography>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )
                                                :
                                                (
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <Button disabled={!handleModalClose ? (fileToRender ? (currentFileIdx != null ? (Object.keys(fileToRender).length < 1 ? (fileToRender.isArchived ? true : false) : false) : false) : true) : false} color="primary" variant="contained" onClick={this.handleArchiveFile}>
                                                            <Typography color="textSecondary">{handleModalClose ? (fileToRender && fileToRender.isArchived ? "Gem" : "Opret") : "Arkivér"}</Typography>
                                                        </Button>
                                                    </div>
                                                )
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <InformationSnackbar handleBarClose={this.handleWarningBarClose} isBarOpen={isWarningBarOpen} message={warningBarMessage} />
            </Fragment>

        )
    }
}

const FolderSelectMenu = (props) => {
    const { anchorEl, handleFolderSelectClose, handleUpdateFolder, mainFolders } = props
    const [mainFolderRefs, setMainFolderRefs] = React.useState([].fill(null))
    const [folderRefs, setFolderRefs] = React.useState([].fill(null))

    const setNewMainFolderRef = (newValue, mainFolderIdx) => {
        const newRefsArray = [...mainFolderRefs]
        newRefsArray[mainFolderIdx] = newValue
        setMainFolderRefs(newRefsArray)
    }

    const setNewFolderRef = (newValue, folderIdx) => {
        const newRefsArray = [...folderRefs]
        newRefsArray[folderIdx] = newValue
        setFolderRefs(newRefsArray)
    }

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFolderSelectClose}>
            {Object.values(mainFolders).sort(((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1)).map((mainFolder, mainFolderIdx) => {
                return (
                    <MenuItem key={mainFolder.name} onClick={(e) => { !Boolean(mainFolderRefs[mainFolderIdx]) && setNewMainFolderRef(e.currentTarget, mainFolderIdx) }}>
                        <Fragment>
                            {mainFolder.name}
                            <Menu anchorEl={mainFolderRefs[mainFolderIdx]} open={Boolean(mainFolderRefs[mainFolderIdx])} onClose={() => setNewMainFolderRef(null, mainFolderIdx)}>
                                {Object.keys(mainFolder.folders).length > 0 ? (
                                    Object.values(mainFolder.folders).filter(f => { if (f.folderContentType === "kodebank") return false; else return true }).sort(((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)))).map((folder, folderIdx) => {
                                        return (
                                            <MenuItem key={folder.name} onClick={e => { e.stopPropagation(); setNewFolderRef(null, folderIdx); setNewMainFolderRef(null, mainFolderIdx); handleUpdateFolder(mainFolder.name, folder.name, null) }}>
                                                <Fragment>
                                                    <Grid container direction="row" spacing={2} justify="space-between">
                                                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography>{folder.name}</Typography>
                                                        </Grid>
                                                        <Grid item style={{ padding: 0 }}>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); !Boolean(folderRefs[folderIdx]) && setNewFolderRef(e.currentTarget, folderIdx) }}>
                                                                <ChevronRight />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Menu anchorEl={folderRefs[folderIdx]} open={Boolean(folderRefs[folderIdx])} onClose={() => setNewFolderRef(null, folderIdx)}>
                                                        {Object.keys(folder.subFolders).length > 0 ? (
                                                            Object.values(folder.subFolders).sort(((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0)))).map((subFolder, subFolderIdx) => {
                                                                return (
                                                                    <MenuItem key={subFolder.name} onClick={(e) => { e.stopPropagation(); setNewFolderRef(null, folderIdx); setNewMainFolderRef(null, mainFolderIdx); handleUpdateFolder(mainFolder.name, folder.name, subFolder.name) }}>
                                                                        {subFolder.name}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        )
                                                            :
                                                            (<MenuItem key={"empty"} onClick={() => setNewFolderRef(null, folderIdx)}>
                                                                Denne mappe har ingen undermapper
                                                            </MenuItem>
                                                            )
                                                        }
                                                    </Menu>
                                                </Fragment>
                                            </MenuItem>
                                        )
                                    })
                                )
                                    :
                                    (<MenuItem key={"empty"} onClick={() => setNewMainFolderRef(null, mainFolderIdx)}>
                                        Denne hovedmappe er tom
                                    </MenuItem>
                                    )
                                }
                            </Menu>
                        </Fragment>
                    </MenuItem>
                )
            })}
        </Menu>
    )
}

const styles = theme => ({
    card: {
        opacity: "1.0",
        transition: "opacity 200ms cubic-bezier(0.0, 0, 0.2, 1)"
    },
    cardHidden: {
        opacity: "0.2"
    },
    rootInputAdornment: {
        marginTop: 2
    },
    textField: {
        color: theme.palette.primary.dark
    },

})

const mapStateToProps = ({ state }) => {
    return {
        currentCompany: state.currentCompany,
        uploadedFiles: state.uploadedFiles,
        mainFolders: state.mainFolders,
        currentFile: state.currentFile,
        currentMainFolder: state.currentMainFolder,
        currentFolder: state.currentFolder,
        currentSubFolder: state.currentSubFolder,
        currentFileIdx: state.currentFileIdx,
        loggedInUser: state.loggedInUser,
        searchValue: state.searchValue
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUploadedFiles, setSearchResults, setSearchValue, setMainFolders, setCurrentFile, setCurrentMainFolder, setCurrentFolder, setCurrentSubFolder }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(DocumentValuesContainer))