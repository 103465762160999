import axios from "axios"

export default axios.create({
    baseURL: "http://167.71.37.46:8000/api"
})

export const escapeForwardSlash = (string) => {
    return string.replace(/\//g, "%2F")
}

export const findHighestVersionNumber = (newFile, files) => {
    let highestVersionNumber = 0
    for (const file of files) {
        if (file.name.includes(newFile.name.split(/\(\d+\)/g)[0])) {
            const match = file.name.match(/(?<=\()\d+(?=\))/g)
            const versionNumber = match != null ? parseInt(match[match.length - 1]) : 0
            if (versionNumber > highestVersionNumber) {
                highestVersionNumber = versionNumber
            }
        }
    }
    return highestVersionNumber
}

// baseURL: "http://localhost:8000/api"
//"http://167.71.37.46:8000/api"

