import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Typography, ExpansionPanel, IconButton, ExpansionPanelSummary, Modal, MenuItem, Fab, Menu, Dialog, ExpansionPanelDetails, Divider } from '@material-ui/core'
import { ExpandMore, MoreVert, NoteAdd } from "@material-ui/icons"
import FileActionBar from "./FileActionBar"
import DocumentModal from "./DocumentModal"
import { fileTypes, urgencyLevels } from "../enums"
import Dropzone from "./Dropzone"
import DocumentValuesContainer from "./DocumentValuesContainer"
import moment from "moment"
import dateFns from 'date-fns'
import api, { escapeForwardSlash, findHighestVersionNumber } from "../api"
import { setCurrentFile, setCurrentSubFolder, setMainFolders, setCurrentFolder } from "../actions/actions"

class FolderExpansionPanel extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isDocumentModalOpen: false
        }
    }

    handleDocumentModalClose = () => {
        this.setState({ isDocumentModalOpen: false })
    }

    handleDocumentModalOpen = (file, subFolder, didClickAttachedFileButton) => {
        const { setCurrentFile, handleDocumentValuesContainerModalOpen, loggedInUser, currentCompany } = this.props

        if (file.type == "password") {
            return
        } else if (didClickAttachedFileButton) {
            setCurrentFile(file)
            this.setState({ isDocumentModalOpen: true })
        } else if (file.type == "note" || file.type == "document") {
            setCurrentFile(file)
            handleDocumentValuesContainerModalOpen(file, subFolder)

            const newActivity = {
                company: currentCompany.name,
                user: `${loggedInUser.firstname} ${loggedInUser.lastname}`,
                date: moment(),
                event: file.name
            }

            api.post(`/${escapeForwardSlash(currentCompany.name)}/activity`, { ...newActivity, status: "Åbnet" })
        } 
    }

    handleAddNewFile = (file, subFolder, formData) => {
        const { currentMainFolder, currentCompany, loggedInUser, currentFolder, setCurrentFolder, setCurrentSubFolder, setWarningBarAndLabel } = this.props

        const newCurrentFolder = { ...currentFolder }
        const newFile = {
            ...file,
            folder: currentFolder.name,
            mainFolder: currentMainFolder.name,
            isArchived: true,
            dateDue: "",
            nextReminder: "",
            periodFrom: "",
            periodTo: "",
            subFolder: subFolder ? subFolder.name : null,
            tags: [],
            uploaderName: loggedInUser.firstname[0] + loggedInUser.lastname[0],
            urgency: urgencyLevels.DEFAULT
        }

        api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newFile)
            .then(res => {
                if (res.data && res.data.code != 1001) {
                    if (subFolder) {
                        newCurrentFolder.subFolders[subFolder.name].files.push(newFile)
                    } else {
                        newCurrentFolder.files.push(newFile)
                    }

                    api.post("/file", formData)
                        .then(res => {
                            setCurrentFolder(newCurrentFolder)
                        })
                } else {
                    api.get(`/${escapeForwardSlash(currentCompany.name)}/file/${escapeForwardSlash(newFile.mainFolder)}/${escapeForwardSlash(newFile.folder)}`)
                        .then(res2 => {
                            if (res2.data) {
                                const highestVersionNumber = findHighestVersionNumber(newFile, res2.data)

                                if (!newFile.name.match(/(?<=\()\d+(?=\))/g)) {
                                    newFile.name = newFile.name += ` (${highestVersionNumber + 1})`
                                } else {
                                    newFile.name = newFile.name.replace(/(?<=\()\d+(?=\))/, highestVersionNumber + 1)
                                }

                                api.post(`/${escapeForwardSlash(currentCompany.name)}/file`, newFile)
                                    .then(res3 => {
                                        api.post("/file", formData)
                                            .then(res4 => {
                                                if (subFolder) {
                                                    newCurrentFolder.subFolders[subFolder.name].files.push(newFile)
                                                } else {
                                                    newCurrentFolder.files.push(newFile)
                                                }
                                                setCurrentFolder(newCurrentFolder)
                                            })
                                    })
                            }
                        })
                }
            })
    }

    handleSetNewAttachedFile = file => {
        this.setState({ newAttachedFile: file })
    }

    render() {
        const { isDocumentModalOpen, newAttachedFile } = this.state
        const { setDVCType, didClickUploadDocument, handleShareClicked, getSearchResults, currentCompany, handleUploadDocumentClicked, handleMoreOptionsClicked, handleMoreOptionsClose, handleAddNewNoteClicked, moreOptionsSubFolderTarget, isDocumentValuesContainerModalOpen, selectedFileType, handleDocumentValuesContainerModalOpen, handleDocumentValuesContainerModalClose, classes, currentFile, currentFolder, handleDialogOpen, searchResults, wasCodebankPasswordCorrect, setCurrentSubFolder, loggedInUser } = this.props

        return (
            <Fragment>
                {searchResults
                    ?
                    (
                        <Grid container className={classes.root} direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="column" alignItems="center" justify="center">
                                    {searchResults.length 
                                        ? 
                                        searchResults.map((file, fileIdx) => {
                                            return (
                                                <FileActionBar
                                                    handleShareClicked={handleShareClicked}
                                                    key={`${file.name}_${fileIdx}`}
                                                    file={file}
                                                    idx={fileIdx}
                                                    searchResults={searchResults}
                                                    handleDialogOpen={handleDialogOpen}
                                                    handleDocumentModalOpen={this.handleDocumentModalOpen}
                                                    handleDocumentValuesContainerModalOpen={handleDocumentValuesContainerModalOpen}
                                                    isLastIdx={fileIdx === searchResults.length - 1}
                                                />
                                            )
                                        })
                                        :
                                        <Grid item style={{ alignSelf: "flex-start" }}>
                                            <Typography variant="h6">Ingen matches</Typography>
                                        </Grid>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    :
                    ((currentFolder && currentFolder.folderContentType == "kodebank" && !wasCodebankPasswordCorrect
                        ?
                        (
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item><Typography variant="h6">Mappens indhold er beskyttet</Typography></Grid>
                            </Grid>
                        )
                        :
                        (
                            <Fragment>
                                <Grid container className={classes.root} direction="column" spacing={2} style={{ height: currentFolder && currentFolder.subFolders && Object.keys(currentFolder.subFolders).length > 0 ? "unset" : "100%" }}>
                                    {currentFolder && currentFolder.files && currentFolder.files.length > 0 &&
                                        <Fragment>
                                            <Grid item style={{ height: "100%", paddingBottom: 0 }}>
                                                <Grid container direction="column" justify="space-between" style={{ height: "100%", flexFlow: "column" }}>
                                                    <Grid item>
                                                        <Grid container direction="column" alignItems="center" justify="center">
                                                            {currentFolder.files.sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))).map((file, fileIdx) => {
                                                                return (
                                                                    <FileActionBar
                                                                        handleShareClicked={handleShareClicked}
                                                                        key={`${file.name}_${fileIdx}`}
                                                                        file={file}
                                                                        idx={fileIdx}
                                                                        handleDialogOpen={handleDialogOpen}
                                                                        handleDocumentModalOpen={this.handleDocumentModalOpen}
                                                                        handleDocumentValuesContainerModalOpen={handleDocumentValuesContainerModalOpen}
                                                                        isLastIdx={fileIdx === currentFolder.files.length - 1}
                                                                    />
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{ width: "100%", marginTop: 16, marginBottom: 16 }}>
                                                        <Grid container direction="row">
                                                            <Grid item xs={10}>
                                                                <Dropzone
                                                                    loggedInUser={loggedInUser}
                                                                    handleAddNewFile={this.handleAddNewFile}
                                                                    currentCompany={currentCompany}
                                                                    directUpload={true}
                                                                    height={120}
                                                                />
                                                            </Grid>
                                                            <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={2} onClick={(e) => { e.stopPropagation(); setDVCType(false); handleAddNewNoteClicked(fileTypes.NOTE) }}> 
                                                                <Fab color="primary" style={{ color: "white" }}>
                                                                    <NoteAdd />
                                                                </Fab>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    }
                                    {currentFolder && Object.values(currentFolder.subFolders).length > 0 && currentFolder.files && currentFolder.files.length < 1 &&
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container direction="row">
                                                <Grid item xs={10}>
                                                    <Dropzone
                                                        loggedInUser={loggedInUser}
                                                        handleAddNewFile={this.handleAddNewFile}
                                                        currentCompany={currentCompany}
                                                        directUpload={true}
                                                        height={120}
                                                    />
                                                </Grid>
                                                <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={2} onClick={(e) => { e.stopPropagation(); setDVCType(false); handleAddNewNoteClicked(fileTypes.NOTE) }}> 
                                                    <Fab color="primary" style={{ color: "white" }}>
                                                        <NoteAdd />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {currentFolder && Object.values(currentFolder.subFolders).length > 0 &&
                                        <Grid item style={{ width: "100%" }}>
                                            {Object.values(currentFolder.subFolders).sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))).map((subFolder, subFolderIdx) => {
                                                return (
                                                    <ExpansionPanel key={`${subFolder.name}_${subFolderIdx}`}>
                                                        <ExpansionPanelSummary
                                                            classes={{ root: classes.subFolderBarRoot }}
                                                            className={classes.subFolderBar}
                                                            aria-controls={`panel${subFolderIdx + 1}-content`}
                                                            id={`panel${subFolderIdx + 1}-header`}
                                                            onClick={e => e.stopPropagation()}
                                                        >
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item xs>
                                                                    <Typography className={classes.heading} color="textSecondary">{subFolder.name} {subFolder.files.length > 0 ? `(${subFolder.files.length})` : ""}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <IconButton style={{ color: "white" }} onClick={(e) => { e.stopPropagation(); handleMoreOptionsClicked(e, "moreOptionsSubFolderTarget", currentFolder.mainFolder, currentFolder.name, subFolder.name) }}>
                                                                        <MoreVert />
                                                                    </IconButton>
                                                                    <Menu id="simple-menu" anchorEl={moreOptionsSubFolderTarget} open={Boolean(moreOptionsSubFolderTarget)} onClose={(e) => { e.stopPropagation(); handleMoreOptionsClose("moreOptionsSubFolderTarget") }}>
                                                                        <MenuItem key="Opret note" onClick={(e) => { e.stopPropagation(); setDVCType(false); handleAddNewNoteClicked(fileTypes.NOTE) }}>
                                                                            Opret note
                                                                        </MenuItem>
                                                                        <MenuItem key="Upload dokument" onClick={(e) => { e.stopPropagation(); setDVCType(true); handleUploadDocumentClicked(fileTypes.DOCUMENT) }}>
                                                                            Upload dokument
                                                                        </MenuItem>
                                                                        <MenuItem key="Omdøb hovedmappe" onClick={(e) => { e.stopPropagation(); handleDialogOpen("renameSubFolderDialog") }}>
                                                                            Omdøb
                                                                        </MenuItem>
                                                                        <MenuItem key="Slet undermappe" onClick={(e) => { e.stopPropagation(); handleDialogOpen("deleteSubFolderDialog") }}>
                                                                            Slet
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </Grid>
                                                            </Grid>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails classes={{ root: classes.rootExpansionPanelDetails }}>
                                                            <Grid container direction="column" alignItems="center" justify="center">
                                                                {subFolder.files.sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))).map((file, index) => {
                                                                    return (
                                                                        <FileActionBar
                                                                            handleShareClicked={handleShareClicked}
                                                                            key={`${file.name}_${index}`}
                                                                            file={file}
                                                                            idx={index}
                                                                            subFolder={subFolder}
                                                                            handleDialogOpen={handleDialogOpen}
                                                                            handleDocumentModalOpen={this.handleDocumentModalOpen}
                                                                            handleDocumentValuesContainerModalOpen={handleDocumentValuesContainerModalOpen}
                                                                            isLastIdx={index === subFolder.files.length - 1}
                                                                        />
                                                                    )
                                                                })}
                                                            </Grid>
                                                            <Grid container direction="column">
                                                                <Grid item style={{ width: "100%" }}>
                                                                    <Grid container direction="row">
                                                                        <Grid item xs={10} style={{ paddingLeft: 16, paddingTop: 16, paddingBottom: 16 }}>
                                                                            <Dropzone
                                                                                loggedInUser={loggedInUser}
                                                                                handleAddNewFile={this.handleAddNewFile}
                                                                                currentCompany={currentCompany}
                                                                                directUpload={true}
                                                                                subFolder={subFolder}
                                                                                height={120}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={2} onClick={(e) => { e.stopPropagation(); setDVCType(false); setCurrentSubFolder(subFolder); handleAddNewNoteClicked(fileTypes.NOTE) }}> 
                                                                            <Fab color="primary" style={{ color: "white" }}>
                                                                                <NoteAdd />
                                                                            </Fab>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                )
                                            })}
                                        </Grid>
                                    }
                                    {currentFolder && currentFolder.files && currentFolder.files.length < 1 && Object.values(currentFolder.subFolders).length < 1 &&
                                        <Grid item style={{ height: "100%", paddingBottom: 16 }}>
                                            <Grid container direction="column" justify="space-between" style={{ height: "100%" }}>
                                                <Grid item>
                                                    <Typography variant="h6">Mappen er tom</Typography>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Grid container direction="row">
                                                        <Grid item xs={10}>
                                                            <Dropzone
                                                                loggedInUser={loggedInUser}
                                                                handleAddNewFile={this.handleAddNewFile}
                                                                currentCompany={currentCompany}
                                                                directUpload={true}
                                                                height={120}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={2} onClick={(e) => { e.stopPropagation(); setDVCType(false); handleAddNewNoteClicked(fileTypes.NOTE) }}> 
                                                            <Fab color="primary" style={{ color: "white" }}>
                                                                <NoteAdd />
                                                            </Fab>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Fragment>
                        )
                    ))
                }
                {isDocumentModalOpen &&
                    <DocumentModal
                        isOpen={isDocumentModalOpen}
                        handleModalClose={this.handleDocumentModalClose}
                        newAttachedFile={newAttachedFile}
                    />
                }
                {isDocumentValuesContainerModalOpen &&
                    <Dialog open={isDocumentValuesContainerModalOpen} onClose={e => handleDocumentValuesContainerModalClose(e)} classes={{ paperScrollPaper: classes.paperScrollPaper }}>
                        <DocumentValuesContainer
                            handleDocumentModalOpen={this.handleDocumentModalOpen}
                            selectedFileType={selectedFileType}
                            handleModalClose={handleDocumentValuesContainerModalClose}
                            didClickUploadDocument={didClickUploadDocument}
                            handleSetNewAttachedFile={this.handleSetNewAttachedFile}
                            getSearchResults={getSearchResults}
                        />
                    </Dialog>
                }
            </Fragment>
        )
    }
}



const styles = theme => ({
    root: {
        width: "100%",
        padding: theme.spacing(1),
        margin: 0,
        paddingBottom: 0
    },
    rootExpansionPanelDetails: {
        padding: 0,
        flexDirection: "column"
    },
    heading: {
        fontSize: 20,
        fontWeight: 400,
        hyphens: "auto",
        wordBreak: "break-word"
    },
    subFolderBar: {
        background: "dimgrey"
    },
    subFolderBarRoot: {
        padding: "0 0 0 24px"
    },
    paperScrollPaper: {
        maxHeight: "calc(100% - 58px)"
    },
    fileActionBarRowDivider: {
        width: "100%",
    },
})

const mapStateToProps = ({ state }) => {
    return {
        mainFolders: state.mainFolders,
        mainFoldersEntities: state.mainFoldersEntities,
        currentMainFolder: state.currentMainFolder,
        currentFile: state.currentFile,
        currentCompany: state.currentCompany,
        currentSubFolder: state.currentSubFolder,
        currentFolder: state.currentFolder,
        loggedInUser: state.loggedInUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setCurrentFile, setCurrentFolder, setCurrentSubFolder, setMainFolders }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(FolderExpansionPanel))