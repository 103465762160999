import React, { Fragment } from 'react'

class Assignments extends React.Component {
    render() {
        return (
            <div></div>
        )
    }
}

// const styles = theme => ({

// })

// const mapStateToProps = ({ state }) => {
//     return {
//         mainFolders: state.mainFolders,
//         isLoading: state.isLoading,
//         loggedInUser: state.loggedInUser
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({ setIsLoading, setSharing, setMainFolders, setUploadedFiles, setCompanies, setCurrentCompany, setCurrentFolder }, dispatch)
// }

// export default compose(
//     withStyles(styles),
//     connect(null, null),
// )(withRouter(Calendar))

export default Assignments