import React from "react"
import { entityTypes, urgencyLevels, folderIcons, fileTypes } from "./enums"
import { Folder, VpnKey, Business, Face } from "@material-ui/icons"
import moment from "moment"

export const subFolders = {
    "Udvidede virksomhedsoplysninger": {
        name: "Udvidede virksomhedsoplysninger",
        files: []
    },
    "Tegningsregel, personkreds og revisor": {
        name: "Tegningsregel, personkreds og revisor",
        files: []
    },
    "Ejerforhold": {
        name: "Ejerforhold",
        files: []
    },
    "Regnskaber": {
        name: "Regnskaber",
        files: [{
            name: "Årsrapport 2018",
            type: fileTypes.DOCUMENT,
            uploaderName: "FJ",
            nextReminder: moment().add(7, "days").format("YYYY-MM-DD"),
            dateDue: moment().add(21, "days").format("YYYY-MM-DD"),
            periodFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
            periodTo: moment().format("YYYY-MM-DD"),
            amount: "",
            mainFolder: "Main folder 1",
            folder: "Firmaoplysninger fra CVR",
            subFolder: "Regnskaber",
            GDPR: "",
            tags: [],
            note: "",
            isArchived: true,
            sharedWith: [],
            uploadDate: moment().format("YYYY-MM-DD"),
        },
        {
            name: "Årsrapport 2017",
            type: fileTypes.DOCUMENT,
            uploaderName: "FJ",
            nextReminder: moment().add(7, "days").format("YYYY-MM-DD"),
            dateDue: moment().add(21, "days").format("YYYY-MM-DD"),
            periodFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
            periodTo: moment().format("YYYY-MM-DD"),
            amount: "",
            mainFolder: "Main folder 1",
            folder: "Firmaoplysninger fra CVR",
            subFolder: "Regnskaber",
            GDPR: "",
            tags: [],
            note: "",
            isArchived: true,
            sharedWith: [],
            uploadDate: moment().format("YYYY-MM-DD"),
        },
        {
            name: "Årsrapport 2016",
            type: fileTypes.DOCUMENT,
            uploaderName: "FJ",
            nextReminder: moment().add(7, "days").format("YYYY-MM-DD"),
            dateDue: moment().add(21, "days").format("YYYY-MM-DD"),
            periodFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
            periodTo: moment().format("YYYY-MM-DD"),
            amount: "",
            mainFolder: "Main folder 1",
            folder: "Firmaoplysninger fra CVR",
            subFolder: "Regnskaber",
            GDPR: "",
            tags: [],
            note: "",
            isArchived: true,
            sharedWith: [],
            uploadDate: moment().format("YYYY-MM-DD"),
        }]
    },
    "P-enheder": {
        name: "P-enheder",
        files: []
    },
    "Registreringshistorik": {
        name: "Registreringshistorik",
        files: []
    },
    "Historiske stamdata": {
        name: "Historiske stamdata",
        files: []
    },
    "Antal ansatte": {
        name: "Antal ansatte",
        files: []
    },
    "Moms terminer": {
        name: "Moms terminer",
        files: []
    }
}

export const subFoldersPasswords = {
    "Hetzner Online": {
        name: "Hetzner Online",
        files: [{
            name: "Deloitte - Licens 2018",
            type: fileTypes.PASSWORD,
            password: "1234",
            IP: "234.190.54.45",
            mainFolder: "Main folder 2",
            folder: "Kodebank",
            subFolder: "Hetzner Online",
        },
        {
            name: "Deloitte - Login credentials",
            type: fileTypes.PASSWORD,
            password: "11223344",
            IP: "183.132.23.04",
            mainFolder: "Main folder 2",
            folder: "Kodebank",
            subFolder: "Hetzner Online",
        },
        {
            name: "Nordea - Licens 2018",
            type: fileTypes.PASSWORD,
            password: "1234",
            IP: "98.93.149.98",
            mainFolder: "Main folder 2",
            folder: "Kodebank",
            subFolder: "Hetzner Online",
        }]
    }
}

export const mainFolders = {
    "Main folder 1": {
        name: "Main folder 1", sortIndex: 0, folders: {
            "Firmaoplysninger fra CVR": {
                name: "Firmaoplysninger fra CVR", mainFolder: "Main folder 1", urgency: urgencyLevels.HIGH, subFolders: subFolders, icon: folderIcons.FOLDER, files: [{
                    name: "Årsrapport 2015",
                    type: fileTypes.DOCUMENT,
                    uploaderName: "FJ",
                    nextReminder: moment().add(7, "days").format("YYYY-MM-DD"),
                    dateDue: moment().add(21, "days").format("YYYY-MM-DD"),
                    periodFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
                    periodTo: moment().format("YYYY-MM-DD"),
                    amount: "",
                    mainFolder: "Main folder 1",
                    folder: "Firmaoplysninger fra CVR",
                    subFolder: null,
                    GDPR: "",
                    tags: [],
                    note: "",
                    isArchived: true,
                    sharedWith: [],
                    uploadDate: moment().format("YYYY-MM-DD"),
                }]
            },
            "Nødvendige for alle": { name: "Nødvendige for alle", mainFolder: "Main folder 1", files: [], urgency: urgencyLevels.MEDIUM, subFolders: [], icon: folderIcons.FOLDER },
            "Nødvendige for ApS": { name: "Nødvendige for ApS", mainFolder: "Main folder 1", files: [], urgency: urgencyLevels.MEDIUM, subFolders: [], icon: folderIcons.FOLDER },
            "Andre for ApS": { name: "Andre for ApS", mainFolder: "Main folder 1", files: [], urgency: urgencyLevels.LOW, subFolders: [], icon: folderIcons.FOLDER }
        }
    },
    "Main folder 2": {
        name: "Main folder 2", sortIndex: 1, folders: {
            "Særlige mapper": { name: "Særlige mapper", mainFolder: "Main folder 2", files: [], urgency: urgencyLevels.HIGH, subFolders: [], icon: folderIcons.FOLDER },
            "Kodebank": { name: "Kodebank", mainFolder: "Main folder 2", files: [], urgency: urgencyLevels.LOW, subFolders: subFoldersPasswords, icon: folderIcons.KEY },
            "GDPR": { name: "GDPR", mainFolder: "Main folder 2", files: [], urgency: urgencyLevels.MEDIUM, subFolders: [], icon: folderIcons.FOLDER }
        }
    }
}


export const sharedWith = [{ name: "Mathias Jepsen", type: entityTypes.PERSON }, { name: "Nordea", type: entityTypes.COMPANY }, { name: "Danske Bank", type: entityTypes.COMPANY }]

export const drawerWidth = 320

export const subFoldersEntities = {
    "Brugerinformation": {
        name: "Brugerinformation",
        files: []
    },
    "Delte dokumenter": {
        name: "Delte dokumenter",
        files: []
    },
    "Historik": {
        name: "Historik",
        files: [
            {
                name: "2019",
                type: fileTypes.SHARE,
                sharedWith: [
                    {
                        name: "Mathias Jepsen",
                        type: entityTypes.PERSON
                    }],
                date: "30.05.2019",
                period: "01.01.2019 - 31.12.2019",
                mainFolder: "Virksomheder",
                folder: "Deloitte",
                subFolder: "Historik"
            },
            {
                name: "2018",
                type: fileTypes.SHARE,
                sharedWith: [
                    {
                        name: "Mathias Jepsen",
                        type: entityTypes.PERSON
                    }],
                date: "30.09.2018",
                period: "01.01.2018 - 31.12.2018",
                mainFolder: "Virksomheder",
                folder: "Deloitte",
                subFolder: "Historik"
            }
        ]
    }
}

export const entities = {}

export const mainFoldersEntities = {
    "Virksomheder": { name: "Virksomheder", sortIndex: 0, folders: { "Deloitte": { name: "Deloitte", subFolders: subFoldersEntities, icon: folderIcons.COMPANY } } },
    "Individer": { name: "Individer", sortIndex: 1, folders: { "Mathias Jepsen": { name: "Mathias Jepsen", subFolders: [], icon: folderIcons.PERSON } } }
}

export function createListItemIcon(icon, folderContentType) {
    if (folderContentType) {
        switch (folderContentType) {
            case "kodebank":
                return <VpnKey />
            case "persondata":
                return <Folder />
            case "regnskab":
                return <Folder />
        }
    }
    switch (icon) {
        case "Folder":
            return <Folder />
        case "VpnKey":
            return <VpnKey />
        case "Business":
            return <Business />
        case "Face":
            return <Face />
    }

}