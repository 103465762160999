import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { makeStyles } from '@material-ui/styles'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Typography, Avatar, TextField, LinearProgress, Button, Select, MenuItem, FormControl, OutlinedInput, InputLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { Delete } from "@material-ui/icons"
import { setCurrentCompany, setCompanies, setMainFolders } from "../actions/actions"
import Header from "../components/Header"
import Footer from "../components/Footer"
import moment from "moment"
import { save } from "save-file"
import dateFns from 'date-fns'
import api, { escapeForwardSlash } from "../api"

class Settings extends React.PureComponent {
    constructor(props) {
        super(props)

        const { companies, currentCompany } = this.props

        const filteredCompanies = companies.filter(company => company.name != currentCompany.name)

        this.state = {
            company: props.currentCompany,
            oldCompanyName: props.currentCompany.name,
            setAccountingPeriodStartDialog: false,
            setAccountingPeriodEndDialog: false,
            copyFolderStructureDialog: false,
            copyFolderStructureConfirmationDialog: false,
            downloadProgress: 0,
            selectedCompanyForCopy: filteredCompanies.length > 0 ? filteredCompanies[0] : null
        }
    }

    downloadAllDocuments = () => {
        const { file, currentCompany } = this.props

        this.setState({ downloadProgress: 0 }, () => {
            api.get(`/${escapeForwardSlash(currentCompany.name)}/file`)
                .then(res => {
                    const numOfFiles = res.data.filter(file => file.type === "document").length
                    let filesDownloadedCount = 0
                    for (const file of res.data) {
                        if (file.type === "document") {
                            api.get(`/${escapeForwardSlash(currentCompany.name)}/fileSrc/${file.src}`)
                                .then(async res2 => {
                                    if (Object.keys(res2.data).length > 0) {
                                        if (res2.data.row) {
                                            await save(res2.data.row.src, file.uploadedName)
                                            const progress = (++filesDownloadedCount / numOfFiles) * 100
                                            this.setState({ downloadProgress: progress })
                                        }
                                    }
                                })
                        }
                    }
                })
        })
    }

    handleTextFieldChange = (event) => {
        const { company } = this.state
        const field = event.target.name
        const newValue = event.target.value

        if (field == "selectedCompanyForCopy") {
            this.setState({ selectedCompanyForCopy: newValue })
            return
        }

        this.setState({ company: { ...company, [field]: newValue } })
    }

    handleCheckboxChange = (event) => {
        const { company } = this.state
        const field = event.target.name
        const newValue = event.target.checked

        this.setState({ company: { ...company, [field]: newValue, closedDate: newValue == false ? moment().format("YYYY-MM-DD") : null } })
    }

    handleChangeImage = (e, imageSrc, image) => {
        const { company } = this.state

        let file, fileName

        if (e.target) {
            if (e.target.files.length > 1) {
                return
            }
            file = e.target.files[0]
            fileName = e.target.value.split("\\")[e.target.value.split("\\").length - 1]
        } else {
            file = e
            fileName = file.name
        }
        
        const newGUID = dateFns.getTime(new Date())
        const newOriginalName = `${fileName.split(".")[0]}_${newGUID}.${fileName.split(".")[fileName.split(".").length - 1]}`

        var formData = new FormData()
        formData.append("file", file)
        formData.append("newOriginalName", newOriginalName)

        api.post("/file", formData)
            .then(res => {
                api.get(`/${escapeForwardSlash(company.name)}/fileSrc/${newOriginalName}`)
                    .then(res2 => {
                        if (Object.keys(res2.data).length > 0) {
                            this.setState({ company: { ...company, [imageSrc]: res2.data.src, [image]: newOriginalName } })
                        } else {
                            api.delete(`/fileSrc/${newOriginalName}`)
                        }
                    })
            })
    }

    updateCompany = () => {
        const { company, oldCompanyName } = this.state
        const { setCurrentCompany, setCompanies, companies, loggedInUser } = this.props

        setCurrentCompany(company)

        for (const companyIdx in companies) {
            if (companies[companyIdx].name == oldCompanyName) {
                const newCompanies = [...companies]
                newCompanies.splice(companyIdx, 1, company)
                setCompanies(newCompanies)
            }
        }

        api.put(`/company/${escapeForwardSlash(oldCompanyName)}/${escapeForwardSlash(loggedInUser.username)}`, company)

        this.setState({ oldCompanyName: company.name })
    }

    handleDialogOpen = (dialog) => {
        this.setState({ [dialog]: true })
    }

    handleDialogClose = (dialog) => {
        this.setState({ [dialog]: false })
    }

    copyFolderStructure = () => {
        const { currentCompany, setMainFolders } = this.props
        const { selectedCompanyForCopy } = this.state

        api.get(`/${escapeForwardSlash(selectedCompanyForCopy.name)}/fetchAllData`)
            .then(res => {
                api.put(`/companyCopy/${escapeForwardSlash(currentCompany.name)}/${escapeForwardSlash(selectedCompanyForCopy.name)}`, res.data.mainFolders)
                    .then(res2 => {
                        setMainFolders(res2.data.newMainFolders)
                    })
            })
    }

    render() {
        const { company, setAccountingPeriodEndDialog, downloadProgress, selectedCompanyForCopy, copyFolderStructureConfirmationDialog, setAccountingPeriodStartDialog, copyFolderStructureDialog } = this.state
        const { classes, mainFolders, companies, currentCompany } = this.props

        const textFieldInputLabelProps = {
            classes: {
                root: classes.textField
            },
            shrink: true
        }

        return (
            <Fragment>
                <Grid container direction="column" spacing={2} className={classes.root}>
                    <Grid item>
                        <TextField InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="Navn" name="name" label="Navn" value={company.name} onChange={this.handleTextFieldChange} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Grid container direction="column" spacing={4} alignItems="center">
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <Typography variant="h5">Logo</Typography>
                                    </Grid>
                                    <Grid item className={classes.logoContainer}>
                                        <Avatar
                                            className={classes.logo}
                                            src={company.logoSrc}
                                            alt="Company Logo"
                                        />
                                    </Grid>
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <input
                                            style={{ display: "none" }}
                                            id="raised-button-logo"
                                            type="file"
                                            onChange={(e) => this.handleChangeImage(e, "logoSrc", "logo")}
                                        />
                                        <label htmlFor="raised-button-logo">
                                            <Button variant="contained" component="span">
                                                Skift
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginLeft: 40 }}>
                                <Grid container direction="column" spacing={4} alignItems="center">
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <Typography variant="h5">Infographics</Typography>
                                    </Grid>
                                    <Grid item className={classes.logoContainer}>
                                        <Avatar
                                            className={classes.logo}
                                            src={company.infographics1Src}
                                            alt="Infographics1"
                                        />
                                    </Grid>
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <input
                                            style={{ display: "none" }}
                                            id="raised-button-infographics1"
                                            type="file"
                                            onChange={(e) => this.handleChangeImage(e, "infographics1Src", "infographics1")}
                                        />
                                        <label htmlFor="raised-button-infographics1">
                                            <Button variant="contained" component="span">
                                                Skift
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginLeft: 40 }}>
                                <Grid container direction="column" spacing={4} alignItems="center">
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <Typography variant="h5">Økonomi</Typography>
                                    </Grid>
                                    <Grid item className={classes.logoContainer}>
                                        <Avatar
                                            className={classes.logo}
                                            src={company.economySrc}
                                            alt="Economy Dashboard Image"
                                        />
                                    </Grid>
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <input
                                            style={{ display: "none" }}
                                            id="raised-button-economy"
                                            type="file"
                                            onChange={(e) => this.handleChangeImage(e, "economySrc", "economy")}
                                        />
                                        <label htmlFor="raised-button-economy">
                                            <Button variant="contained" component="span">
                                                Skift
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginLeft: 40 }}>
                                <Grid container direction="column" spacing={4} alignItems="center">
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <Typography variant="h5">Kunder</Typography>
                                    </Grid>
                                    <Grid item className={classes.logoContainer}>
                                        <Avatar
                                            className={classes.logo}
                                            src={company.customersSrc}
                                            alt="Customers Dashboard Image"
                                        />
                                    </Grid>
                                    <Grid item style={{ width: 256, display: "flex", justifyContent: "center" }}>
                                        <input
                                            style={{ display: "none" }}
                                            id="raised-button-customers"
                                            type="file"
                                            onChange={(e) => this.handleChangeImage(e, "customersSrc", "customers")}
                                        />
                                        <label htmlFor="raised-button-customers">
                                            <Button variant="contained" component="span">
                                                Skift
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="CVR" name="cvr" label="CVR" value={company.cvr || ""} onChange={this.handleTextFieldChange} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={1}>
                            <Grid item>
                                <TextField InputLabelProps={textFieldInputLabelProps} type="number" variant="outlined" placeholder="Antal brugere" name="numOfUsers" label="Antal brugere" value={company.numOfUsers || 0} onChange={this.handleTextFieldChange} />
                            </Grid>
                            <Grid item>
                                <TextField InputLabelProps={textFieldInputLabelProps} type="number" variant="outlined" placeholder="Antal medarbejdere" name="numOfEmployees" label="Antal medarbejdere" value={company.numOfEmployees || 0} onChange={this.handleTextFieldChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField type="date" InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="Stiftelsesdato" name="establishedDate" label="Stiftelsesdato" value={company.establishedDate || ""} onChange={this.handleTextFieldChange} />
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" spacing={1}>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="caption" style={{ fontSize: 12, color: "rgb(41, 93, 178)" }}>Regnskabsperiode start</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large" variant="outlined" style={{ minWidth: 152 }} onClick={() => this.handleDialogOpen("setAccountingPeriodStartDialog")}>{(company.accountingPeriodStart && moment(company.accountingPeriodStart).format("DD/MM")) || "01/01"}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="caption" style={{ fontSize: 12, color: "rgb(41, 93, 178)" }}>Regnskabsperiode slut</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large" variant="outlined" style={{ minWidth: 152 }} onClick={() => this.handleDialogOpen("setAccountingPeriodEndDialog")}>{(company.accountingPeriodEnd && moment(company.accountingPeriodEnd).format("DD/MM")) || "31/12"}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography>Momsafregning: </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    value={company.vat || ""}
                                    onChange={this.handleTextFieldChange}
                                    name="vat"
                                    input={<OutlinedInput name="vat" id="outlined-vat" />}
                                >
                                    <MenuItem value="Årlig">Årlig</MenuItem>
                                    <MenuItem value="Halvårlig">Halvårlig</MenuItem>
                                    <MenuItem value="Kvartalsvis">Kvartalsvis</MenuItem>
                                    <MenuItem value="Månedsvis">Månedsvis</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography>Selskabsform: </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    value={company.companyType || ""}
                                    onChange={this.handleTextFieldChange}
                                    name="companyType"
                                    input={<OutlinedInput name="companyType" id="outlined-companyType" />}
                                >
                                    <MenuItem value="pmv">Enkeltmandsvirksomhed</MenuItem>
                                    <MenuItem value="ivs">Iværksætterselskab</MenuItem>
                                    <MenuItem value="aps">Anpartsselskab</MenuItem>
                                    <MenuItem value="as">Aktieselskab</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <Typography>Aktivt: </Typography>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={company.status == 0 ? false : true}
                                    classes={{ root: classes.checkbox }}
                                    style={{ color: "black" }}
                                    name="status"
                                    onChange={this.handleCheckboxChange}
                                />
                            </Grid>
                            {company.closedDate &&
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography>Lukket: </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{moment(company.closedDate).format("DD.MM.YYYY")}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={this.downloadAllDocuments}>
                                    <Typography color="textSecondary">Download alle dokumenter</Typography>
                                </Button>
                            </Grid>
                            <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <LinearProgress style={{ width: 360, height: 16 }} variant="determinate" value={downloadProgress} color="primary" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => this.handleDialogOpen("copyFolderStructureDialog")}>
                            <Typography color="textSecondary">Kopiér mappestruktur fra anden virksomhed</Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={this.updateCompany}>
                            <Typography color="textSecondary">Opdatér</Typography>
                        </Button>
                    </Grid>
                </Grid>
                {setAccountingPeriodStartDialog &&
                    <Dialog open={setAccountingPeriodStartDialog} onClose={() => this.handleDialogClose("setAccountingPeriodStartDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Regnskabsperiode start</DialogTitle>
                        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                            <TextField type="date" InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="Regnskabsperiode start" name="accountingPeriodStart" label="Regnskabsperiode start" value={company.accountingPeriodStart || ""} onChange={this.handleTextFieldChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogClose("setAccountingPeriodStartDialog")} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {setAccountingPeriodEndDialog &&
                    <Dialog open={setAccountingPeriodEndDialog} onClose={() => this.handleDialogClose("setAccountingPeriodEndDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Regnskabsperiode slut</DialogTitle>
                        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                            <TextField type="date" InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="Regnskabsperiode slut" name="accountingPeriodEnd" label="Regnskabsperiode slut" value={company.accountingPeriodEnd || ""} onChange={this.handleTextFieldChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogClose("setAccountingPeriodEndDialog")} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {copyFolderStructureDialog &&
                    <Dialog open={copyFolderStructureDialog} onClose={() => this.handleDialogClose("copyFolderStructureDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Kopiér mappestruktur</DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Select
                                    value={selectedCompanyForCopy || ""}
                                    onChange={this.handleTextFieldChange}
                                    name="selectedCompanyForCopy"
                                    input={<OutlinedInput name="selectedCompanyForCopy" id="outlined-selected-company-copy" />}
                                >
                                    {companies.filter(company => company.name != currentCompany.name).map((company, idx) => {
                                        return <MenuItem key={idx} value={company}>{company.name}</MenuItem>
                                    })}
                                </Select>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogOpen("copyFolderStructureConfirmationDialog")} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {copyFolderStructureConfirmationDialog &&
                    <Dialog open={copyFolderStructureConfirmationDialog} onClose={() => this.handleDialogClose("copyFolderStructureConfirmationDialog")} onClick={(e) => e.stopPropagation()}>
                        <DialogTitle>Alt eksisterende data vil blive slettet</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ color: "rgba(0, 0, 0, 0.54)", textAlign: "center" }}>
                                Bekræft at du ønsker at fortsætte
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "space-evenly" }}>
                            <Button onClick={() => this.handleDialogClose("copyFolderStructureConfirmationDialog")} color="primary">
                                Annullér
                            </Button>
                            <Button onClick={() => { this.handleDialogClose("copyFolderStructureConfirmationDialog"); this.handleDialogClose("copyFolderStructureDialog"); this.copyFolderStructure() }} color="primary">
                                Fortsæt
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Fragment>
        )
    }
}

//                             <TextField InputLabelProps={textFieldInputLabelProps} variant="outlined" placeholder="Selskabsform" name="companyType" label="Selskabsform" value={company.companyType} onChange={this.handleTextFieldChange} />

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        width: "unset",
        margin: "unset"
    },
    logoContainer: {
        width: 256,
        height: 256,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    infographics1Container: {
        maxWidth: 1024,
        height: 256,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: 256,
        height: 256
    },
    infographics1: {
        height: 256,
        maxWidth: 1024
    },
    textField: {
        color: theme.palette.primary.dark
    },
    checkbox: {
        padding: 0,
        color: "black"
    },
})

const mapStateToProps = ({ state }) => {
    return {
        currentCompany: state.currentCompany,
        mainFolders: state.mainFolders,
        companies: state.companies,
        loggedInUser: state.loggedInUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setCurrentCompany, setCompanies, setMainFolders }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Settings))