import "./bootstrap"
import React from 'react'
import ReactDOM from 'react-dom'
import MainApp from './MainApp'
import { Provider } from "react-redux"
import { store } from "./store/index"
import { BrowserRouter as Router } from "react-router-dom"
import {  } from '@material-ui/styles'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import lightBlue from "@material-ui/core/colors/lightBlue"

const theme = createMuiTheme({
    palette: {
        primary: { main: '#3B86FF', contrastText: '#263238' },
        secondary: { main: '#111D3C' },
        text: {
            primary: "#263238",
            secondary: "#ffffff"
        },
        button: lightBlue
    },
    typography: {
        useNextVariants: true
    }
})

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <MainApp />
            </MuiThemeProvider>
        </Router>
    </Provider>, document.getElementById('root'))
