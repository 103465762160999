import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import md5 from "md5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom'
import { Grid, TextField, Checkbox, Typography, Fab, Link } from '@material-ui/core'
import api, { escapeForwardSlash } from "../api"
import { setLoggedInUser } from "../actions/actions"

class Login extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isRememberMeChecked: false,
            wasPasswordWrong: false,
            wasUsernameWrong: false
        }
    }

    handleRememberMeChange = (event) => {
        this.setState({ isRememberMeChecked: event.target.checked })
    }

    handleTextFieldChange = (event) => {
        const field = event.target.name
        const newValue = event.target.value

        this.setState({ [field]: newValue })    
    }

    handleLogin = (event) => {
        const { username, password } = this.state
        const { setLoggedInUser } = this.props

        event.preventDefault()

        if (!username || !password) {
            this.setState({ wasPasswordWrong: true, wasUsernameWrong: true })
            return
        }

        api.get(`user/${escapeForwardSlash(username)}`)
            .then(res => {
                if (Object.keys(res.data).length > 0) {
                    const user = res.data.data
                    let passwordWasCorrect = false
                    let usernameWasCorrect = false
                    if (user.password != md5(password)) {
                        this.setState({ wasPasswordWrong: true })
                    } else {
                        passwordWasCorrect = true
                        this.setState({ wasPasswordWrong: false })
                    }
                    if (user.username != username) {
                        this.setState({ wasUsernameWrong: true })
                    } else {
                        usernameWasCorrect = true
                        this.setState({ wasUsernameWrong: false })
                    }
                    
                    if (usernameWasCorrect && passwordWasCorrect) {
                        setLoggedInUser(user)
                    }
                } else {
                    this.setState({ wasPasswordWrong: true, wasUsernameWrong: true })
                }
            })
    }

    render() {
        if (this.props.loggedInUser) {
            const { from } = { from: { pathname: "/" } }
            return <Redirect to={from} />
        } 

        const { classes } = this.props
        const { isRememberMeChecked, wasPasswordWrong, wasUsernameWrong } = this.state

        return (
            <Fragment>
                <div className={classes.root}>
                    <Grid className={`${classes.containerRoot} ${classes.flex}`} container direction="column" spacing={3}>
                        <Grid className={classes.inputRow} item>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item>
                                    <Typography 
                                        style={{ marginRight: 8 }}
                                        variant="h5"
                                    >Login</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item>
                            <form onSubmit={this.handleLogin} noValidate>
                                <Grid container direction="column" spacing={3}>
                                    <Grid className={classes.inputRow} item>
                                        <TextField
                                            classes={{ root: classes.textField }}
                                            placeholder="Username"
                                            name="username"
                                            variant="outlined"
                                            error={wasUsernameWrong}
                                            onChange={this.handleTextFieldChange}
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid className={classes.inputRow} item>
                                        <TextField
                                            classes={{ root: classes.textField }}
                                            placeholder="Password"
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            fullWidth
                                            error={wasPasswordWrong}
                                            onChange={this.handleTextFieldChange}
                                        />
                                    </Grid>
                                    <Grid className={classes.inputRow} item>
                                        <Grid className={classes.flex} style={{ justifyContent: "space-between" }} container direction="row">
                                            <Grid item>
                                                <Grid className={classes.flex} container direction="row">
                                                    <Grid style={{ marginRight: 8 }} item>
                                                        <Checkbox
                                                            classes={{ root: classes.checkbox }}
                                                            style={{ color: "black" }}
                                                            checked={isRememberMeChecked}
                                                            onChange={this.handleRememberMeChange}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant="button"
                                                        >Husk mig</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={this.handleLogin}
                                                ><Typography color="textSecondary">Log på</Typography></Fab>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        
                        <Grid className={classes.inputRow} item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Grid className={classes.flex} style={{ justifyContent: "space-between" }} container direction="row">
                                        <Grid item>
                                            <Link
                                                component={RouterLink}
                                                to=""
                                                color="textPrimary"
                                                variant="body1"
                                                underline="always"
                                            >Glemt password?</Link>
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                component={RouterLink}
                                                to=""
                                                color="textPrimary"
                                                variant="body1"
                                                underline="always"
                                            >Log på med NemID</Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Link
                                                component={RouterLink}
                                                to=""
                                                color="textPrimary"
                                                variant="body1"
                                                underline="always"
                                            >Ny bruger?</Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        )
    }
}

const styles = theme => ({
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    root: {
        position: "absolute",
        height: "100%",
        width: "100%"
    },
    containerRoot: {
        height: "100%",
        width: "100%"
    },
    inputRow: {
        width: 320
    },
    checkbox: {
        padding: 0,
        color: "black"
    },
    logoContainer: {
        width: 64,
        height: 64
    },
    logo: {
        width: "100%",
        height: "100%"
    },
    textField: {
        backgroundColor: theme.palette.button.main
    }
})

const mapStateToProps = ({ state }) => {
    return {
        loggedInUser: state.loggedInUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setLoggedInUser }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Login))