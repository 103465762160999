import React from "react"
import { makeStyles } from '@material-ui/styles'
import { Snackbar, IconButton } from '@material-ui/core'
import { Close } from "@material-ui/icons"

const InformationSnackbar = (props) => {
    const { isBarOpen, handleBarClose, message, topMargin } = props

    const useStyles = makeStyles(theme => {
        return {
            warningBarAnchor: {
                marginTop: topMargin
            },
            close: {
                padding: 4
            }
        }
    })

    const classes = useStyles()

    return (
        <div>
            <Snackbar
                classes={{ anchorOriginTopCenter: classes.warningBarAnchor }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={isBarOpen}
                autoHideDuration={3000}
                onClose={handleBarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleBarClose}
                    >
                        <Close />
                    </IconButton>,
                ]}
            />
        </div>
    )
}

export default InformationSnackbar