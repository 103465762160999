import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => {
        return rest.loggedInUser ? (
            (
                <Component {...props} />
            )
        ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
            )
    }} />
}

const mapStateToProps = ({ state }) => {
    return {
        loggedInUser: state.loggedInUser
    }
}

export default withRouter(connect(mapStateToProps, null)(LoginRequiredRoute))