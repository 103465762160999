import React, { Fragment } from 'react'
import { Switch, Route } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withStyles } from '@material-ui/core/styles'
import compose from "recompose/compose"
import { withRouter, Redirect } from "react-router-dom"
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { } from "@material-ui/icons"
import { drawerWidth } from "./constants"
import MenuIcon from "@material-ui/icons/Menu"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Login from "./screens/Login"
import Home from "./screens/Home"
import Sharing from "./screens/Sharing"
import FolderList from "./screens/FolderList"
import Upload from "./screens/Upload"
import Settings from "./screens/Settings"
import Calendar from "./screens/Calendar"
import Assignments from "./screens/Assignments"
import Admin from "./screens/Admin"
import FolderExpansionPanel from "./components/FolderExpansionPanel"
import { setMainFolders, setUploadedFiles, setSearchResults, setCurrentFile, setCurrentSubFolder, setCompanies, setSharing, setCurrentCompany, setIsLoading, setCurrentFolder } from "./actions/actions"
import api, { escapeForwardSlash } from "./api"
const qs = require('qs');

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isDrawerVisible: true,
            isDocumentValuesContainerModalOpen: false
        }
    }

    componentDidMount() {
        const { setMainFolders, setUploadedFiles, setCompanies, setCurrentCompany, setIsLoading, setSharing, setCurrentFolder, loggedInUser } = this.props

        api.get("/company")
            .then(res => {
                const userCompanies = loggedInUser.companies.split(",")
                let companies = res.data.rows.filter(row => userCompanies.includes(row.name)).sort((a, b) => (b.name < a.name ? 1 : (a.name < b.name ? -1 : 0))).map(company => company)
                api.get(`/company/fileSrc/all/images`, {
                    params: {
                        images: companies.map(company => [company.logo, company.infographics1]).flat()
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                })
                    .then(res3 => {
                        const newCompanies = companies.map(newCompany => {
                            return {
                                ...newCompany, logoSrc: res3.data.rows.filter(logoSrc => { return logoSrc.name == newCompany.logo }).length > 0 ? res3.data.rows.filter(logoSrc => { return logoSrc.name == newCompany.logo })[0].src : null,
                                infographics1Src: res3.data.rows.filter(infographics1Src => { return infographics1Src.name == newCompany.infographics1 }).length > 0 ? res3.data.rows.filter(infographics1Src => { return infographics1Src.name == newCompany.infographics1 })[0].src : null
                            }
                        })
                        api.get(`/${escapeForwardSlash(newCompanies[0].name)}/fetchAllData`)
                            .then(res2 => {
                                setSharing(res2.data.sharing)
                                setMainFolders(res2.data.mainFolders)
                                setUploadedFiles(res2.data.uploadedFiles)
                                setCurrentCompany(newCompanies[0])
                                setCompanies(newCompanies)
                                setIsLoading(false)
                                setCurrentFolder(null)
                            })
                    })

            })
    }

    handleDrawerOpen = () => {
        this.setState({ isDrawerVisible: true })
    }

    handleDrawerClose = () => {
        this.setState({ isDrawerVisible: false })
    }

    handleDocumentValuesContainerModalClose = (e) => {
        const { setCurrentFile } = this.props

        e && e.stopPropagation()

        this.setState({ isDocumentValuesContainerModalOpen: false }, () => setCurrentFile(null))
    }

    handleDocumentValuesContainerModalOpen = (file, subFolder) => {
        const { setCurrentFile, mainFolders, currentMainFolder, currentFolder, setCurrentSubFolder } = this.props
        setCurrentFile(file)
        subFolder && setCurrentSubFolder(mainFolders[currentMainFolder.name].folders[currentFolder.name].subFolders[subFolder.name])
        this.setState({ isDocumentValuesContainerModalOpen: true })
    }

    getSearchResults = (searchValue) => {
        const { mainFolders } = this.props

        let foundFiles = []
        Object.values(mainFolders).forEach(mainFolder => {
            Object.values(mainFolder.folders).filter(folder => folder.folderContentType != "kodebank").forEach(folder => {
                if (folder.files.length > 0) {
                    foundFiles = foundFiles.concat(this.searchForMatch(folder.files, searchValue))
                }

                if (folder.subFolders) {
                    Object.values(folder.subFolders).forEach(subFolder => {
                        if (subFolder.files.length > 0) {
                            foundFiles = foundFiles.concat(this.searchForMatch(subFolder.files, searchValue))
                        }
                    })
                }
            })
        })

        return foundFiles
    }

    searchForMatch = (files, searchValue) => {
        if (searchValue === "") return []

        const iterateStringForMatch = (string) => {
            const searchValueLower = searchValue.toLowerCase()
            const stringLower = string.toLowerCase()

            for (var i = 0; i < stringLower.length; i++) {
                const stringToCompare = stringLower.substring(i, i + searchValueLower.length)
                if (stringToCompare == searchValueLower) {
                    return true
                }
            }

            return false
        }

        let foundFiles = []
        files.forEach(file => {
            if (iterateStringForMatch(file.mainFolder) || iterateStringForMatch(file.folder)) {
                foundFiles.push(file)
                return
            } else if (file.subFolder) {
                if (iterateStringForMatch(file.subFolder)) {
                    foundFiles.push(file)
                    return
                }
            }

            if (iterateStringForMatch(file.name)) {
                foundFiles.push(file)
                return
            }

            file.tags.forEach(tag => {
                if (iterateStringForMatch(tag)) {
                    foundFiles.push(file)
                    return
                }
            })

            file.GDPR.forEach(tag => {
                if (iterateStringForMatch(tag)) {
                    foundFiles.push(file)
                    return
                }
            })
        })

        return foundFiles
    }

    render() {
        const { isLoading, searchValue, searchResults, setSearchResults, classes, currentFolder } = this.props
        const { isDrawerVisible, isDocumentValuesContainerModalOpen } = this.state

        if (isLoading) {
            return (
                <CircularProgress style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            )
        } else {
            return (
                <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
                    <Header 
                        getSearchResults={this.getSearchResults}
                     />
                        <Fragment>
                        {searchValue 
                            ?
                            <div className={classes.root}>
                                <div className={classes.rootGrid}>
                                    <Grid container className={`${classes.contentContainer} ${searchValue != "" ? classes.contentContainerShifted : (!isDrawerVisible ? classes.contentContainerShifted : "")}`}>
                                        <Grid item style={{ width: "100%" }}>
                                            <Grid container className={classes.contentHeader} direction="row">
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                                        {!searchResults ?
                                                            <Fragment>
                                                                <Grid item>
                                                                    <IconButton
                                                                        color="inherit"
                                                                        aria-label="Open drawer"
                                                                        onClick={this.handleDrawerOpen}
                                                                        edge="start"
                                                                        className={`${isDrawerVisible ? classes.hide : classes.menuIcon}`}
                                                                    >
                                                                        <MenuIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        noWrap
                                                                        className={`${classes.menuTitle} ${!isDrawerVisible ? classes.menuTitleShifted : ""}`}
                                                                    >
                                                                        {currentFolder ? `${currentFolder.name} (${Object.values(currentFolder.subFolders).map(subFolder => subFolder.files.length).reduce((a, b) => a + b, 0) + currentFolder.files.length})` : "Ingen mappe valgt"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Fragment>
                                                            :
                                                            <Grid item>
                                                                <Typography variant="h6" className={classes.menuTitle}>Søgningsresultater</Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.content}>
                                            <FolderExpansionPanel
                                                searchResults={searchResults}
                                                getSearchResults={this.getSearchResults}
                                                isDocumentValuesContainerModalOpen={isDocumentValuesContainerModalOpen}
                                                handleDocumentValuesContainerModalClose={this.handleDocumentValuesContainerModalClose}
                                                handleDocumentValuesContainerModalOpen={this.handleDocumentValuesContainerModalOpen}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            :
                            <Switch>
                                <Redirect exact from="/" to="/home" />
                                <Route exact path="/home" render={(props) => <Home {...props} getSearchResults={this.getSearchResults} />} />
                                <Route exact path="/folderlist" component={FolderList} />
                                <Route exact path="/sharing" component={Sharing} />
                                <Route exact path="/upload" component={Upload} />
                                <Route exact path="/settings" component={Settings} />
                                <Route exact path="/calendar" component={Calendar} />
                                <Route exact path="/assignments" component={Assignments} />
                                <Route exact path="/admin" component={Admin} />
                            </Switch>
                        }
                        </Fragment>
                    <Footer />
                </div>
            )
        }


    }
}

const styles = theme => ({
    root: {
        width: "100%",
        height: "calc(100vh - 150px)",
        padding: theme.spacing(3),
        position: "relative",
    },
    rootGrid: {
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        height: "100%"
    },
    drawerContainer: {
        width: drawerWidth,
        height: "100%",
        background: theme.palette.background.paper,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    drawerContainerShifted: {
    },
    drawerHeader: {
        height: 64,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    drawerItem: {
        height: 64,
        width: drawerWidth,
    },
    contentContainer: {
        position: "absolute",
        top: theme.spacing(3),
        left: theme.spacing(3) + drawerWidth,
        width: `calc(100% - ${drawerWidth + theme.spacing(6)}px)`,
        height: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentContainerShifted: {
        left: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    contentHeader: {
        height: 64,
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.paper
    },
    content: {
        background: "whitesmoke",
        width: "100%",
        height: `calc(100% - 64px)`,
        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    foldersContainer: {
        height: "calc(100% - 64px)",
        overflow: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0
        }
    },
    hide: {
        display: 'none'
    },
    menuTitle: {
        margin: 0,
        marginLeft: theme.spacing(3)
    },
    menuTitleShifted: {
        marginLeft: 0
    },
    menuIcon: {
        marginLeft: 0
    },
    rootExpansionPanelDetails: {
        padding: 0,
    },
    mainFolderBar: {
        background: "dimgrey"
    },
    rootMainFolder: {
        padding: "0 0 0 16px"
    },
    expandedMainFolder: {
        margin: "0px !important"
    },
    focusedMainFolder: {
        backgroundColor: "dimgrey !important"
    },
    textField: {
        color: theme.palette.primary.main
    },
    paperScrollPaper: {
        maxHeight: "calc(100% - 58px)"
    },
    radioButton: {
        color: "black"
    },
    radioGroup: {
        flexDirection: "row",
        justifyContent: "center"
    },
    heading: {
        hyphens: "auto",
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    }
})

const mapStateToProps = ({ state }) => {
    return {
        mainFolders: state.mainFolders,
        isLoading: state.isLoading,
        loggedInUser: state.loggedInUser,
        searchValue: state.searchValue,
        currentFolder: state.currentFolder,
        currentMainFolder: state.currentMainFolder,
        searchResults: state.searchResults
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setIsLoading, setSearchResults, setSharing, setMainFolders, setUploadedFiles, setCompanies, setCurrentCompany, setCurrentFolder, setCurrentSubFolder, setCurrentFile }, dispatch)
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(App))