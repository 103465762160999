import React, { useMemo, useCallback, Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import { fileTypes, urgencyLevels } from "../enums"
import moment from "moment"
import dateFns from 'date-fns'
import api, { escapeForwardSlash, findHighestVersionNumber } from "../api"

const Dropzone = (props) => {
    const { handleAddNewFile, currentCompany, inUpload, directUpload, subFolder, height, text, loggedInUser } = props

    const onDrop = useCallback(files => {
        files.forEach(file => {
            const newFile = {
                name: file.name.split(".")[0],
                type: fileTypes.DOCUMENT,
                uploaderName: loggedInUser.firstname[0] + loggedInUser.lastname[0],
                nextReminder: moment().add(7, "days").format("YYYY-MM-DD"),
                dateDue: moment().add(14, "days").format("YYYY-MM-DD"),
                periodFrom: moment().subtract(1, "years").format("YYYY-MM-DD"),
                periodTo: moment().format("YYYY-MM-DD"),
                amount: "",
                mainFolder: null,
                folder: null,
                subFolder: null,
                isEntity: false,
                company: currentCompany.name,
                GDPR: [],
                tags: [],
                note: "",
                sharedWith: [],
                isArchived: false,
                uploadDate: moment().format("YYYY-MM-DD"),
                uploadedName: file.name,
                src: file.name,
                isRemovedExceededDeadlines: false,
                isRemovedUpcomingDeadlines: false,
                urgency: urgencyLevels.DEFAULT
            }

            const newGUID = dateFns.getTime(new Date())
            const newOriginalName = `${file.name.split(".")[0]}_${newGUID}.${file.name.split(".")[file.name.split(".").length - 1]}`

            var formData = new FormData()
            formData.append("file", file)
            formData.append("newOriginalName", newOriginalName)
            newFile.src = newOriginalName

            if (inUpload) {
                api.post(`/${escapeForwardSlash(currentCompany.name)}/uploadedFile`, newFile)
                    .then(res => {
                        if (res.data && res.data.code != 1001) {
                            handleAddNewFile(newFile)
                        } else {
                            api.get(`/${escapeForwardSlash(currentCompany.name)}/uploadedFile`)
                                .then(res2 => {
                                    if (res2.data) {
                                        const highestVersionNumber = findHighestVersionNumber(newFile, res2.data)
                                        newFile.uploadedName = newOriginalName

                                        if (!newFile.name.match(/(?<=\()\d+(?=\))/g)) {
                                            newFile.name = newFile.name += ` (${highestVersionNumber + 1})`
                                        } else {
                                            newFile.name = newFile.name.replace(/(?<=\()\d+(?=\))/, highestVersionNumber + 1)
                                        }

                                        api.post(`/${escapeForwardSlash(currentCompany.name)}/uploadedFile`, newFile)
                                            .then(res3 => {
                                                handleAddNewFile(newFile)
                                            })
                                    }
                                })
                        }
                    })
            } else if (directUpload) {
                handleAddNewFile(newFile, subFolder, formData)
            } else {
                handleAddNewFile(file, formData)
            }
        })
    }, [handleAddNewFile])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop });

    const style = useMemo(() => ({
        ...baseStyle(height),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
            isDragActive,
            isDragReject,
            isDragAccept
        ]);
    
    return (
        <div style={{ height: "100%" }}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps() } />
                <p style={{ fontSize: 22 }}>{text || "Drag 'n' drop filer, eller klik for at vælge fra en liste"}</p>
            </div>
        </div>
    );
}

const baseStyle = (height) => ({
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: "center",
    height: "100%",
    minHeight: height ? height : 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'lightslategrey',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'lightslategrey',
    outline: 'none',
    transition: 'border .24s ease-in-out'
})

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
}

export default Dropzone